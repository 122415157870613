<template>
  <div class="alerts flex gap-20">
    <BaseButton @click="addAlert" class="add-alert"> הוספה </BaseButton>
    <div class="alert" :key="alert.promo" v-for="alert in getAlerts">
      <div class="text-continer flex gap-05">
        <img
          class="icon"
          v-if="getIconSrc(alert.icon)"
          :src="getIconSrc(alert.icon)"
        />
        <span class="Heebo content">
          {{ alert.content }}
        </span>
      </div>
      <BaseButton v-if="alert.editable" @click="editAlert(alert)">
        עריכה
      </BaseButton>
      <BaseButton @click="clickRemove(alert)"> מחיקה </BaseButton>
      <span class="type"> סוג : {{ alert.type }}</span>
    </div>
    <EditAlertModal
      :saveOrEdit="textButtonInModal"
      :alert="currentAlert"
      :isEdit="isEdit"
      :customers="getCustomers"
      @exitModal="isOpenModal = false"
      @saveAlert="saveAlert"
      v-if="isOpenModal"
    >
    </EditAlertModal>
    <ConfirmDialog
      v-if="isConfirmDialog"
      :text="confirmDialogText"
      @confirm="confirmDialog"
      @deny="denyDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "../helpers/BaseButton.vue";
import EditAlertModal from "../helpers/EditAlertModal.vue";
import ConfirmDialog from "../helpers/ConfirmDialog.vue";
export default {
  components: {
    BaseButton,
    EditAlertModal,
    ConfirmDialog,
  },
  data() {
    return {
      isOpenModal: false,
      isConfirmDialog: false,
      confirmDialogType: null,
      confirmDialogText: null,
      textButtonInModal: null,
      isEdit:null,
      currentAlert: null,
    };
  },
  async mounted() {
    await this.$store.dispatch("Customers/getCustomers");
  },
  methods: {
    getIconSrc(icon) {
      if (icon) return `images/${icon}-icon.png`;
      else return;
    },
    editAlert(alert) {
      this.currentAlert = { ...alert };
      this.textButtonInModal = "שמור";
      this.isEdit = true;
      this.isOpenModal = true;
    },
    addAlert() {
      this.currentAlert = null;
      this.textButtonInModal = "הוסף";
      this.isEdit = false;
      this.isOpenModal = true;
    },
    async saveAlert(alert) {
      await this.$store.dispatch("Alert/saveAlert", alert);
      this.isOpenModal = false;
    },
    async removeAlert(alert) {
      await this.$store.dispatch("Alert/removeAlert", alert);
      this.isOpenModal = false;
    },
    async confirmDialog() {
      if (this.confirmDialogType === "remove") {
        await this.removeAlert(this.currentAlert.alert);
        this.isConfirmDialog = false;
      }
    },
    denyDialog() {
      this.currentAlert = null;
      this.isConfirmDialog = false;
    },
    clickRemove(alert) {
      this.currentAlert = { ...alert };
      this.isConfirmDialog = true;
      this.confirmDialogType = "remove";
      this.confirmDialogText = "האם אתה בטוח רוצה למחוק את הודעה זאת";
    },
  },
  computed: {
    ...mapGetters({
      getAlerts: "Alert/getAlertsPopups",
      getCustomers: "Customers/getCustomers",
    }),
  },
};
</script>

<style scoped lang="scss">
.text-continer {
  margin-bottom: 10px;
  color: white;
}
.content {
  width: 100%;
  font-size: $mediumTextSize;
}
.icon {
  width: 30px;
}
.add-alert {
  width: 90%;
}
.alerts {
  padding: 10px;
  flex-direction: column;
}
.alert {
  width: 100%;
  padding: 10px;
  box-shadow: 1px 1px 1px #ffe6ad;
  border-radius: 20px;
  background: #393945;
  max-width: 600px;
}

.product-continer {
  margin-bottom: 10px;
}
button {
  margin-left: 10px;
}
.type {
  color: white;
}
</style>