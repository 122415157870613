import Api from './Api'

export default {
    getParts(obj) {
        return Api().post('parts', obj)
    },
    starProduct(obj){
        return Api().post('starproduct', obj)
    },
    getFamilies(obj) {
        return Api().post('families', obj)
    },
    updateFamilies(obj) {
        return Api().post('updateFamilies', obj)
    }
}