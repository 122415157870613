<template>
  <a
    v-if="this.$store.getters['AuthModule/isAuthenticated']"
    @click="clickonnav"
    :class="mode"
    class="waves-effect waves"
    ><div class="nav-card-continer">
      <h2>
        {{ title }}<i class="material-icons">{{ iconName }}</i>
      </h2>
      <p>{{ amount }}</p>
      <h3>{{ subTitle }}</h3>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      requierd: true,
    },
    subTitle: {
      type: String,
      requierd: true,
    },
    amount: {
      type: Number,
      requierd: true,
    },
    mode: {
      type: String,
      requierd: true,
    },
    iconName: {
      type: String,
      requierd: true,
    },
    refi: {
      type: String,
      requierd: true,
    },
  },
  data() {
    return {
      refer: this.ref,
    };
  },
  methods: {
    clickonnav() {
      // console.log("goto: /" + this.$props.refi);
      // console.log(this.$props);
      this.$store.commit("setMenuToOpen");
      this.$router.replace("/" + this.$props.refi);
    },
  },
};
</script>

<style scoped lang='scss'>
* {
  color: white !important;
}
.grey {
  background: $secondaryColor !important;
}
.brown {
  background: $primaryColor !important;
}
a:lang(en) {
  text-align: left;
}
a {
  height: fit-content !important;
  width: fit-content !important;
  padding: 13px;
  text-align: right;
  border-radius: 15px;
  box-shadow: 1px 1px 1px $primaryTextColor;
  margin: 0 15px;
  
  h2 {
    margin: 0;
    font-size: $mediumTextSize;
    margin-bottom: 3px;
    i.material-icons {
      margin-right: 24px;
      color: $primaryTextColor !important;
    }
    i.material-icons:lang(en) {
      font-size: $extraSmallTextSize;
      margin-right: 0px !important;
      margin-left: 7px;
    }
  }
  h3 {
    font-size: 12px;
    margin: 0;
    display: inline;
  }
  p {
    margin: 0;
    font-size: 20px;
    line-height: 20px;
    color: white;
    display: inline;
    margin-left: 5px;
    font-weight: bold;
  }
  p:lang(en) {
    margin: unset;
    margin-right: 5px;
  }
}
@media (max-width: 601px) {
  h2 i.material-icons {
    margin-right: 10px !important;
  }
  a {
    margin: 0;
    padding: 8px 15px;
    h2 {
      margin: 0;
      margin-bottom: 5px;

      font-size: $smallTextSize;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 993px) {
  a {
    margin: 0 4px;
  }
}
</style>