<template>
  <div>
    <ul class="invoices Heebo">
      <BaseInvoice
        v-for="invoice in invoices"
        :key="invoice.docno"
        :invoice="invoice"
        @download-invoice="downloadInvoice"
      />
    </ul>
  </div>
</template>

<script>
import BaseInvoice from "./BaseInvoice.vue";
export default {
  components: { BaseInvoice },
  name: "Invoices",
  props: {
    invoices: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async downloadInvoice(docId) {
      await this.$store.dispatch('Invocies/delevires', docId);
    },
  },
};
</script>

<style scoped lang="scss">
.invoices {
  list-style: none;
  background: $secondaryColor;
  border-radius: 30px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
}
</style>
