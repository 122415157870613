<template>
  <p v-if="isThereErrorMsg">
    {{ errorMsg }}
  </p>
</template>

<script>
export default {
  props: ["isThereErrorMsg", "errorMsg"],
};
</script>

<style lang="scss" scoped>
p {
  color: #bb1209;
  font-weight: bold;
  font-size: $smallTextSize;
  margin-bottom: 0px;
  margin-top: 10px;
}
</style>
