<template>
  <div class="distlines gap-20">
    <BaseButton @click="openAddNewDistLine">{{
      !isAddNewDistLineOpen ? "הוספת קו חלוקה חדשה" : "סגירת חלון קו חלוקה חדש"
    }}</BaseButton>
    <section class="new-distline" v-if="isAddNewDistLineOpen">
      <Select
        @update-distLine="updateDistLine"
        :selectedOptions="0"
        :options="newDistLineOptions"
        :isNewDistLine="true"
      />
    </section>
    <h5 class="white-text center">קווי החלוקה שלך:</h5>
    <section class="distlines-select">
      <Select
        @select="setSelectedDistLineOptions"
        :options="distLinesOptions"
        :selectedOptions="selectedDistLine"
        @update-distLine="updateDistLine"
        @delete-distLine="deleteDistLine"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Select from "../helpers/Select.vue";
import BaseButton from "../helpers/BaseButton.vue";

export default {
  components: {
    BaseButton,
    Select,
  },
  data() {
    return {
      // distLinesOptions: [],
      selectedDistLine: null,
      isAddNewDistLineOpen: false,
      newDistLineOptions: null,
    };
  },
  mounted() {
    this.resetNewDistLineOptions();
  },
  methods: {
    resetNewDistLineOptions() {
      this.newDistLineOptions = [
        {
          title: "קו חלוקה חדש",
          linekey: "",
          linedes: "שם קו חלוקה",
          days: [
            { title: "יום ראשון", isSet: false },
            { title: "יום שני", isSet: false },
            { title: "יום שלישי", isSet: false },
            { title: "יום רביעי", isSet: false },
            { title: "יום חמישי", isSet: false },
            { title: "יום שישי", isSet: false },
          ],
        },
      ];
    },
    loadDistLines() {
      const distLines = this.getDistLines.map((distLine) => {
        return {
          title: `קו חלוקה ${distLine.linekey}`,
          linekey: distLine.linekey,
          linedes: distLine.linedes,
          days: [
            { title: "יום ראשון", isSet: distLine.day1 },
            { title: "יום שני", isSet: distLine.day2 },
            { title: "יום שלישי", isSet: distLine.day3 },
            { title: "יום רביעי", isSet: distLine.day4 },
            { title: "יום חמישי", isSet: distLine.day5 },
            { title: "יום שישי", isSet: distLine.day6 },
          ],
        };
      });
      this.distLinesOptions = distLines;
    },
    openAddNewDistLine() {
      this.isAddNewDistLineOpen = !this.isAddNewDistLineOpen;
    },
    setSelectedDistLineOptions(key) {
      this.selectedDistLine = key;
    },
    updateDistLine(distLineObj) {
      const days = distLineObj.days.reduce((accumulator, day, index) => {
        accumulator[`day${index + 1}`] = day.isSet;
        return accumulator;
      }, {});
      this.$store.dispatch("DistLines/updateDistLine", {
        linekey: distLineObj.linekey,
        linedes: distLineObj.linedes,
        ...days,
        mode: distLineObj.mode,
      });
      if (distLineObj.mode === "new") {
        this.resetNewDistLineOptions();
      }
    },
    deleteDistLine(linekey) {
      this.$store.dispatch("DistLines/deleteDistLine", linekey);
    },
  },
  computed: {
    ...mapGetters({
      getDistLines: "DistLines/getDistLines",
    }),
    distLinesOptions() {
      return this.getDistLines.map((distLine) => {
        return {
          title: `קו חלוקה ${distLine.linekey}`,
          linekey: distLine.linekey,
          linedes: distLine.linedes,
          days: [
            { title: "יום ראשון", isSet: distLine.day1 },
            { title: "יום שני", isSet: distLine.day2 },
            { title: "יום שלישי", isSet: distLine.day3 },
            { title: "יום רביעי", isSet: distLine.day4 },
            { title: "יום חמישי", isSet: distLine.day5 },
            { title: "יום שישי", isSet: distLine.day6 },
          ],
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
.distlines-select {
  height: calc(90vh - 180px);
  margin-top: 20px;
  overflow: scroll;
  overflow-x: hidden;
  padding-bottom: 20px;
}

@media (min-width: 900px) {
  .distlines-select {
    margin-bottom: 50px;
  }
}

.new-distline {
  margin-top: 10px;
}
.text-continer {
  margin-bottom: 10px;
  color: white;
}
.content {
  width: 100%;
  font-size: $mediumTextSize;
}
.icon {
  width: 30px;
}
.add-alert {
  width: 90%;
}
.distlines {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  max-width: 700px;
}
.alert {
  width: 100%;
  padding: 10px;
  box-shadow: 1px 1px 1px #ffe6ad;
  border-radius: 20px;
  background: #393945;
}

.product-continer {
  margin-bottom: 10px;
}
button {
  margin-left: 10px;
}
.type {
  color: white;
}
</style>
