<template>
  <div v-if="false"></div>
</template>

<script>
import { routerNames } from "@/router";
export default {
  async mounted() {
    document.getElementById("dolimo_loader").style.display = "flex";
    const passkey = this.$route.query.passkey;
    const phone = this.$route.query.phone;
    const cust = this.$route.query.cust;
    const agent = this.$route.query.agent;
    const orderId = this.$route.query.orderId;

    // get 15 minutes jwt
    try {
      const response = await this.$store.dispatch(
        "AuthModule/getOneTimeLogin",
        {
          passkey,
          phone,
          cust,
          agent,
        }
      );
      if (response) {
        if (orderId) {
          this.$router.push({
            name: routerNames.order,
            params: { ord: orderId },
          });
        } else {
          this.$router.push({
            name: routerNames.category,
            params: { category: "המוצרים שלי" },
          });
        }
      } else throw new Error();
    } catch (error) {
      console.log(error);
      this.$router.push({ path: "/" });
    }
  },
};
</script>

<style></style>
