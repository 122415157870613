<template>
  <teleport to="#modal_backdrop">
    <div class="backdrop-popup"></div>
    <div class="modal-popup enter">
      <h5>אישור</h5>
      {{ text }}
      <div class="buttons-continer">
        <BaseButton @click="this.$emit('deny')"> ביטול </BaseButton>
        <BaseButton @click="this.$emit('confirm')"> אישור </BaseButton>
      </div>
    </div>
  </teleport>
</template>

<script>
import BaseButton from "../helpers/BaseButton.vue";
export default {
  components: {
    BaseButton,
  },
  emits: ["confirm", "deny"],
  props: {
    text: {
      type: String,
      requierd: true,
    },
  },
};
</script>

<style scoped lang="scss">
.backdrop-popup {
  background-color: #000000;
  opacity: 0.6;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 11;
}

.modal-popup {
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 12;
  border-radius: 40px;
  background: #393945;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 330px;
  padding: 10px;
  color: white;
}
.buttons-continer {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
</style>