<template>
  <section class="massages-continer flex gap-10">
    <div class="flex gap-05">
      <img class="iconWithGrayBg" src="/images/ball.png" />
      <h1>הודעות</h1>
    </div>
    <BaseMessage :messageIcon="messageIcon"><slot></slot></BaseMessage>
    <div class="button-continer">
      <span @click="$emit('allMassages')" class="all-massages">
        לכל ההודעות »</span
      >
    </div>
  </section>
</template>

<script>
import BaseMessage from "./BaseMessage.vue";
export default {
  components: { BaseMessage },
  props: {
    messageIcon: {
      type: String,
    },
  },
  emits: ["allMassages"],
};
</script>

<style scoped lang="scss">
h1 {
  font-size: $mediumTextSize;
  margin: 0px;
  color: $primaryTextColor;
}
.massages-continer {
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  .button-continer {
    width: 100%;
    .all-massages {
      color: $primaryTextColor;
      font-size: $smallTextSize;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
