<template>
  <teleport to="#modal_backdrop">
    <div @click.prevent="confirmPopup" class="backdrop-popup"></div>
    <div class="modal-popup enter">
      <span class="popups-number">{{ popups.length }}</span>
      <div class="icon-continer">
        <img class="icon" src="/images/ball-icon.png" />
      </div>
      <!-- <Transition :is="popups[currPopup].alert" mode="out-in"> -->
        <div class="alart-continer">
          <p class="felix007">
            {{ popups[currPopup].content }}
          </p>
          <BaseButton @click="approve(popups[currPopup].alert)" class="button"
            ><div>אישור</div>
          </BaseButton>
        </div>
      <!-- </Transition> -->
    </div>
  </teleport>
</template>

<script>
import BaseButton from "../helpers/BaseButton.vue";
export default {
  components: {
    BaseButton,
  },

  props: {
    popups: {
      type: Array,
      requierd: true,
    },
    currPopup: {
      type: Number,
      requierd: true,
    },
  },
  emits: ["exit-modal", "approve"],
  methods: {
    confirmPopup() {
      this.$emit("exit-modal");
    },
    approve(alert) {
      this.$emit("approve", alert);
    },
  },
};
</script>
<style scoped lang="scss">
.categories-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  padding: 15px;
}

.line-seperator-one {
  background-image: url("/images/category-filter-seperator.png");
  width: 370px;
  height: 46px;
  display: flex;
  justify-content: center;
}

.backdrop-popup {
  background-color: #000000;
  opacity: 0.6;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 11;
}

.modal-popup {
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 12;
  border-radius: 40px;
  background: #393945;
  top: 13%;
  right: 6%;
  width: 100%;
  max-width: 330px;
  padding: 20px;

  .popups-number {
    position: absolute;
    top: 33px;
  }

  .icon-continer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alart-continer {
    width: 100%;
    max-height: 350px;
    overflow-y: auto;
    min-height: 300px;
    padding: 1px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .text-continer {
    padding: 0px 10px;
    align-items: flex-start;
  }
  .icon {
    width: 67px;
    height: 73px;
  }

  h1 {
    margin: 0;
    padding: 0;
    color: $primaryTextColor;
    font-size: $largTextSize;
    letter-spacing: 0.04px;
  }

  span {
    color: black;
    font-weight: 300;
    font-size: $largestTextSize;
    // padding: 0px 20px;
  }
  p {
    font-size: $largTextSize;
    color: white;
    text-align: center;
  }
  .button {
    width: 100%;
  }
}
</style>
