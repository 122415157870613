<template>
  <transition name="fade">
    <button
      v-show="!isScrolling"
      @click="handleScroll"
      class="scroll-btn"
      :class="{ 'scroll-down': isAtTop }"
    >
      <img :src="arrowIcon" alt="arrow-icon" />
    </button>
  </transition>
</template>

<script>
export default {
  name: "ScrollButton",
  props: {
    bottomPixels: {
      type: String,
      default: "20px",
    },
  },
  data() {
    return {
      isAtTop: true,
      isScrolling: false,
      scrollTimeout: null,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollEvent);
    this.checkScrollPosition(); // Initial check
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScrollEvent);
  },
  methods: {
    checkScrollPosition() {
      this.isAtTop = window.scrollY < 100;
    },
    handleScrollEvent() {
      this.isScrolling = true;
      this.checkScrollPosition();

      // Clear the existing timeout
      clearTimeout(this.scrollTimeout);

      // Set a new timeout
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false;
      }, 150); // Adjust this value to change how long after scrolling stops before the button appears
    },
    handleScroll() {
      if (this.isAtTop) {
        this.scrollToBottom();
      } else {
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    scrollToBottom() {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    },
  },
  computed: {
    arrowIcon() {
      return this.isAtTop ? "/images/arrow-down.svg" : "/images/arrow-up.svg";
    },
  },
};
</script>

<style scoped lang="scss">
.scroll-btn {
  position: fixed;
  bottom: v-bind(bottomPixels);
  right: 20px;
  background-color: $primaryTextColor;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  z-index: 10;
  color: black;
}

.scroll-btn:hover {
  transform: scale(1.1);
}

.scroll-btn.scroll-down {
  background-color: $primaryColor;
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
