import Api from './Api'
// import SiteService from "@/services/SiteService";
import OrderService from "@/services/OrderService";
import PartService from "@/services/PartService";
import store from '../store/index'

export default {
    verifyPhone(obj) {
        return Api().post('verify-phone', obj)
    },
    verifyOtp(obj) {
        return Api().post('verify-otp', obj)
    },
    changeLang(obj) {
        return Api().post('change-language', obj)
    },
    createNewPassword(email, password, token) {
        return Api().post('create-new-password', { email: email, password: password, token: token })
    },
    resetPass(userEmail) {
        return Api().post('reset-password', { userEmail: userEmail })
    },
    login(credentials) {
        return Api().post('login', credentials)
    },
    getData() {
        return Api().post('customer')
    },
    logLogin(obj) {
        return Api().post('logLogin', obj)
    },
    logReLogin(obj) {
        return Api().post('logReLogin', obj);
    },
    logBadLogin(obj) {
        return Api().post('logBadLogin', obj);
    },
    logBadToken(obj) {
        return Api().post('logBadToken', obj);
    },
    isUserBlocked(obj) {
        return Api().post('isUserBlockedByIp', obj);
    },
    isUserBlockByToken(obj) {
        return Api().post('isUserBlockByToken', obj);
    },
    getOneTimeLogin(obj) {
        return Api().post('getOneTimeLogin', obj)
    },
    async getBigData() {
        /*-get user data:-*/
        try {
            console.log('getBigData')
            // const sites = await SiteService.getSites({});
            // console.log(sites.data);
            store.dispatch("Alert/popupBycust", { cust: store.getters['AuthModule/getUserCust'] });
            await store.dispatch("Alert/alerts");
            await store.dispatch("Alert/alertsPopups");
            await store.dispatch("Alert/lastAlert");
            await store.dispatch("Alert/promotions");
            // store.commit('Sites/setSites', sites.data)

            const ordersa = await OrderService.getOrders({});
            console.log("orders data:");
            console.log(ordersa.data);
            await store.dispatch('Orders/updateOrders', ordersa.data.orders)
            await store.dispatch('Orders/updateCart', ordersa.data.cart)
            await store.dispatch("AuthModule/getAgent", { agent: store.getters['AuthModule/getUserAgent'] })
            await store.dispatch('DistLines/fetchCustDistLines');
            await store.dispatch('DistLines/fetchDistLines');
            await store.dispatch('Orders/fetchCustomerBalances');

            const parts = await PartService.getParts({});
            console.log("parts data:");
            console.log(parts.data);
            store.commit('Parts/setParts', parts.data)

            await store.dispatch('Setting/fetchSettings');

            await store.dispatch('Parts/setFamilies');

            console.log('getBigData end')

            return true
        }
        catch {
            return false
        }

    }
}