<template>
  <div class="category-actions">
    <BaseButton v-if="!isDesktop" @click="toogleCategoryFilterModal"
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 11.276 10.245"
      >
        <path
          id="Combined-Shape"
          d="M9.271,6.256A1.995,1.995,0,1,1,7.265,8.25,2,2,0,0,1,9.271,6.256Zm0,.967A1.028,1.028,0,1,0,10.31,8.25,1.035,1.035,0,0,0,9.271,7.222Zm-4.709.57a.483.483,0,1,1,0,.967H.5a.483.483,0,1,1,0-.967ZM2.006,0A1.995,1.995,0,1,1,0,2,2,2,0,0,1,2.006,0Zm0,.967A1.028,1.028,0,1,0,3.045,2,1.035,1.035,0,0,0,2.006.967Zm8.427.58a.483.483,0,0,1,0,.967H6.374a.483.483,0,0,1,0-.967Z"
          transform="translate(0 0)"
          fill="#ffe6ad"
          fill-rule="evenodd"
        />
      </svg>
      <span id="categories">קטגוריות</span></BaseButton
    >
    <div class="sub-actions flex">
      <BaseButton
        id="myProducts"
        @click="navigateToMyProductsPage"
        bgColor="#393945"
      >
        <span>המוצרים שלי</span></BaseButton
      >
      <BaseButton id="duplicateOrder" @click="openCartModal" bgColor="#393945">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 12.279 13.446"
        >
          <g
            id="Group_12"
            data-name="Group 12"
            transform="translate(-179.399 -7)"
          >
            <g id="copy_icon-icons.com_64492" transform="translate(151.399 6)">
              <g
                id="Group_8"
                data-name="Group 8"
                transform="translate(31.722 1)"
              >
                <g id="Group_7" data-name="Group 7">
                  <path
                    id="Path_2270"
                    data-name="Path 2270"
                    d="M16.278,0h-7A.778.778,0,0,0,8.5.778V2.722h.778V.778h7V9.334H13.945v.778h2.334a.778.778,0,0,0,.778-.778V.778A.778.778,0,0,0,16.278,0Z"
                    transform="translate(-8.5 0)"
                    fill="#ffe6ad"
                  />
                </g>
              </g>
              <g
                id="Group_10"
                data-name="Group 10"
                transform="translate(0 2.334)"
              >
                <g id="Group_9" data-name="Group 9">
                  <path
                    id="Path_2271"
                    data-name="Path 2271"
                    d="M3.834,9.111H7.723a.389.389,0,1,0,0-.778H3.834a.389.389,0,1,0,0,.778ZM9.278,6h-7a.778.778,0,0,0-.778.778v8.556a.778.778,0,0,0,.778.778h7a.778.778,0,0,0,.778-.778V6.778A.778.778,0,0,0,9.278,6Zm0,9.334h-7V6.778h7ZM3.834,10.667H7.723a.389.389,0,1,0,0-.778H3.834a.389.389,0,0,0,0,.778Z"
                    transform="translate(26.5 -4)"
                    fill="#ffe6ad"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <span>שכפול הזמנה אחרונה</span></BaseButton
      >
    </div>
    <teleport to="#modal_backdrop">
      <div class="category-modal-backdrop">
        <transition name="slide-right">
          <CategoriesModal
            v-if="openCategoryModal"
            @exit-modal="toogleCategoryFilterModal"
          />
        </transition>
      </div>
    </teleport>
    <CartModal
      icon="undraw_exclamation-point.png"
      :titleOne="
        cartOrderLinesNumbe
          ? 'פעולה זו תגרום לפריטים הקיימים בעגלה להמחק.'
          : 'פעולה זו תשכפל את הפריטים של ההזמנה האחרונה לעגלה שלך'
      "
      titleTwo="האם ברצונך לאשר את הפעולה?"
      @exit-modal="closeCartModal"
      v-if="isCartModalOpen"
    />
  </div>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import CategoriesModal from "./CategoriesModal.vue";
import { routerNames } from "@/router";
import { mapGetters } from "vuex";
import CartModal from "./CartModal.vue";

export default {
  components: { BaseButton, CategoriesModal, CartModal },
  data() {
    return {
      searchInputText: "",
      openCategoryModal: false,
      isCartModalOpen: false,
    };
  },
  methods: {
    navigateToMyProductsPage() {
      this.$router.push({ path: "/category/המוצרים שלי" });
    },
    async cloneOrder() {
      let latestOrder = this.$store.getters["Orders/getUserOrders"](
        this.getUser.phone
      );
      if (latestOrder === undefined || latestOrder === null) {
        latestOrder = this.$store.getters["Orders/getOrders"][0];
      }
      const cartOrd = this.$store.getters["Orders/getCart"].ord;
      const response = await this.$store.dispatch("Orders/cloneOrderToCart", {
        cartOrd: cartOrd,
        order: latestOrder,
      });
      if (response.status === 200) {
        this.$router.push({ name: routerNames.cart });
      }
    },
    closeCartModal(userRequest) {
      if (userRequest.isConfirmed) {
        this.cloneOrder();
      }
      this.isCartModalOpen = false;
    },
    async openCartModal() {
      this.isCartModalOpen = true;
    },
    toogleCategoryFilterModal() {
      this.openCategoryModal = !this.openCategoryModal;
    },
  },
  computed: {
    ...mapGetters({
      isDesktop: "isDesktop",
      cartOrderLinesNumbe: "Orders/getCartOrderLinesNumber",
      getUser: "AuthModule/getUser",
    }),
  },
};
</script>

<style scoped lang="scss">
button {
  &:hover {
    cursor: pointer;
  }
}
.category-modal-backdrop {
  position: relative;
  z-index: 11;
}
.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.3s ease;
}

.slide-right-enter-from {
  transform: translateX(100%);
}

.slide-right-leave-to {
  transform: translateX(100%);
}
.category-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.category-actions button {
  font-size: 1.2rem;
  max-width: unset;
  font-weight: 500;
  color: $primaryTextColor;
  padding: 5px 10px 8px 10px;

  span {
    margin-right: 4px;
  }
}

#duplicateOrder {
  padding: 7px 10px;
}

#categories {
  font-size: 1.7rem;
}

#myProducts {
  font-size: 1.4rem;
}

.sub-actions {
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 375px) {
  .category-actions button {
    font-size: 1.1rem;
  }
}

@media (max-width: 362px) {
  .category-actions button {
    font-size: 1rem;
  }
  #myProducts {
    font-size: 1.1rem;
  }
}
</style>
