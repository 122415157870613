<template>
    <div class="card-container">
        <slot></slot>
    </div>
</template>


<script>
export default {

}
</script>

<style lang="scss" scoped>
.card-container {
  text-align: start;
  border: 1px solid $primaryTextColor;
  border-radius: 20px;
  background: $secondaryColor;
}
</style>
