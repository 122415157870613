<template>
  <section class="Select flex gap-10" v-if="options">
    <div
      @click="$emit('select', key)"
      class="option flex"
      :class="[isSelected(key), isDisabled(key)]"
      v-for="(option, key) in options"
      :key="key"
    >
      <section class="date-section">
        <img v-if="isSelected(key)" class="icon" src="/images/check-icon.png" />
        <img
          v-else-if="isDisabled(key)"
          class="icon"
          src="/images/uncheck-icon.png"
        />
        <img v-else class="icon empty" src="/images/emptyCheck-icon.png" />
        <span
          class="title felix007"
          :class="[
            isSelected(key)
              ? 'selected'
              : !isPaymentMethod(option)
              ? 'white-text'
              : '',
          ]"
        >
          {{ option.title }}
        </span>
        <img
          v-if="option.icon"
          class="icon"
          :src="getProductImage(option.icon)"
        />
        <span
          v-if="option?.text"
          class="text Heebo"
          :class="isSelected(key) ? 'selected' : ''"
        >
          {{ option.text }}
        </span>
      </section>
      <transition name="fade">
        <section
          class="cash-section"
          v-if="option?.type === 'cash' && isSelected(key)"
        >
          <p style="color: white">
            שים לב! יש להכין תשלום מזומן במעטפה או צ'ק ביום המסירה לנהג
          </p>
        </section>
      </transition>
      <transition name="fade">
        <div
          v-if="
            option?.type === 'credit' && isSelected(key) && isLoadingBalance
          "
          class="progress"
        >
          <div class="indeterminate"></div>
        </div>
        <section
          class="debt-section"
          v-else-if="
            option?.type === 'credit' && isSelected(key) && !isLoadingBalance
          "
        >
          <div class="debt-info" v-if="debtBalance !== 0">
            <div class="debt-balance">
              <span class="debt-title Gisha">{{
                `יתרת ${debtBalance > 0 ? "חוב" : "זכות"}`
              }}</span
              ><span class="debt-price"
                ><span class="currency">₪</span
                >{{
                  getFixedPrice(
                    debtBalance > 0 ? debtBalance : debtBalance * -1
                  )
                }}</span
              >
            </div>
            <div class="debt-balance">
              <span class="debt-title Gisha"
                >סה"כ לתשלום<span class="balance"> (כולל יתרות)</span></span
              ><span class="total-price"
                ><span class="currency felix007">₪</span
                >{{ getFixedPrice(totalPriceWithDebtBalance) }}</span
              >
            </div>
          </div>
        </section>
      </transition>

      <form
        @submit.prevent="updateDistLine"
        class="dist-section"
        v-if="option.days && option.days.length > 0 && isSelected(key)"
      >
        <div class="new-distline-section">
          <div v-if="isNewDistLine">
            <label class="new-line-label" for="new-line-id-input"
              >מספר קו חלוקה</label
            >
            <input
              type="text"
              inputmode="numeric"
              maxlength="2"
              class="white-text"
              id="new-line-id-input"
              v-model="newLinkKey"
              required
            />
          </div>
          <div>
            <label class="new-line-label" for="new-line-des-input"
              >שם קו חלוקה</label
            >
            <input
              type="text"
              class="white-text"
              id="new-line-des-input"
              v-model="lineDes"
              required
            />
          </div>
        </div>
        <div class="distlines-container">
          <div
            v-for="(day, index) in option.days"
            :key="index"
            class="distline"
          >
            <label :for="day.index" class="dist-title Gisha">{{
              day.title
            }}</label
            ><input
              @input="handleCheckBoxChange(index)"
              :id="day.index"
              type="checkbox"
              :checked="day.isSet"
              class="day-checkbox"
            />
          </div>
        </div>
        <div class="dist-line-actions flex gap-10">
          <BaseButton
            @click.prevent="deleteDistLine"
            v-if="!isNewDistLine"
            type="submit"
            class="update-button"
            >מחיקה</BaseButton
          >
          <BaseButton type="submit" class="update-button">{{
            isNewDistLine ? "יצירה" : "עדכון"
          }}</BaseButton>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "./BaseButton.vue";
export default {
  name: "Select",
  components: { BaseButton },
  props: {
    debtBalance: {
      type: Number,
    },
    options: {
      type: Array,
      requierd: true,
    },
    selectedOptions: {
      type: Number,
    },
    totalPriceWithDebtBalance: {
      type: String,
    },
    isNewDistLine: {
      type: Boolean,
    },
    isLoadingBalance: {
      type: Boolean,
    },
  },
  emits: ["select", "update-distLine", "delete-distLine"],
  data() {
    return {
      daysOptions: [],
      newLinkKey: null,
      lineDes: "",
    };
  },
  watch: {
    selectedOptions() {
      this.reloadDays();
      this.lineDes = this.options[this.selectedOptions]?.linedes ?? "";
    },
  },
  computed: {
    ...mapGetters({
      getFixedPrice: "Orders/getFixedPrice",
    }),
  },
  methods: {
    deleteDistLine() {
      const linekey = this.options[this.selectedOptions].linekey;
      this.$emit("delete-distLine", linekey);
    },
    reloadDays() {
      if (this.options[this.selectedOptions].days)
        this.daysOptions = [...this.options[this.selectedOptions].days];
    },
    handleCheckBoxChange(index) {
      this.reloadDays();
      this.daysOptions[index].isSet = !this.daysOptions[index].isSet;
    },
    updateDistLine() {
      this.$emit("update-distLine", {
        linekey: this.newLinkKey ?? this.options[this.selectedOptions].linekey,
        linedes: this.lineDes,
        days: this.daysOptions,
        mode: this.newLinkKey ? "new" : "update",
      });
      if (this.isNewDistLine) {
        this.daysOptions = [];
        this.newLinkKey = null;
        this.lineDes = "";
      }
    },
    isPaymentMethod(option) {
      return (
        option?.type === "credit" ||
        option?.type === "obligo" ||
        option?.type === "cash"
      );
    },
    isSelected(key) {
      if (key === this.selectedOptions && !this.isDisabled(key))
        return "active";
      else "";
    },
    isDisabled(key) {
      return this.options[key]?.disabled ? "disabled" : "";
    },
    getProductImage(iconUrl) {
      return `/images/${iconUrl}-icon.png`;
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-iframe {
  width: 100%;
  min-height: 665px;
  border: none;
}
.update-button {
  margin-bottom: 10px;
  padding: 10px 20px;
}
.fade-enter-active {
  transition: opacity 0.3s;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-active {
  display: none;
}
.date-section {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.dist-section {
  gap: 2px;
  display: flex;
  flex-direction: column;
  width: 100%;

  .new-line-label {
    font-size: $extraSmallTextSize;
    text-align: center;
    width: 100%;
  }

  #new-line-id-input {
    width: 50px;
    text-align: center;
  }

  #new-line-des-input {
    width: 150px;
    text-align: center;
  }
}

.debt-section {
  width: 100%;
}

.div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.distline {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: #c4c4c4;
  align-items: center;

  .dist-title {
    font-size: 16px;
    color: white;
  }

  .day-checkbox {
    width: 15px;
    opacity: 1;
    position: static;
    pointer-events: fill;
    border-bottom: none;
    box-shadow: none;
  }
}

@media (max-width: 615px) {
  .dist-section {
    gap: 2px;
    flex-direction: column;
    align-items: flex-start;
  }

  .distline {
    flex-direction: row-reverse;
    gap: 10px;
  }
}

.debt-balance {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #c4c4c4;
  align-items: center;

  .debt-title {
    font-size: $smallTextSize;
  }

  .balance {
    font-size: 12px;
  }

  .total-price,
  .debt-price {
    font-size: $largTextSize;
    font-family: "felix007";
  }

  .total-price {
    color: $primaryTextColor;
  }

  .currency {
    font-size: 20px;
  }
}
.Select {
  flex-direction: column !important;
}
.option {
  flex-direction: column;
  padding: 5px 20px;
  width: 100%;
  background: $secondaryColor;
  border-radius: 15px;
  color: #c4c4c4;
  justify-content: flex-start;
  border: 2px dashed #c4c4c4;
  cursor: pointer;
  &.active {
    border: 1px solid $primaryTextColor;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: #858585;
    border: none;
  }

  .icon {
    width: 22px;
    height: 22px;
  }
  .empty {
    border: 1px solid #c4c4c4;
    border-radius: 50%;
  }
  .title {
    font-size: $largTextSize;
  }

  .selected {
    color: $primaryTextColor;
  }

  .text {
    font-size: $extraSmallTextSize;
  }

  .distlines-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 20px;
  }

  @media (max-width: 615px) {
    .distlines-container {
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }

  .new-distline-section {
    display: flex;
    gap: 20px;
  }

  @media (max-width: 615px) {
    .new-distline-section {
      flex-direction: column;
      gap: 0px;
    }
  }

  .dist-line-actions {
    margin-top: 10px;
    align-self: flex-end;
  }
}
</style>
