<template>
  <teleport to="#modal_backdrop">
    <div @click="this.$emit('exitModal')" class="backdrop-popup"></div>
    <div class="modal-popup">
      <div>
        <label>כותרת</label>
        <input
          v-model="currPromotion.tilte"
          type="text"
          :placeholder="'כותרת'"
        />
      </div>
      <div>
        <label>קישור המוצר</label>
        <input
          v-model="currPromotion.url"
          type="text"
          :placeholder="'קישור המוצר'"
        />
      </div>
      <div>
        <label>קישור לתמונה</label>
        <input
          v-model="currPromotion.imgUrl"
          type="text"
          :placeholder="'קישור לתמונה'"
        />
      </div>
      <div>
        <label>תוכן</label>
        <textarea
          v-model="currPromotion.contant"
          type="text"
          :placeholder="'תוכן'"
        />
      </div>

      <BaseButton @click="this.savePromotion" class="btn">{{
        saveOrEdit
      }}</BaseButton>
    </div>
  </teleport>
</template>
<script>
import BaseButton from "../helpers/BaseButton.vue";

export default {
  components: {
    BaseButton,
  },
  emits: ["exitModal", "savePromotion"],
  props: {
    saveOrEdit: {
      type: String,
      required: true,
    },
    promotion: {
      type: Object,
    },
  },
  data() {
    return {
      currPromotion: {
        tilte: "",
        contant: "",
        url: null,
        imgUrl: "amit-544.jpg",
      },
    };
  },
  mounted() {
    if (this.promotion) {
      this.currPromotion = { ...this.promotion };
    }
  },
  methods: {
    savePromotion() {
      this.$emit("savePromotion", this.currPromotion);
    },
  },
};
</script>

<style scoped lang="scss">
.backdrop-popup {
  background-color: #000000;
  opacity: 0.6;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 11;
}

.modal-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 12;
  border-radius: 40px;
  background: #393945;
  padding: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 330px;
}
textarea {
  height: fit-content;
  color: white;
  font-size: $mediumTextSize;
}
div {
  width: 100%;
}
label {
  font-size: $mediumTextSize;
}
input {
  color: white;
}
.btn {
  width: 100%;
  padding: 0px;
}
</style>