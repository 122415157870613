export default function downloadBase64AsPDF(base64Data, fileName) {
  // Remove the data URL prefix if present
  const base64WithoutPrefix = base64Data.replace(
    /^data:application\/pdf;base64,/,
    ""
  );

  // Decode the base64 string
  const binaryData = atob(base64WithoutPrefix);

  // Create a Uint8Array from the binary data
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([uint8Array], { type: "application/pdf" });

  // Create a temporary URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a link element and trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName || "document.pdf";
  link.click();

  // Clean up by revoking the temporary URL
  URL.revokeObjectURL(url);
}
