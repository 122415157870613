<template>
  <div class="promotions flex gap-20">
    <BaseButton @click="addPromotion" class="add-promotion"> הוספה </BaseButton>
    <div
      class="promotion"
      :key="promotion.promo"
      v-for="promotion in getPromotions"
    >
      <CarouselProduct :slide="promotion" />
      <BaseButton @click="editPromotion(promotion)" :bgColor="'#393945'">
        עריכה
      </BaseButton>
      <BaseButton @click="clickRemove(promotion)" :bgColor="'#393945'">
        מחיקה
      </BaseButton>
    </div>
    <EditPromotionModal
      :saveOrEdit="textButtonInModal"
      :promotion="currentPromotion"
      @exitModal="isOpenModal = false"
      @savePromotion="savePromotion"
      v-if="isOpenModal"
    >
    </EditPromotionModal>
    <ConfirmDialog
      v-if="isConfirmDialog"
      :text="confirmDialogText"
      @confirm="confirmDialog"
      @deny="denyDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "../helpers/BaseButton.vue";
import CarouselProduct from "../helpers/CarouselProduct.vue";
import EditPromotionModal from "../helpers/EditPromotionModal.vue";
import ConfirmDialog from "../helpers/ConfirmDialog.vue";
export default {
  components: {
    CarouselProduct,
    BaseButton,
    EditPromotionModal,
    ConfirmDialog,
  },
  data() {
    return {
      isOpenModal: false,
      isConfirmDialog: false,
      confirmDialogType: null,
      confirmDialogText: null,
      textButtonInModal: null,
      currentPromotion: null,
    };
  },
  methods: {
    editPromotion(promotion) {
      this.currentPromotion = { ...promotion };
      this.textButtonInModal = "שמור";
      this.isOpenModal = true;
    },
    addPromotion() {
      this.currentPromotion = null;
      this.textButtonInModal = "הוסף";
      this.isOpenModal = true;
    },
    async savePromotion(promotion) {
      await this.$store.dispatch("Alert/savePromotion", promotion);
      this.isOpenModal = false;
    },
    async removePromotion(promo) {
      await this.$store.dispatch("Alert/removePromotion", promo);
      this.isOpenModal = false;
    },
    async confirmDialog() {
      if (this.confirmDialogType === "remove") {
        await this.removePromotion(this.currentPromotion.promo);
        this.isConfirmDialog = false;
      }
    },
    denyDialog() {
      this.currentPromotion = null;
      this.isConfirmDialog = false;
    },
    clickRemove(promotion) {
      this.currentPromotion = { ...promotion };
      this.isConfirmDialog = true;
      this.confirmDialogType = "remove";
      this.confirmDialogText = "האם אתה בטוח רוצה למחוק פרסום זה";
    },
  },
  computed: {
    ...mapGetters({
      getAlerts: "Alert/getAlerts",
      getPromotions: "Alert/getPromotions",
    }),
  },
};
</script>

<style scoped lang="scss">
.add-promotion {
  width: 90%;
}
.promotions {
  padding: 10px;
  flex-direction: column;
}

.promotion {
  padding: 10px;
  max-width: 600px;
}
.product-continer {
  margin-bottom: 10px;
  max-width: 600px;
}
button {
  margin-left: 10px;
}
</style>
