import DistLineService from "../../services/DistLineService";

export default {
  namespaced: true, // all the state, mutations, actions, getters in this module is unique to this store module
  state() {
    return {
      custDistLines: [],
      distLines: [],
    };
  },
  mutations: {
    setCustDisLines(state, custDistLines) {
      state.custDistLines = custDistLines;
    },
    setDistLines(state, distLines) {
      state.distLines = distLines;
    },
    addDistLine(state, distLine) {
      state.distLines.push(distLine);
    },
    removeDistLine(state, lineKey) {
      const foundDistLineIndex = state.distLines.findIndex(
        (line) => line.linekey === lineKey
      );
      state.distLines.splice(foundDistLineIndex, 1);
    },
    setDistLine(state, distLine) {
      const foundDistLineIndex = state.distLines.findIndex(
        (line) => line.linekey === distLine.linekey
      );
      state.distLines.splice(foundDistLineIndex, 1, distLine);
    },
  },
  actions: {
    async fetchCustDistLines(context) {
      const distlines = await DistLineService.getCustDistLines();
      context.commit("setCustDisLines", distlines.data);
    },

    async fetchDistLines(context) {
      const distlines = await DistLineService.getDistLines();
      context.commit("setDistLines", distlines.data);
    },

    async deleteDistLine(context, lineKey) {
      try {
        await DistLineService.deleteDistLine({lineKey: lineKey});
        context.dispatch(
          "stopLoading",
          {
            status: "success",
            message: "קו החלוקה נמחק בהצלחה!",
          },
          { root: true }
        );
        context.commit("removeDistLine", lineKey);
      } catch (err) {
        console.log(err);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: err.response.data.error,
          },
          { root: true }
        );
      }
    },

    async updateDistLine(context, distLineObj) {
      if (Object.keys(distLineObj).length === 1) {
        context.dispatch(
          "stopLoading",
          {
            status: "success",
            message: "קו החלוקה עודכן בהצלחה!",
          },
          { root: true }
        );
        return;
      }
      try {
        await DistLineService.updateDistLine(distLineObj);
        context.dispatch(
          "stopLoading",
          {
            status: "success",
            message:
              distLineObj.mode === "update"
                ? "קו החלוקה עודכן בהצלחה!"
                : "קו החלוקה נוצר בהצלחה!",
          },
          { root: true }
        );
        if (distLineObj.mode === "new") {
          context.commit("addDistLine", distLineObj);
        } else {
          context.commit("setDistLine", distLineObj);
        }
      } catch (err) {
        console.log(err);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: err.response.data.error,
          },
          { root: true }
        );
      }
    },
  },
  getters: {
    getDistLines(state) {
      return state.distLines;
    },
    getCustDistLines(state) {
      return state.custDistLines;
    },
  },
};
