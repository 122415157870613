import InvociesService from "../../services/InvociesService";
import downloadBase64AsPDF from "../../utility";

export default {
  namespaced: true,
  actions: {
    async sendInvoicePayment(context, invoicePayLoad) {
      context.dispatch("startLoading", null, { root: true });
      try {
        await InvociesService.sendInvoicePayment(invoicePayLoad);
        context.dispatch("stopLoading", null, { root: true });
      } catch (error) {
        console.log(error);
        context.dispatch(
          "stopLoading",
          { status: "error", message: "שגיאה בניסיון יצירת קבלה" },
          { root: true }
        );
      }
    },
    async delevires(context, docId) {
      context.dispatch("startLoading", null, { root: true });
      try {
        const base64file = await InvociesService.delevires(docId);
        downloadBase64AsPDF(base64file.data.result, `${docId}.pdf`);
        context.dispatch("stopLoading", null, { root: true });
      } catch (error) {
        if (error?.response?.status === 404 && error?.response?.data?.message) {
          context.dispatch(
            "stopLoading",
            { status: "error", message: error.response.data.message },
            { root: true }
          );
        } else {
          context.dispatch(
            "stopLoading",
            { status: "error", message: "קובץ התעודה להורדה אינו נמצא" },
            { root: true }
          );
        }
      }
    },
  },
};
