<template>
  <div class="cart-info-container">
    <div class="cart-info">
      <span class="title">מוצרים</span>
      <span class="number">{{
        this.$store.getters["Orders/getCartOrderLinesNumber"]
      }}</span>
    </div>
    <SperetorLine rotateVertical hrWidth="20px" />
    <div class="cart-info">
      <span class="title">קרטונים</span>
      <span class="number">{{
        this.$store.getters["Orders/getCartTotal"]
      }}</span>
    </div>
    <SperetorLine rotateVertical hrWidth="20px" />
    <div class="cart-info">
      <span class="title">סה"כ לתשלום</span>
      <span class="number highlithed"
        ><span style="font-size: 16px">₪</span
        >{{
          getFixedPrice(this.$store.getters["Orders/getCartTotalPrice"])
        }}</span
      >
    </div>
  </div>
</template>

<script>
import SperetorLine from "./SperetorLine.vue";
import { mapGetters } from "vuex";

export default {
  components: { SperetorLine },
  name: "CartSummary",
  computed: {
    ...mapGetters({
      getFixedPrice: "Orders/getFixedPrice",
    }),
  },
};
</script>

<style scoped lang="scss">
.cart-info-container {
  display: flex;
  align-items: center;
}

.cart-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 2px;

  .title,
  .number {
    color: white;
  }
  .title {
    font-size: $extraSmallTextSize;
  }

  .number {
    font-size: 22px;
    font-weight: bold;
    margin-top: -5px;
  }

  .number.highlithed {
    color: $primaryTextColor;
  }
}
</style>
