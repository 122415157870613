<template>
  <section class="side-container">
    <BaseSideBar>
      <!-- list of Statuses -->
      <DatesSearch />
      <transition-group tag="div" name="menu-open-mobile">
        <h2 v-if="!needToCloseSideBar">{{ $t("sideBar.orderStatus") }}</h2>

        <ul class="collection menu-collection" v-if="!needToCloseSideBar">
          <router-link
            @click.prevent="navigateToUrl('הכל')"
            class="collection-item"
            to="/orders/הכל"
            tag="a"
            >{{ $t("sideBar.showAll") }}
          </router-link>
          <router-link
            v-for="status of Statuses"
            :key="status.id"
            :to="'/orders/' + status.name"
            tag="a"
            class="collection-item"
            @click.prevent="navigateToUrl(status.name)"
          >
            {{ isLangHebrew ? status.name : status.enName }}
          </router-link>
        </ul>
      </transition-group>
    </BaseSideBar>
  </section>
</template>

<script>
import BaseSideBar from "./BaseSideBar.vue";

import { mapGetters } from "vuex";
import DatesSearch from "../helpers/DatesSearch.vue";

export default {
  components: {
    BaseSideBar,
    DatesSearch,
  },
  data() {
    return {
      // currentSelectOptionText: "test",
    };
  },
  methods: {
    navigateToUrl(statusName) {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          status: statusName,
        },
        query: {
          ...this.$route.query,
        },
      });
      this.$store.commit("toggleMenu");
    },
  },
  computed: {
    isLangHebrew() {
      return this.getUserLang == "he" ? true : false;
    },
    needToCloseSideBar() {
      if (
        (this.$store.getters.isMobile || this.$store.getters.isTablet) &&
        !this.$store.state.openMenu
      ) {
        // this.$store.commit("toggleMenu");
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters({
      Statuses: "Orders/getOrderStatuses",
      getUserLang: "AuthModule/getUserLang",
    }),
    getParamStatus() {
      return this.$route.params.status;
    },
  },
};
</script>

<style scoped lang="scss">
.side-container {
  background: #2a2929;
  padding: 30px 10px;
  min-width: 20vw !important;
  margin: unset !important;
  margin-bottom: 100px !important;
  box-shadow: 1px 0px 6px 4px rgba(158, 158, 158, 0.368627451);
  border-radius: 0px 0px 20px 20px;

  h2 {
    color: $primaryTextColor;
    font-size: 1.64rem;
    line-height: 110%;
    font-weight: 100;
    padding: 0 5px;
  }
}

@media (max-width: 601px) {
  .side-bar-container {
    padding: unset;
  }
}
.menu-open-mobile-enter-from,
.menu-open-mobile-leave-to {
  max-height: 0px;
}
.menu-open-mobile-enter-active,
.menu-open-mobile-leave-active {
  transition: all 0.2s ease;
  overflow: hidden;
}
.menu-open-mobile-enter-to,
.menu-open-mobile-leave-from {
  max-height: 1000px;
}
h2 {
  font-size: 20px;
  font-weight: bold;
  padding: 0px 20px;
  margin: 30px 0 10px 0;
}

.material-icons {
  font-size: unset;
}
.collection-item {
  background-color: $secondaryColor;
  span {
    font-weight: 400;
    padding: 10px;
  }
}
.menu-collection {
  border-radius: 20px;
  opacity: 0.9;
  a {
    display: flex;
    align-items: center;
    color: white !important;
    i {
      margin-left: 10px;
    }
  }

  a:not(.active):hover,
  a.router-link-active   {
    background-color: #15151f !important;
    color: $primaryTextColor !important;
  }
}
</style>
