<template>
  <section class="flex gap-10">
    <TransitionGroup tag="div" class="TransitionGroup" :name="transitionName">
      <CarouselProduct
        v-for="slide in slides"
        :key="slide.promo"
        :slide="slide"
      />
    </TransitionGroup>
  </section>
</template>

<script>
import CarouselProduct from "../helpers/CarouselProduct.vue";
export default {
  data() {
    return {
      transitionName: null,
      startY: 0,
      startX: 0,
      lastSwipeRightTime: null,
      lastSwipeLeftTime: null,
    };
  },
  props: {
    slides: {
      type: Array,
      requierd: true,
    },
    current: {
      type: Number,
      requierd: true,
    },
  },
  mounted() {
    const scrollableElement = document.querySelector(".TransitionGroup");
    const cardWidth = window.innerWidth * 0.9 + 10;
    // const numCards = Math.ceil(scrollableElement.scrollWidth / cardWidth);

    const debouncedHandler = this.debounce(() => {
      let scrollPosition = -scrollableElement.scrollLeft;
      let currentCardIndex = Math.round(scrollPosition / cardWidth);
      let targetScrollPosition = -currentCardIndex * cardWidth;

      if (scrollPosition !== targetScrollPosition) {
        scrollableElement.scrollLeft = targetScrollPosition;
      }

      this.$emit("setCurrent", currentCardIndex);
    }, 400); // Adjust the debounce time as needed

    scrollableElement.addEventListener("touchend", debouncedHandler);
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },
  },
  emits: ["setCurrent"],
  components: {
    CarouselProduct,
  },
};
</script>

<style lang="scss" scoped>
section {
  flex-direction: column;
}
.TransitionGroup {
  display: flex;
  flex-direction: row;
  width: 90vw;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 10px;
  height: 135px;
  overflow-x: scroll;
  gap: 10px;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
