<template>
  <section class="dates-section">
    <a @click="toggleDataRange" class="date-range-text">
      {{
        !isRangeDatesOptionClicked ? "בחירת טווח תאריכים" : "בחירת תאריך יחיד"
      }}</a
    >
    <div class="dates">
      <div class="date-container">
        <label for="date-input">{{
          isRangeDatesOptionClicked ? "מתאריך" : "בחירת תאריך"
        }}</label>
        <input
          class="Heebo"
          :class="{ dateinput: isSafari }"
          :style="
            !isRangeDatesOptionClicked && isSafari ? { width: '340px' } : {}
          "
          id="date-input"
          v-model="date"
          type="date"
        />
      </div>
      <div v-if="isRangeDatesOptionClicked" class="date-container">
        <label for="date-input-two">עד לתאריך</label>
        <input
          class="Heebo"
          id="date-input-two"
          v-model="dateTwo"
          type="date"
          :class="{ dateinput: isSafari }"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DatesSearch",
  data() {
    return {
      date: "",
      dateTwo: "",
    };
  },
  mounted() {
    if (this.$route.query.date) {
      this.date = this.$route.query.date;
    }
  },
  methods: {
    toggleDataRange() {
      this.$store.commit("Orders/toggleIsRangeDatesOptionClicked");
      this.dateTwo = "";
    },
    removeSpecificQuery(key) {
      let query = {
        ...this.$route.query,
      };
      delete query[key];

      this.$router.push({
        path: this.$route.path,
        query: query,
      });
    },
    async setRouteQuery(query) {
      query = {
        ...this.$route.query,
        ...query,
      };
      await this.$router.push({ path: this.$route.path, query: query });
      // console.log({ ...this.$route.query, ...this.$route.params });
    },
  },
  watch: {
    dateTwo() {
      if (this.dateTwo) {
        this.setRouteQuery({ dateTwo: this.dateTwo });
      } else {
        this.removeSpecificQuery("dateTwo");
      }
    },
    date() {
      if (this.date) {
        this.setRouteQuery({ date: this.date });
      } else {
        this.removeSpecificQuery("date");
      }
    },
  },
  computed: {
    ...mapGetters({
      isSafari: "isSafari",
      isRangeDatesOptionClicked: "Orders/getIsRangeDatesOptionClicked",
    }),
  },
};
</script>

<style scoped lang="scss">
.dates {
  display: flex;
  gap: 8px;
}
.dates-section {
  flex-direction: column;
}
.date-range-text {
  color: $primaryTextColor;
  font-size: $mediumTextSize;
  letter-spacing: 0.05px;
  text-decoration: underline;
  cursor: pointer;
}

.date-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  input {
    background: #15151f;
    border: 1px #c4c4c4 solid;
    border-radius: 10px;
    width: 100%;
    max-width: 400px !important;
    color: #c4c4c4;
    font-family: "felix007";
    font-size: 20px !important;
    position: relative;
    padding-right: 32px;
    cursor: pointer;
  }

  input::-webkit-calendar-picker-indicator {
    background-image: url("/images/calendar-icon.png");
    background-position: center;
    background-size: 12px;
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  .dateinput {
    width: 170px;
  }

  label {
    font-size: $smallTextSize;
    color: $primaryTextColor;
    background: #15151f;
    width: fit-content;
    margin-bottom: -12px;
    margin-right: 11px;
    position: relative;
    z-index: 1;
  }
}
</style>
