<template>
  <div class="massage-background flex gap-10">
    <img class="party-icon" :src="getIconSrc" />
    <span class="white-text"> <slot></slot> </span>
  </div>
</template>

<script>
export default {
  name: "BaseMessage",
  props: {
    // massage: {
    //   type: String,
    //   requierd: true,
    // },
    messageIcon: {
        type: String
    }
  },
  computed: {
    getIconSrc() {
        if (this.messageIcon) return`/images/${this.messageIcon}-icon.png`;
        else return "/images/party-icon.png";
    }
  }
};
</script>

<style scoped lang="scss">
.massage-background {
  background: $secondaryColor;
  border-radius: 10px;
  padding: 10px 15px;
  justify-content: flex-start;
  flex-basis: 100%;
  span{
    font-size: 15px;
  }
}
.party-icon {
  width: 15px;
  height: 15px;
}
</style>
