<template>
  <button class="waves-effect" :disabled="isDisabled" :style="btnStyles">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "#6d1106",
    },
  },
  computed: {
    btnStyles() {
      return {
        "background-color": this.bgColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.waves-light {
  background-color: purple;
}

button {
  max-width: 400px;
  background: $primaryColor;
  border-radius: 50px;
  color: white;
  font-size: $mediumTextSize;
  cursor: pointer;
  padding: 12px 8px 10px 12px;
  text-align: center;
  border-style: none;
  box-shadow: 1px 1px 1px $primaryTextColor;
}

@media (min-width: 1200px) {
  button:not(:disabled):hover {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5), 1px 1px 20px $primaryTextColor;
  }
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
