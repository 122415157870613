import SettingService from "../../services/SettingService";
export default {
  namespaced: true, // all the state, mutations, actions, getters in this module is unique to this store module
  state() {
    return {
      settings: {},
      settingsArray: [],
    };
  },
  mutations: {
    setSettings(state, settings) {
      state.settingsArray = settings;
      const settingsObj = {};

      settings.forEach((setting) => {
        settingsObj[setting.metaKey] = setting.metaValue;
      });
      state.settings = settingsObj;
    },
    setSettingsKeys(state, settings) {
      Object.keys(settings).forEach(
        (key) => (state.settings[key] = settings[key])
      );
    },
  },
  actions: {
    async fetchSettings(context) {
      const settings = await SettingService.getSettings();
      context.commit("setSettings", settings.data);
    },

    async updateSettings(context, settingsArray) {
      context.dispatch("startLoading", null, { root: true });
      try {
        const response = await SettingService.updateSettings(settingsArray);
        context.dispatch(
          "stopLoading",
          { status: "success", message: response.data.message },
          { root: true }
        );
        context.commit("setSettings", settingsArray);
      } catch (err) {
        console.log(err);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: err.response.data.message,
          },
          { root: true }
        );
      }
    },
  },
  getters: {
    getSettings(state) {
      return state.settings;
    },
    getSettingsArray(state) {
      return state.settingsArray;
    },
    isLastOrderHourPassed: (state) => (date) => {
      const todayDate = new Date();
      const deliveryDateTime = new Date(date);
     
      // Edit deadline is 1 day before delivery at the time configured on the system
      let deadline = new Date(deliveryDateTime);
      deadline.setDate(deadline.getDate() - 1);
      // if (deadline.getDay() + 1 === 7) { // if the deadline is on Saturday
      //   deadline = new Date(todayDate);
      // }
      const time = state.settings.lastOrderHour;
      const [hour, minutes] = time.split(":");
      deadline.setHours(hour);
      deadline.setMinutes(minutes);
      return todayDate > deadline;
    },
  },
};
