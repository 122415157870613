import Api from "./Api";
export default {
  alertsPopups() {
    return Api().get("alertsPopups");
  },
  alerts() {
    return Api().get("alerts");
  },
  lastAlert() {
    return Api().get("lastAlert");
  },
  popupBycust(obj) {
    return Api().get("popupBycust", { params: obj });
  },
  approvePopUp(obj) {
    return Api().post("approvePopUp", obj);
  },
  promotions() {
    return Api().get("promotions");
  },
  savePromotion(obj) {
    return Api().post("savePromotion", { promotion: obj });
  },
  removePromotion(promo) {
    return Api().post("removePromotion", { promo: promo });
  },
  saveAlert(obj) {
    return Api().post("saveAlert", { alert: obj });
  },
  removeAlert(alert) {
    return Api().post("removeAlert", { alert: alert });
  },
  saveSubscriber(subObj) {
    return Api().post("saveSubscriber", subObj);
  },
  removeSubscriber() {
    return Api().get("removeSubscriber");
  },
};
