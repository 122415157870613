<template>
  <section>
    <BaseButton
      @click="callToOffice"
      :bgColor="'#393945'"
      class="contact-button"
    >
      <div>
        <div class="button-continer gap-05">
          <img class="icon" src="/images/phone-icon.png" />
          <span>שיחה למשרד</span>
        </div>
      </div>
    </BaseButton>
    <BaseButton
      @click="mailToOffice"
      :bgColor="'#393945'"
      class="contact-button"
    >
      <div>
        <div class="button-continer gap-05">
          <img class="icon" src="/images/massage-icon.png" />
          <span>הודעה למשרד</span>
        </div>
      </div>
    </BaseButton>
    <BaseButton
      @click="whatsappToAgent"
      :bgColor="'#393945'"
      class="contact-button"
    >
      <div>
        <div class="button-continer gap-05">
          <img class="icon" src="/images/whatsApp-icon.png" />
          <span>הודעה לסוכן</span>
        </div>
      </div>
    </BaseButton>
  </section>
</template>

<script>
import BaseButton from "../helpers/BaseButton.vue";
export default {
  components: {
    BaseButton,
  },
  methods: {
    callToOffice() {
      const officePhone =
        this.$store.getters["Setting/getSettings"].officeNumberPhone;
      window.open(`tel:${officePhone}`);
    },
    whatsappToAgent() {
      const agentPhone =
        this.$store.getters["AuthModule/getAgentCust"].contactphone;
      window.open(`https://wa.me/${agentPhone}`);
    },
    mailToOffice() {
      const officeMail =
        this.$store.getters["Setting/getSettings"].officeMailAddress;
      window.open(`mailto:${officeMail}`);
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
}
.contact-button {
  width: 30vw;
  padding: 0px;
  min-width: 110px;
  height: 60px;
  border-radius: 20px;
  &:hover {
    cursor: pointer;
  }
  .button-continer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
  span {
    font-size: $extraSmallTextSize;
  }
}
.icon {
  width: 22px;
  height: 22px;
}
</style>