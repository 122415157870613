<template>
  <div class="main-loginpage">
    <div class="login-container">
      <div v-if="isLaptop || isTablet || isMobile" class="balkan-background" />
      <section
        v-if="isLaptop || isTablet || isMobile"
        class="login-page-heading"
      >
        <img src="/images/logo.png" />
        <h1>BALKAN BAKERY</h1>
        <h5>SINCE 1936</h5>
      </section>
      <form @submit.prevent="verifyPhone" class="login-page-form">
        <h1 class="felix007">
          {{ $t("LoginPage.titleOne") }}<br />
          {{ $t("LoginPage.titleTwo") }}
        </h1>
        <input
          aria-label="phoneNumber"
          @focus="clearErrorMessage"
          required
          type="number"
          inputmode="numeric"
          v-model="userPhone"
          :placeholder="$t('LoginPage.phoneNumberPlaceholder')"
        />
        <BaseButton class="Gisha" :isDisabled="loading" type="submit">{{
          $t("LoginPage.loginButton")
        }}</BaseButton>
        <!-- <a class="forgot_pass">{{ $t("LoginPage.forgetPassButton") }}</a> -->
      </form>
    </div>
    <div v-if="isDesktop">
      <img class="hero-img" src="/images/Background-login.png" alt="" />
    </div>
  </div>
</template>

<script>
import AuthService from "@/services/AuthService";
import BaseButton from "../helpers/BaseButton.vue";
import { mapGetters } from "vuex";
import { routerNames } from "@/router";

export default {
  components: { BaseButton },
  data() {
    return {
      enterdEmail: "",
      enterdPassword: "",
      showIconPassShow: true,
      loading: false,
      authFaild: false,
      isPassShow: false,
      invalidValue: false,
      errorMessage: "",
      resetPassModalIsVisible: false,
      userPhone: "",
    };
  },
  computed: {
    ...mapGetters({
      isDesktop: "isDesktop",
      isLaptop: "isLaptop",
      isTablet: "isTablet",
      isMobile: "isMobile",
    }),
  },
  methods: {
    clearErrorMessage() {
      this.errorMessage = "";
    },
    async verifyPhone() {
      this.$store.commit("setLoading", true);
      // this.loading = true;
      try {
        var res = await this.$store.dispatch(
          "AuthModule/checkIfUserBlockedIp",
          {
            phoneNumber: this.userPhone,
          }
        );
        if (res) return;
        const response = await this.$store.dispatch(
          "AuthModule/verifyPhone",
          "0" + this.userPhone
        );

        if (response && response.status === 200) {
          this.$router.push({ name: routerNames.loginSms });
        }
      } catch (error) {
        console.log(error);
      }
      this.$store.commit("setLoading", false);
      // this.loading = false;
      this.userPhone = "";
    },
    showPass() {
      var input = document.querySelector("#password");
      if (this.isPassShow) {
        input.type = "password";
      } else {
        input.type = "text";
      }
      this.isPassShow = !this.isPassShow;
      console.log(input);
    },
    counter() {
      this.$store.state.counter++;
    },
    async submitedData() {
      if (this.enterdEmail === "" || this.enterdPassword === "") {
        this.invalidValue = true;
      } else {
        this.invalidValue = false;
        this.loading = true;

        try {
          const response = await AuthService.login({
            email: this.enterdEmail,
            password: this.enterdPassword,
          });

          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("token", response.data.token);

          this.$i18n.locale = response.data.user.language;

          await this.$store.dispatch("AuthModule/login", {
            user: response.data.user,
            token: response.data.token,
          });
          this.showIconPassShow = false;

          // console.log("user date:");
          // console.log(response.data.user);
          // console.log("");
          const getBigDataSucceeded = await AuthService.getBigData();
          console.log("get big data Succeeded?");
          console.log(getBigDataSucceeded);
          // להתייעץ עם שלומי איך להעיף את המשתמש מכאן

          this.loading = false;
          this.authFaild = false;
          this.$router.replace("/");
        } catch (error) {
          this.loading = false;
          console.log(error);
          this.errorMessage = "לא ניתן להתחבר, שם משתמש או סיסמה אינם נכונים";
          this.authFaild = true;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
//desctop setting
@media (min-width: 1024px) {
  .login-container {
    min-width: 20vw;
  }
  .login-page-form {
    background: #2a2929;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100% !important;
    margin: unset !important;
    box-shadow: 1px 0px 6px 4px #9e9e9e5e;
    border-radius: 0px 0px 20px 20px;
  }
  .login-page-heading {
    // margin-top: -300px !important;
  }

  .main-loginpage {
    display: flex;
    gap: 20px;
  }
}
.login-container {
  background: black;
}

.forgot_pass {
  color: white;
  font-size: $smallTextSize;
  letter-spacing: 0.05px;
  margin-bottom: 25px;
}

.login-page-form {
  margin: auto;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  gap: 20px;
  text-align: center;
}

.login-page-form h1 {
  font-size: $largTextSize;
  letter-spacing: 0.05px;
  margin: 0;
  padding: 0;
  color: #ffe6ad;
}

.login-page-form input {
  border: solid 1px #6d1106;
  border-radius: 50px;
  text-align: center;
  background: white;
  padding: 24px 0px 25px 0px;
  width: 214px;
}

.login-page-form input::placeholder {
  font-family: "Gisha", serif;
  font-size: $mediumTextSize !important;
  color: #585858;
}

.login-page-heading {
  text-align: center;
  margin-top: -250px;
}

.login-page-heading h1,
h5 {
  margin: 0;
  color: #ffe6ad;
  font-family: "Times New Roman", Times, serif;
}

.login-page-heading h5 {
  font-size: $smallTextSize;
  box-shadow: inset 0 3px 6px #00000015;
}

.login-page-heading h1 {
  font-size: 35px;
  font-weight: bold;
}

.balkan-background {
  background-image: linear-gradient(
      to bottom,
      #ffffff00,
      #b9b9b900,
      #77777700,
      #222222,
      #000000
    ),
    url("/images/Background.png");
  height: 60vh;
  background-size: cover;
  color: white;
}
.password-input-container {
  position: relative;

  #password {
    margin: unset !important;
  }
  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 22px;
    cursor: pointer;
    color: grey;
  }
}
.login-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}
a {
  color: $secondaryTextColor;
  cursor: pointer;
}

#forgot-password-container {
  margin-top: 20px;
}

h1 {
  font-size: $largTextSize;
}
.hero-img {
  height: 600px;
  object-fit: cover;
  border-radius: 10px;
  margin-top: 10px;
  width: 1280px;
}
@media only screen and (max-width: 900px) {
  .material-icons {
    right: 320px;
  }
}
</style>
