<template>
  <section class="balance-continer flex gap-10">
    <div class="balance-header">
      <div class="flex gap-05">
        <img class="iconWithGrayBg" src="/images/credit-card.png" />
        <h1>יתרת {{ debtOrRightBalance }}</h1>
      </div>
      <span v-if="maxBalance" class="white-text balance">
        {{ (this.balanceSpended).toLocaleString("en-US") }} ₪
      </span>
    </div>
    <div class="balance-bar-continer">
      <div class="balance-bar">
        <div :style="barSpended" class="balance-spended"></div>
        <div :style="maxBalanceBar" class="max-balance"></div>
      </div>
      <span>
        נוצל {{ balanceSpendedPercent }}%
      </span>
    </div>
  </section>
</template>

<script>
export default {
  name: "Balance",
  props: {
    maxBalance: {
      type: Number,
      requierd: true,
      default: 1,
    },
    balanceSpended: {
      type: Number,
      requierd: true,
      default: 0,
    },
    maxBalancePercent: {
      type: Number,
      default: 80,
    },
  },
  computed: {
    balanceSpendedPercent() {
      if (this.balanceSpended < 0) return 0;
      return ((this.balanceSpended / this.maxBalance) * 100).toFixed(0);
    },
    barSpended() {
      const width = this.balanceSpended < 0 ? 0 : (this.balanceSpended / this.maxBalance) * 100;
      return {
        width: `${width}%`,
      };
    },
    maxBalanceBar() {
      return {
        right: `${this.maxBalancePercent}%`,
      };
    },
    debtOrRightBalance() {
      return this.balanceSpended < 0 ? "זכות" : "חוב";
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  font-size: $mediumTextSize;
  margin: 0px;
  color: $primaryTextColor;
}
span {
  color: $primaryTextColor;
}
.balance-continer {
  flex-direction: column;
  width: 100%;
  .balance-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .balance {
      font-size: 20px;
    }
  }
  .balance-bar-continer {
    text-align: start;
    width: 100%;
    .balance-bar {
      width: 100%;
      height: 10px;
      background: $secondaryColor;
      border-radius: 10px;
      position: relative;
    }
    .balance-spended {
      background: linear-gradient(
        270deg,
        $primaryTextColor,
        85%,
        $secondaryColor
      );
      border-radius: 10px;
      height: inherit;
    }
    .max-balance {
      width: 2px;
      height: 16px;
      background: $primaryTextColor;
      position: absolute;
      top: -3px;
    }
  }
}
</style>