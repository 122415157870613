<template>
  <div>
    <div class="search-container">
      <div class="magnifying_glass_icon-continer">
        <img
          class="magnifying_glass_icon"
          src="../../../public/images/magnifying_glass_icon_172355.png"
        />
      </div>
      <input
        @keyup="gotosearch"
        v-model="searchword"
        name="search"
        placeholder="חיפוש לפי שם המוצר"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseSearch",
  data() {
    return {
      searchword: "",
    };
  },
  mounted() {
    const searchInput = this.$route.query.search;
    if (typeof searchInput !== "undefined") this.searchword = searchInput;
  },
  watch: {
    "$route.query"() {
      const searchInput = this.$route.query.search;
      if (typeof searchInput === "undefined") this.searchword = "";
    },
  },
  methods: {
    async gotosearch() {
      this.searchword.length > 0
        ? (this.$store.commit("setMenuToClose"),
          this.$store.commit("setMobileNavText", this.$t("BaseSearch.search")))
        : this.$store.commit("setMenuToOpen"); // close menu in mobile when typing in search input

      let query = { ...this.$route.params, ...this.$route.query };
      delete query.category;
      if (this.searchword.length >= 1) {
        query.search = this.searchword;
        this.$router.push({ path: "/category/חיפוש", query: query });
        if (!this.$route.params.category) {
          // this.$router.push({ path: "/category/חיפוש", query: query });
        } else {
          // this.$router.push({
          //   name: this.$route.name,
          //   params: {
          //     ...this.$route.params,
          //   },
          //   query: {
          //     ...this.$route.query,
          //     search: this.searchword,
          //   },
          // });
        }
      } else {
        let query = {
          ...this.$route.query,
        };
        delete query["search"];

        this.$router.push({
          path: this.$route.path,
          query: query,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.category-actions {
  display: flex;
  justify-content: space-between;
}
.category-actions button {
  font-size: 1.2rem;
  color: $primaryTextColor;
  padding: 8px 10px;
}

.search-container {
  margin-bottom: 5px;
  position: relative;
}

.magnifying_glass_icon-continer {
  background-color: $primaryTextColor;
  position: absolute;
  border-radius: 50%;
  height: 41px;
}
.magnifying_glass_icon {
  width: 42px;
  right: 0;
  padding: 12px;
}
input {
  border: 1px solid #ffe6ad;
  background: $secondaryColor;
  border-radius: 50px;
  padding: 20px 0;
  padding-right: 50px;
  color: white;
}

input::placeholder {
  color: #c4c4c4;
}

@media (min-width: 1200px) {
  input::placeholder,
  input {
    font-size: $smallTextSize !important;
  }
}

// span.highlight {
//   font-weight: 700 !important;
// }

ul {
  left: unset !important;
  padding: unset !important;
  li {
    span {
      text-align: right !important ;
    }
  }
}
.input-field .prefix {
  position: absolute;
  font-size: 1.7rem;
  top: 50%;
  transform: translateY(-50%);
}
.input-field {
  color: #a4a4a4;
  height: 45px !important;
  background-color: $primaryBackgroundColor;
  border-radius: 10px;

  i.prefix.active {
    color: $primaryColor;
  }

  #autocomplete-input {
    margin: 0;
    margin-right: 4rem;
    width: calc(100% - 4rem) !important;
    border: unset !important;
    box-shadow: unset !important;
    padding-left: 25px;
    padding-top: 5px;
  }
  label {
    margin-right: 5rem !important;
    width: calc(100% - 4rem - 1.5rem) !important;
    left: unset !important;
    right: 0 !important;
    font-size: $mediumTextSize;
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
  label:lang(en) {
    left: 0px !important;
  }
  label.active {
    margin-right: 5rem !important;
    color: $primaryColor !important;
  }
  label:not(.label-icon).active {
    transform: translateY(-42px) scale(1) !important;
  }
}
@media (max-width: 601px) {
  .input-field {
    padding: 5px 0;
  }
}
</style>
