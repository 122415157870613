import AuthService from "@/services/AuthService";
import CustomerService from "@/services/CustomerService";
// import { setBlockTracking } from "vue";

export default {
  namespaced: true, // all the state, mutations, actions, getters in this module is unique to this store module
  state() {
    return {
      isLoggedIn: false,
      isAuthFailed: false,
      user: null,
      token: null,
      tempPhoneNumber: null,
      agent: null,
    };
  },
  mutations: {
    setUserPhone(state, userObj) {
      state.tempPhoneNumber = userObj.phone;
    },
    setUser(state, userObj) {
      state.user = userObj;
    },
    setUserLang(state, lang) {
      state.user.language = lang;
    },
    setToken(state, token) {
      state.token = token;
    },
    setAgent(state, agent) {
      state.agent = agent;
    },
    setDistLines(state, distLines) {
      state.distLines = distLines;
    },
  },
  actions: {
    async getOneTimeLogin(context, obj) {
      this.dispatch("startLoading", null, { root: true });
      try {
        const response = await AuthService.getOneTimeLogin(obj);
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("token", response.data.token);
          context.commit("setToken", response.data.token);
          context.commit("setUser", response.data.user);
          await AuthService.logLogin(response.data.user);
          await AuthService.getBigData();
          return true;
        }
      } catch (error) {
        console.log(error);
        return false;
      } finally {
        this.dispatch("stopLoading", null, { root: true });
      }
    },
    async getAgent(context, obj) {
      var response = await CustomerService.getAgentOfUser(obj);
      context.commit("setAgent", response.data);
      return response;
    },
    async updateLanguageInDB(context, lang) {
      try {
        let res = await AuthService.changeLang({
          phone: context.state.user.phone,
          language: lang,
        });
        return res;
      } catch (err) {
        console.log(err);
      }
    },

    async verifyOtp(context, obj) {
      var { phoneNumber } = obj;
      this.dispatch("startLoading", null, { root: true });
      try {
        const response = await AuthService.verifyOtp(obj);
        if (response.status === 200) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem("token", response.data.token);
          context.commit("setToken", response.data.token);
          context.commit("setUser", response.data.user);
          await AuthService.logLogin(response.data.user);
          await AuthService.getBigData();
          this.dispatch("stopLoading", null, { root: true });
          return response;
        }
      } catch (error) {
        const response = error.response;
        if (response.status === 403 || response.status === 500) {
          await AuthService.logBadToken({ phoneNumber: phoneNumber });
          context.dispatch(
            "stopLoading",
            {
              status: "error",
              message: response.data.error,
            },
            { root: true }
          );
        }
      }
    },

    async verifyPhone(context, phone) {
      try {
        const response = await AuthService.verifyPhone({
          phoneNumber: phone,
        });
        if (response.status === 200) {
          context.commit("setUserPhone", {
            phone: phone,
          });
          context.dispatch(
            "stopLoading",
            { status: "success", message: "הודעת SMS נשלחה לנייד שלך" },
            { root: true }
          );
          return response;
        }
      } catch (error) {
        const response = error.response;
        if (response.status === 403 || response.status === 500) {
          await AuthService.logBadLogin({ phoneNumber: phone });

          context.dispatch(
            "stopLoading",
            {
              status: "error",
              message: error.response.data.error,
            },
            { root: true }
          );
        } else {
          context.dispatch(
            "stopLoading",
            {
              status: "error",
              message: "אירעה שגיאה בעת שליחת הודעת SMS לנייד שלך",
            },
            { root: true }
          );
        }
      }
    },
    async checkIfUserblockbyToken(context, data) {
      var isBlocked = localStorage.getItem("isBlocked");
      const differenceInMinutes = (Date.now() - isBlocked) / 1000 / 60;
      const isWithin5Minutes = differenceInMinutes <= 5;
      if (isWithin5Minutes) {
        await AuthService.logBadToken({ phoneNumber: data.phoneNumber });
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: "יותר מדי נסיונות שגויים, נסו שוב בעוד 5 דקות ",
          },
          { root: true }
        );
        return true;
      } else {
        var res = await AuthService.isUserBlockByToken(data);
        console.log(res);
        if (res.data.isUserBlocked) {
          localStorage.setItem("isBlocked", Date.now());
          context.dispatch(
            "stopLoading",
            {
              status: "error",
              message: "יותר מדי נסיונות שגויים, נסו שוב בעוד 5 דקות ",
            },
            { root: true }
          );
          return true;
        } else {
          return false;
        }
      }
    },
    async checkIfUserBlockedIp(context, data) {
      var isBlocked = localStorage.getItem("isBlocked");
      const differenceInMinutes = (Date.now() - isBlocked) / 1000 / 60;
      const isWithin10Minutes = differenceInMinutes <= 10;
      // console.log(isWithin10Minutes);

      try {
        if (isWithin10Minutes) {
          await AuthService.logBadLogin({ phoneNumber: data.phoneNumber });
          context.dispatch(
            "stopLoading",
            {
              status: "error",
              message: "יותר מדי נסיונות שגויים, נסו שוב בעוד 10 דקות ",
            },
            { root: true }
          );
          return true;
        } else {
          var res = await AuthService.isUserBlocked(data);
          console.log(res);
          if (res.data.isUserBlocked) {
            localStorage.setItem("isBlocked", Date.now());
            context.dispatch(
              "stopLoading",
              {
                status: "error",
                message: "יותר מדי נסיונות שגויים, נסו שוב בעוד 10 דקות ",
              },
              { root: true }
            );
            return true;
          } else {
            return false;
          }
        }
      } catch (error) {
        console.log(error);
        context.dispatch(
          "stopLoading",
          {
            status: "error",
            message: "ארעה שגיאה בהתחברות, נסו שוב בעוד כמה דקות",
          },
          { root: true }
        );
        return false;
      }
    },
    login(context, data) {
      context.commit("setToken", data.token);
      context.commit("setUser", data.user);
    },
    async tryLogin(context) {
      try {
        // Call to api with the cookies to check if the token is valid
        // Set a boolean value to the local storage to check if the user is logged in
        const token = localStorage.getItem("token");
        const userDetails = JSON.parse(localStorage.getItem("user"));

        if (token && userDetails) {
          await AuthService.logReLogin(userDetails);
          context.commit("setToken", token);
          context.commit("setUser", userDetails);

          // itzhak 01.11.23 add for now to show the login page need later real get data for login from backend

          const getBigDataSucceeded = await AuthService.getBigData();

          if (!getBigDataSucceeded) {
            console.log("if bigData from tryLogin");
            console.log(getBigDataSucceeded);
            await context.dispatch("logout");
          }

          caches.keys().then((keys) => {
            return Promise.all(
              keys.map((key) => {
                if (key.includes("images")) {
                  console.log("Removing old chace", key);
                  return caches.delete(key);
                }
              })
            );
          });
        }
      } catch (err) {
        console.log(err);
        console.log("error");
      }
    },
    async logout(context) {
      // console.log('logout')
      context.commit("setUser", null);
      context.commit("setToken", null);

      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
  getters: {
    getUserRole(state) {
      const userRole = state.user?.UserRoll;
      if (userRole) {
        return userRole;
      }
      return "";
    },
    getUserAgent(state) {
      return state.user.Customer.agent;
    },
    getAgent(state) {
      return state.agent;
    },
    getCustname(state) {
      return state.user.Customer.custname;
    },
    getCustomerName(state) {
      return state?.user?.Customer?.custdes;
    },
    getUserLang(state) {
      return state.user.language;
    },
    getCustomerData(state) {
      return state.user.Customer;
    },
    getToken(state) {
      return state.token;
    },
    getUserName(state) {
      return state?.user?.name;
    },
    getUser(state) {
      return state.user;
    },
    getTempPhoneNumber(state) {
      return state.tempPhoneNumber;
    },
    isAuthenticated(state) {
      return !!state.token; // convert to boolean value
    },
    authStatus(state) {
      return state.isAuthFailed;
    },
    getUserCust(state) {
      return state.user.cust;
    },
    getAgentCust(state) {
      return state.user.Customer.Agent;
    },
    // getUserName(state) {
    //     return state.userDetails.name
    // }
  },
};
