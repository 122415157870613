<template>
  <div class="row">
    <form @submit.prevent="verifyOtp" class="verify_sms_container">
      <h1 class="verify_sms_container__title">{{ $t("LoginSms.title") }}</h1>
      <p class="verify_sms_container__info Heebo">
        {{ $t("LoginSms.sendSmsInfoOne") }}: {{ userPhone }} <br />
        {{ $t("LoginSms.sendSmsInfoTwo") }}
      </p>
      <div class="otp-input">
        <input
          autocomplete="one-time-code"
          @focus="clearErrorMessage"
          tabindex="4"
          required
          type="text"
          maxlength="1"
          ref="otpDigit_4"
          inputmode="numeric"
        />
        <input
          autocomplete="one-time-code"
          @focus="clearErrorMessage"
          tabindex="3"
          required
          type="text"
          maxlength="1"
          ref="otpDigit_3"
          inputmode="numeric"
        />
        <input
          autocomplete="one-time-code"
          @focus="clearErrorMessage"
          tabindex="2"
          required
          type="text"
          maxlength="1"
          ref="otpDigit_2"
          inputmode="numeric"
        />
        <input
          autocomplete="one-time-code"
          @focus="clearErrorMessage"
          tabindex="1"
          required
          type="text"
          maxlength="1"
          ref="otpDigit_1"
          inputmode="numeric"
        />
      </div>
      <BaseError :errorMsg="errorMsg" :isThereErrorMsg="errorMsg"></BaseError>
      <hr class="otp-input-separator" />
      <BaseButton
        :isDisabled="isLoading"
        class="Gisha verify_sms_container__submitBtn"
        type="submit"
        >{{ $t("LoginSms.loginButton") }}</BaseButton
      >
      <div class="resend-sms-actions">
        <a
          @click="resendOtp"
          class="Heebo verify_sms_container__resend-link-msg"
          :class="{ disabled: isResendOtpLoading }"
          >{{ $t("LoginSms.resendLinkMessage") }}</a
        >
        <span
          v-if="isResendOtpLoading"
          class="Heebo verify_sms_container__resend-info-msg"
          >{{ $t("LoginSms.resendInfoMessageOne")
          }} <span>{{ resendTimeout }}</span
          > {{ $t("LoginSms.resendInfoMessageTwo") }}</span
        >
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "../helpers/BaseButton.vue";
import BaseError from "../helpers/BaseError.vue";
export default {
  components: { BaseButton, BaseError },
  data() {
    return {
      errorMsg: null,
      isResendOtpLoading: true,
      isLoading: false,
      resendTimeout: 30,
    };
  },
  methods: {
    startResendTimer() {
      const timer = setInterval(() => {
        this.resendTimeout--;
        if (this.resendTimeout === 0) {
          clearInterval(timer);
          this.resendTimeout = 30;
          this.isResendOtpLoading = false;
        }
      }, 1000);
    },
    async resendOtp() {
      this.isResendOtpLoading = true;
      await this.$store.dispatch("AuthModule/verifyPhone", this.userPhone);
      this.startResendTimer();
    },
    async retrieveOtpCredential() {
      if ("OTPCredential" in window) {
        window.addEventListener("DOMContentLoaded", async () => {
          const ac = new AbortController();
          try {
            const otpCredential = await navigator.credentials.get({
              otp: { transport: ["sms"] },
              signal: ac.signal,
            });

            if (otpCredential) {
              const otpCode = otpCredential.code;

              // Update the OTP code (e.g., update inputs)
              this.$refs["otpDigit_1"].value = otpCode[0];
              this.$refs["otpDigit_2"].value = otpCode[1];
              this.$refs["otpDigit_3"].value = otpCode[2];
              this.$refs["otpDigit_4"].value = otpCode[3];

              this.verifyOtp();
            }
          } catch (err) {
            console.error(err);
          } finally {
            ac.abort();
          }
        });
      }
    },
    clearErrorMessage() {
      this.errorMsg = null;
    },
    async verifyOtp() {
      document.activeElement.blur();
      const otpCode =
        this.$refs["otpDigit_1"].value +
        "" +
        this.$refs["otpDigit_2"].value +
        "" +
        this.$refs["otpDigit_3"].value +
        "" +
        this.$refs["otpDigit_4"].value;

      this.isLoading = true;

      try {
        var res = await this.$store.dispatch(
          "AuthModule/checkIfUserblockbyToken",
          {
            phoneNumber: this.userPhone,
          }
        );
        if (res) return;
        const response = await this.$store.dispatch("AuthModule/verifyOtp", {
          phoneNumber: this.userPhone,
          otpCode: otpCode,
        });
        if (response && response.status === 200) {
          this.$router.push("/");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.clearInputs();
        this.isLoading = false;
      }
    },
    clearInputs() {
      const stateRefs = this.$refs;

      Object.keys(stateRefs).forEach((key) => {
        stateRefs[key].value = "";
      });
    },
  },
  computed: {
    ...mapGetters({
      userPhone: "AuthModule/getTempPhoneNumber",
    }),
  },
  mounted() {
    if (!this.userPhone) {
      this.$router.push("/login");
    }

    this.startResendTimer();
    this.retrieveOtpCredential();

    const inputs = Array.from(Object.values(this.$refs));

    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];

      input.addEventListener("input", () => {
        // Handle normal input
        if (input.value.length == 1 && i - 1 >= 0) {
          inputs[i - 1].focus();
        }
        if (input.value.length > 1) {
          input.value = input.value.slice(0, 1);
        }
      });

      input.addEventListener("keydown", (e) => {
        if (e.key === "Backspace" || e.key === "Delete") {
          // User pressed backspace/delete
          if (input.value.length === 0 && i < inputs.length - 1) {
            // Input is empty, focus previous
            inputs[i + 1].focus();
          }
        }
      });

      input.addEventListener("input", () => {
        if (
          this.$refs["otpDigit_1"].value &&
          this.$refs["otpDigit_2"].value &&
          this.$refs["otpDigit_3"].value &&
          this.$refs["otpDigit_4"].value
        ) {
          this.verifyOtp();
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
h1 {
  color: white;
  size: 12px;
}

.otp-input {
  display: flex;
  justify-content: center;
  gap: 5px;
}

.otp-input input {
  width: 60px;
  height: 60px !important;
  margin-right: 10px;
  text-align: center;
  font-size: $largestTextSize !important;
  font-weight: bold;
  border: 1px solid #ccc;
  border-radius: 10px;
  color: white;
}

.otp-input input:valid,
.otp-input input:focus {
  background: #640b06;
}

.verify_sms_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-content: center;
  height: 100%;
  gap: 15px;
  padding: 40px;
}

.verify_sms_container .verify_sms_container__title {
  font-size: 35px;
  font-family: "felix007", serif;
  letter-spacing: 0.05px;
  margin: 0;
  padding: 0;
  color: $primaryTextColor;
}
.verify_sms_container .verify_sms_container__info {
  font-size: $mediumTextSize;
  letter-spacing: 0.05px;
  margin-bottom: 10px;
  margin-top: 0;
  padding: 0;
  font-weight: 100;
  color: white;
}

.verify_sms_container .otp-input-separator {
  width: 300px;
  border: none;
  border-bottom: 2px dashed rgba(255, 255, 255, 0.511);
}

.resend-sms-actions {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.verify_sms_container .verify_sms_container__resend-link-msg {
  color: white;
  font-size: $mediumTextSize;
  letter-spacing: 0.05px;
  margin-top: 20px;
  cursor: pointer;
  text-decoration: underline;
}

.verify_sms_container__resend-link-msg.disabled {
  color: #a0a0a0;
  user-select: none;
  pointer-events: none;
}

.verify_sms_container__resend-info-msg {
  color: white;
  font-size: $smallTextSize;

  span {
    color: $primaryTextColor;
    font-weight: bold;
  }
}

.verify_sms_container .verify_sms_container__submitBtn {
  width: 200px;
  align-self: center;
}
</style>
