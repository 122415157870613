import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "./components/pages/LoginPage";
import Home from "./components/pages/Home";
// import Cart from './components/pages/Cart'
import Products from "./components/pages/Products";
// import Search from './components/pages/Search'
import SingleOrder from "./components/pages/SingleOrder";
import CheckOut from "./components/pages/CheckOut.vue";
import Orders from "./components/pages/Orders";
// import LoginForm from './components/menus/LoginForm'
// import ArchiveSideBar from './components/menus/ArchiveSIdeBar'
import OrdersSideBar from "./components/menus/OrdersSideBar";
import store from "./store/index";
import SideCategoreis from "./components/helpers/SideCategoreis.vue";
import LoginSmsPage from "./components/pages/LoginSmsPage";
import AdminPanel from "./components/pages/AdminPanel";
import ThankYou from "./components/pages/ThankYou.vue";
import PaymentPage from "./components/pages/PaymentPage.vue";
import EditPromotions from "./components/pages/EditPromotions.vue";
import EditAlerts from "./components/pages/‏‏EditAlerts.vue";
import EditDistLines from "./components/pages/EditDistLines.vue";
import EditCategories from "./components/pages/EditCategories.vue";
import WaitingPage from "./components/pages/WaitingPage.vue";

export const routerNames = {
  orders: "orders",
  category: "category",
  cart: "cart",
  home: "home",
  loginSms: "loginSms",
  order: "order",
  adminPanel: "admin-panel",
  editAlerts: "editAlerts",
  editCategories: "editCategories",
  editDistLines: "editDistLines",
  paymentPage: "PaymentPage",
};
const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  routes: [
    {
      name: "adminPanel",
      path: "/admin-panel",
      components: {
        default: AdminPanel,
      },
      meta: {
        loginRequired: true,
        title: "אזור אישי אדמין",
      },
    },
    {
      name: "thankYou",
      path: "/thank-you",
      components: {
        default: ThankYou,
      },
      meta: {
        loginRequired: true,
      },
    },
    {
      name: "PaymentPage",
      path: "/payment-page",
      components: {
        default: PaymentPage,
      },
      meta: {
        loginRequired: true,
        title: "דף תשלום",
      },
    },
    {
      name: "editPromotions",
      path: "/edit-promotions",
      components: {
        default: EditPromotions,
      },
      meta: {
        loginRequired: true,
        title: "אזור עריכת מבצעים",
      },
    },
    {
      name: "editCategories",
      path: "/edit-categories",
      components: {
        default: EditCategories,
      },
      meta: {
        loginRequired: true,
        title: "אזור עריכת קטגוריות",
      },
    },
    {
      name: "editDistLines",
      path: "/edit-distlines",
      components: {
        default: EditDistLines,
      },
      meta: {
        loginRequired: true,
        title: "אזור עריכת קווי חלוקה",
      },
    },
    {
      name: "editAlerts",
      path: "/edit-alerts",
      components: {
        default: EditAlerts,
      },
      meta: {
        loginRequired: true,
        title: "אזור עריכת הודעות",
      },
    },
    {
      name: "home",
      path: "/",
      components: {
        default: Home,
        sideBar: SideCategoreis,
      },
      meta: {
        loginRequired: true,
        isShowHeaderDesktop: true,
      },
    },
    {
      name: "orders",
      path: "/orders/:status?",
      components: {
        default: Orders,
        sideBar: OrdersSideBar,
      },
      meta: {
        loginRequired: true,
        title: "הזמנות וחשבוניות",
      },
    },
    {
      name: "cart",
      path: "/cart",
      components: {
        default: CheckOut,
        sideBar: SideCategoreis,
      },
      meta: {
        loginRequired: true,
        title: "הזמנתכם",
      },
    },
    {
      name: "category",
      path: "/category/:category",
      components: {
        default: Products,
        sideBar: SideCategoreis,
      },
      meta: {
        loginRequired: true,
        title: "הזמנה חדשה",
        isShowHeaderDesktop: true,
      },
    },
    // {
    //     name: 'Search',
    //     path: '/Search/:value',
    //     components: {
    //         default: Search,
    //         sideBar: Categories,
    //     },
    //     meta: {
    //         loginRequired: true,
    //     }
    // },
    {
      name: "order",
      path: "/order/:ord",
      components: {
        default: SingleOrder,
        sideBar: SideCategoreis,
      },
      meta: {
        loginRequired: true,
        title: "הזמנתכם",
      },
    },
    {
      name: "passkey",
      path: "/passkey",
      components: {
        default: WaitingPage,
      },
      meta: {
      },
    },
    {
      name: "loginPage",
      path: "/login",
      components: {
        default: LoginPage,
        // sideBar: LoginForm,
      },
      meta: {
        isShowHeaderDesktop: true,
        redirectToLogin: true,
      },
    },
    {
      name: "loginSms",
      path: "/loginSms",
      components: {
        default: LoginSmsPage,
      },
      meta: {
        redirectToLogin: true,
        isShowHeaderDesktop: false,
      },
    },
  ],
});

async function tryToLogin() {
  // console.log('try to login from router.js start')
  document.getElementById("dolimo_loader").style.display = "flex";
  await store.dispatch("AuthModule/tryLogin");
  // console.log('try to login from router.js done!')
}

router.beforeEach(async function (to, from, next) {
  if (to.name !== from.name) {
    store.commit("setPreviousRoute", from.name);
  }

  const isAuthenticated = store.getters["AuthModule/isAuthenticated"];

  if (to.meta.loginRequired && !isAuthenticated) {
    await tryToLogin();
    if (store.getters["AuthModule/isAuthenticated"]) {
      next();
    } else {
      next({ name: "loginPage" });
    }
  } else if (to.meta.redirectToLogin && isAuthenticated) {
    next(from);
  } else {
    next();
  }
});

export default router;
