<template>
  <div class="main-contant">
    <ScrollToTopButton />
    <section class="ord-number-continer Heebo">
      <span>מס' הזמנה</span>
      <div class="ord-number">{{ this.order.ord }}</div>
    </section>
    <NotFound class="not-found" v-if="!canEditOrder">{{
      getBlockOrderMsg
    }}</NotFound>
    <section v-if="false" class="delivery flex gap-05">
      <img class="iconWithGrayBg" src="/images/delivery.png" />
      <div>
        <h1>מועד אספקה</h1>
        <div class="flex gap-05">
          <img class="pinter-icon" src="/images/pinter.png" />
          <span>משלוח עבור מאפיית האחים מחסן מרכזי</span>
        </div>
      </div>
    </section>
    <Select
      v-if="false"
      :selectedOptions="0"
      :options="theOrder.deliveryDate"
    />
    <section class="products flex">
      <div class="products-order-text">
        <img class="iconWithGrayBg" src="/images/cart-icon.png" />
        <h1 class="felix007">מוצרים בהזמנה</h1>
      </div>
      <section class="products">
        <ProductItem
          v-for="(line, index) in getOrderProducts"
          :key="line.ord"
          :index="index"
          :ord="line.ord"
          :product="line.Part"
          :quant="line.quant"
          :partPrice="getFixedPrice(line.partPrice)"
          :ordi="line.ordi"
        />
      </section>
    </section>
    <DeliveryDetails :deliveryDetails="getDeliveryDetails" />
    <section v-if="canEditOrder" class="submit">
      <BaseButton @click="openCartModal" class="submit-btn flex gap-10">
        <span>למעבר לעריכת ההזמנה</span>
        <img class="icon" src="/images/send-icon.png" />
      </BaseButton>
    </section>
    <CartModal
      icon="undraw_exclamation-point.png"
      titleOne="פעולה זו תגרום לביטול ההזמנה הקיימת ופרטיו יתווספו לעגלת הקניות שלך לביצוע הזמנה חדשה."
      titleTwo="האם ברצונך לאשר את הפעולה?"
      @exit-modal="closeCartModal"
      v-if="isCartModalOpen"
    />
    <Invocies :invoices="order.Deliveries" v-if="order.Deliveries.length > 0" />
  </div>
</template>

<script>
import Select from "../helpers/Select.vue";
import ProductItem from "../helpers/ProductItem.vue";
import DeliveryDetails from "../helpers/DeliveryDetails.vue";
import { formatDate } from "../../Dates";
import BaseButton from "../helpers/BaseButton.vue";
import { routerNames } from "@/router";
import NotFound from "../helpers/NotFound.vue";
import CartModal from "../helpers/CartModal.vue";
import Invocies from "../helpers/Invocies.vue";
import { mapGetters } from "vuex";
import ScrollToTopButton from '../helpers/ScrollToTopButton.vue';
export default {
  components: {
    Select,
    ProductItem,
    DeliveryDetails,
    BaseButton,
    NotFound,
    CartModal,
    Invocies,
    ScrollToTopButton,
  },
  data() {
    return {
      order: null,
      isCartModalOpen: false,
    };
  },
  methods: {
    async resendOrder() {
      const cartOrd = this.$store.getters["Orders/getCart"].ord;
      // clone the order to the cart, navigate to the checkout page and cancel the old order.
      const response = await this.$store.dispatch("Orders/cloneOrderToCart", {
        cartOrd: cartOrd,
        order: this.order,
        mode: "resend",
      });
      if (response.status === 200) {
        this.$router.push({ name: routerNames.cart });
      }
    },
    closeCartModal(userRequest) {
      if (userRequest.isConfirmed) {
        this.resendOrder();
      }
      this.isCartModalOpen = false;
    },
    async openCartModal() {
      this.isCartModalOpen = true;
    },
    findOrderObject(ord) {
      const orders = this.$store.getters["Orders/getOrders"];
      this.order = orders.find((order) => {
        return order.ord === parseInt(ord);
      });
      console.log("order");
      console.log(this.order);
    },
  },
  watch: {
    "$route.params.ord": {
      handler: "findOrderObject",
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      getFixedPrice: "Orders/getFixedPrice",
    }),
    getBlockOrderMsg() {
      if (this.order.status === 5)
        return "ניתן לערוך את ההזמנה בעוד 15 דקות ועד לאישור במערכת";
      return "לא ניתן לערוך הזמנה זו";
    },
    canEditOrder() {
      const isLastOrderHourPassed = this.$store.getters[
        "Setting/isLastOrderHourPassed"
      ](this.order.senddate);

      return !isLastOrderHourPassed && this.order.Status.editable;
    },
    getDeliveryDetails() {
      const productsQuant = this.order.OrderLines.reduce(
        (totalQuant, orderLine) => totalQuant + orderLine.quant,
        0
      );
      return {
        date: formatDate(this.order.senddate, {
          delimiter: "/",
        }),
        providedTo: this.order.Customer.custdes,
        products: this.order.OrderLines.length,
        cartons: productsQuant,
        totalPayment: this.$store.getters["Orders/getOrderTotalPrice"](
          this.order.ord
        ),
        debtBalance: this.order.debtBalance,
        tax: this.order.tax,
      };
    },
    getOrderProducts() {
      const orderProducts = this.order.OrderLines;
      return orderProducts;
    },
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  margin-bottom: 40px;
}

@media (min-width: 1200px) {
  .not-found {
    max-width: fit-content;
  }
}
.products {
  width: 100%;

  .products-order-text {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

@media (min-width: 600px) {
  .products {
    width: 360px;
  }
}
.submit-btn {
  margin: 15px auto;

  .icon {
    width: 15px;
    height: 15px;
  }
}
.main-contant {
  flex-direction: column;
  align-items: flex-start;
  padding: $mainContentPadding;
}

@media (min-width: 1200px) {
  .main-contant {
    padding-bottom: 50px;
  }
}
h1 {
  font-size: 20px;
  margin: 0px;
  color: #c4c4c4;
  font-weight: 400;
}
.ord-number-continer {
  margin-top: 20px;
  span {
    color: #c4c4c4;
    font-size: $extraSmallTextSize;
    font-weight: 700;
  }
  .ord-number {
    font-size: 20px;
    color: $primaryTextColor;
    font-weight: 600;
  }
}
.delivery {
  justify-content: start;
  margin-top: 30px;
  margin-bottom: 20px;
  .pinter-icon {
    width: 9px;
    height: 13px;
  }
  span {
    color: $primaryTextColor;
  }
}
.products {
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .products {
    display: grid;
  }
}

button {
  width: 100%;
}
</style>
