<template>
  <div class="white-text">
    <h1 class="felix007 heading-settings">
      <span class="white-text">מסך</span> ניהול הגדרות
    </h1>
    <section class="button-continer flex gap-10">
      <BaseButton @click="navigateToEditPromotions">
        <div>
          <div class="flex gap-05">
            <span> לעריכת מבצעים </span>
          </div>
        </div>
      </BaseButton>
      <BaseButton @click="navigateToEditAlerts" :bgColor="'#393945'">
        <div>
          <div class="flex gap-05">
            <span> לעריכת הודעות</span>
          </div>
        </div>
      </BaseButton>
    </section>
    <section class="button-continer flex gap-10">
      <BaseButton @click="navigateToEditDistLines">
        <div>
          <div class="flex gap-05">
            <span> לעריכת קווי חלוקה </span>
          </div>
        </div>
      </BaseButton>
      <BaseButton @click="navigateToEditCategories" :bgColor="'#393945'">
        <div>
          <div class="flex gap-05">
            <span> לעריכת קטגוריות</span>
          </div>
        </div>
      </BaseButton>
    </section>
    <section id="settings-values">
      <form class="settings-form" @submit.prevent="submitChanges">
        <div
          class="setting-value-container"
          v-for="(setting, index) in settingsValues"
          :key="index"
        >
          <label :for="setting.metaKey">{{ setting.metaDesc }}: </label>
          <input
            :name="setting.metaKey"
            :id="setting.metaKey"
            :value="setting.metaValue"
            :disabled="!setting.editable"
            :class="{ readonly: !setting.editable }"
          />
        </div>
        <BaseButton type="submit">החל שינויים</BaseButton>
      </form>
    </section>
  </div>
</template>

<script>
import BaseButton from "../helpers/BaseButton.vue";
// import { routerNames } from "@/router";
export default {
  components: { BaseButton },
  data() {
    return {
      settingsValues: [],
    };
  },
  mounted() {
    this.settingsValues = this.$store.getters["Setting/getSettingsArray"];
  },
  methods: {
    navigateToEditCategories() {
      this.$router.push("/edit-categories");
    },
    navigateToEditDistLines() {
      this.$router.push("/edit-distlines");
    },
    navigateToEditPromotions() {
      this.$router.push("/edit-promotions");
    },
    navigateToEditAlerts() {
      this.$router.push("/edit-alerts");
    },
    async submitChanges(event) {
      const updatedSettings = this.settingsValues.map((setting) => {
        const { name, value } = event.target.elements[setting.metaKey];
        if (setting.metaKey === name) {
          return {
            ...setting,
            metaValue: value,
          };
        }
      });
      await this.$store.dispatch("Setting/updateSettings", updatedSettings);
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  color: $primaryTextColor;
  text-align: center;
  font-size: 3.5rem;
  margin-top: 10px;
}

.button-continer {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-around;
  .plus-icon {
    width: 10px;
    height: 10px;
  }
}

.settings-form {
  max-width: 52%;
  margin: auto;
  text-align: center;
  font-family: "Heebo";

  button {
    margin-top: 10px;
  }
}

.setting-value-container {
  width: 100%;

  label {
    font-size: 1.2rem;
  }

  input {
    color: white;
    text-align: center;
  }

  input::placeholder {
    color: rgb(152, 152, 152);
    font-family: inherit;
    text-align: center;
  }

  .readonly {
    user-select: none;
    pointer-events: none;
    color: grey;
  }
}

#settings-values {
  padding-bottom: 5%;
}
</style>
