export default {
    direction: 'rtl',

    LoginPage: {
        titleOne: 'ברוכים הבאים למזווה של',
        titleTwo: 'מאפה הבלקן',
        phoneNumberPlaceholder: 'הכנס מספר נייד',
        loginButton: 'שלח אליי קוד התחברות',
        forgetPassButton: 'שכחת סיסמה?'
    },
    LoginSms: {
        title: 'קוד כניסה למערכת',
        sendSmsInfoOne: 'שלחנו לך קוד אימות למספר',
        sendSmsInfoTwo: 'נא להכניס את הקוד כאן למטה',
        loginButton: 'התחברות',
        resendLinkMessage: 'לא קיבלתי קוד',
        resendInfoMessageOne: 'אנא המתן',
        resendInfoMessageTwo: 'שניות לשליחה חוזרת',
    },
    Header: {
        morningGreeting: "בוקר טוב",
        afterNoonGreeting: "צהריים טובים",
        eveningGreeting: "ערב טוב",
        nightGreeting: "לילה טוב",
        neworder: 'הזמנה חדשה',
        orderSummary: 'סיכום הזמנה',
        catitems: 'מוצרים בעגלה',
        myorders: 'ההזמנות שלי',
        openOrdersOne: 'יש',
        openOrdersTwo: 'הזמנות פתוחות',
        hello: 'שלום',
        logout: 'התנתקות',
        logourl: "/images/logohe.png",
        activeorders: 'הזמנות פתוחות',
        adminSetting: 'הגדרות מנהל',
    },

    Footer: {
        companyName: 'מאפה הבלקן בע"מ'
    },

    ProductWidget: {
        nirotekSKU: 'מק"ט נירעותק',
        custSKU: 'מק"ט לקוח',
        category: 'קטגוריה',
        length: 'אורך',
        width: 'רוחב',
        totalWidth: 'רוחב מצטבר',
        quantity: 'כמות',
        numOfCycles: "מס' מחזורים",
        detailOfWidthDimensions: 'פירוט מידות רוחב',
        unit: 'יחידה',
    },
    Products: {
        clearAll: 'נקה הכל',
        overFiftyProducts: 'יוצגו רק 50 תוצאות ראשונות, ניתן להשתמש במסננים לתוצאות נוספות',
        moreProductsAvailable: 'ניתן למצוא מוצרים נוספים בעת שימוש בסינונים בראש העמוד.',
        noProductsWereFound: 'לא נמצאו מוצרים להצגה',
        filtersHeaders: {
            param1: "סוג נייר",
            param2: "משקל נייר",
            param3: "עובי נייר פנים",
            param4: "צבע",
            param5: "",
            param6: "",
            param7: "דבק",
            param8: "סוג ציפוי",
            param9: "",
            param10: "סוג גב",
            param11: "משקל גב",
            param12: "צבע גב",
            param13: "כח שחרור",
            param14: "",
            param15: "",
            param16: "",
            param17: "",
            param18: "",
            param19: "",
            param20: "",
            type: "סוג מוצר",
            paramInbox: "האם ארוז"
        },


    },
    ProductModal: {
        alreadyInCart: 'מוצר זה קיים בעגלה',
        notCustPart: 'לידיעתך, מוצר זה אינו קיים בקבוצת המוצרים שלך.',
        orderLine: 'שורת הזמנה',
        note: 'הערה למוצר',
        save: 'הוסף לסל',
        cancel: 'ביטול',
        inbox: 'האם לארוז?',

        quantity: 'כמות גליונות:',
        gilyonLength: 'אורך הגליון..',
        gilyonWidth: 'רוחב הגליון..',

        galilLength: 'אורך הגליל במטרים..',
        galilCutWidth: 'רוחב לחיתוך בס"מ..',
        total: 'סה"כ רוחב מצטבר',
        cm: 'ס"מ',
        validWidths: 'רוחבי גלילים אפשריים:',
        numOfCycles: "מס' מחזורים:",
        invalidTotalWidth: 'סכום רוחבי הפרוסות לא תואם את הרוחבים האפשריים ולכן צפוי פחת של ',
        unbox: 'לא ארוז',
        boxed: 'ארוז',
        sheets: 'גליונות',
        galilee: 'גלילים',
        notvalidWidth: 'סה"כ רוחב לא אפשרי - רוחב מקסימלי',
        numberCuts: 'רוחב פרוסה בס"מ',
        numberCycles: 'מספר פרוסות',
        phatAlert: 'יש לתאם עם התפי',
        inboxAlert: 'הכמות הסופית של הגיליונות תאושר על ידי התפי'
        // width
    },

    SingleOrder: {
        NewOrder: 'הזמנה חדשה',
        OrderNumber: 'הזמנה מספר:',
        PurchaseOrderNumber: "מס' הזמנת רכש",
        contact: 'איש קשר',
        address: 'כתובת',
        orderDetails: ' פריטים בהזמנה',
        Remarks: 'הערות',
        SendOrder: 'שליחת הזמנה ',
        ProductionDate: 'תאריך יצירה',
        orderNumber: "מס' הזמנה",
        sent: 'נשלחה',
        Update: 'עדכון',
        inpPreparation: 'בהכנה',
        registered: 'נקלטה',
        maxLetters: 'מקסימום 120 תווים',
        ordersEmpty: 'הזמנתך ריקה.',
        viewDeliveryDoc: 'צפייה בתעודה',
        savedBeforeSendOrder : 'ההזמנה לא נשמרה לפי השליחה',
        contactTooltip: 'ניתן לשנות איש קשר רק לאחר ביצוע ההזמנה'
    },

    Categories: {
        title: 'קטגוריות מוצרים',
        favorites: 'מועדפים',
        allProducts: 'כל המוצרים',
        myProducts: 'המוצרים שלי',
        viewOrder: 'צפיה בהזמנה »',
        langFieldName: 'familyname',
    },

    BaseSearch: { search: 'חיפוש...' },

    sideBar: {
        orderStatus: 'סטטוס הזמנה',
        showAll: 'הכל',
    },
    orders: {
        myOrders: 'ההזמנות שלי',
        addressForDelivery: 'כתובת למשלוח',
        searchBySKU: 'חיפוש לפי מק"ט',
        address: 'כתובות',
        status: 'סטטוס',
        productionDate: 'תאריך יצירה',
        orderNumber: 'מספר הזמנה',
        deliveryCertificate: 'תעודת משלוח',
        DeliveryCertificatenNotProvided: 'תעודת משלוח טרם סופקה',
        reference: 'הזמנתכם'
    },
    home: {
        welcome: 'ברוכים הבאים לאתר ההזמנות של נירעותק',
        start: 'אפשר להתחיל',
        newOrder: 'הזמנה חדשה',
        viewOrders: 'הזמנות וחשבוניות', 
    },
    orderSuccModal: {
        title: 'הזמנך נקלטה בהצלחה',
        orderNumber: 'מספר הזמנה',
        alertText: 'ייתכן שמחלקת תפ"י תבצע התאמות נוספות, נעדכן אותך במידה ויהיה שינוי.',
        watchOrder: 'לצפייה בהזמנה',
        processingYourRequest: 'מטפלים בפניתך',
        continueOrdering: 'המשך להזמין',


    },

}