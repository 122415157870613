<template>
  <teleport to="#modal_backdrop">
    <div @click="this.$emit('exitModal')" class="backdrop-popup"></div>
    <div class="modal-popup gap-10">
      <div class="type" v-if="!isEdit">
        <label>סוג</label>
        <select v-model="currAlert.type">
          <option disabled value="">תבחר את סוג ההודעה</option>
          <option value="LOG">הודעה</option>
          <option value="POPUP">פופאף</option>
        </select>
      </div>

      <div>
        <label>תוכן</label>
        <textarea
          :maxlength="currAlert.type === 'LOG' ? 50 : 100"
          v-model="currAlert.content"
          type="text"
          :placeholder="'תוכן'"
        />
      </div>
      <div v-if="currAlert.type === 'LOG'">
        <label>אייקון</label>
        <Select
          :options="iconsOptions"
          :selectedOptions="selectedIcon"
          @select="selectIcon"
        ></Select>
      </div>
      <div v-else-if="currAlert.type === 'POPUP'">
        <VueMultiselect
          v-model="currAlert.userSelected"
          :options="customers"
          :multiple="true"
          :close-on-select="true"
          placeholder="תבחר משתמשים שיקבלו פופאף זה"
          label="custname"
          track-by="cust"
        />
      </div>

      <BaseButton @click="this.saveAlert" class="submit">{{
        saveOrEdit
      }}</BaseButton>
    </div>
  </teleport>
</template>
<script>
import BaseButton from "../helpers/BaseButton.vue";
import Select from "../helpers/Select.vue";
import VueMultiselect from "vue-multiselect";
export default {
  components: {
    BaseButton,
    Select,
    VueMultiselect,
  },
  emits: ["exitModal", "saveAlert"],
  props: {
    saveOrEdit: {
      type: String,
      required: true,
    },
    alert: {
      type: Object,
    },
    customers: {
      type: Array,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currAlert: {
        type: "",
        content: "בגלל המצב המשלוחים יחולקו עד השעה 17:00",
        editable: true,
        icon: "info",
        userSelected: null,
      },
      iconsOptions: [
        { title: "מידע", icon: "info" },
        { title: "אזהרה", icon: "alert" },
        { title: "קידומי", icon: "party" },
      ],
      selectedIcon: 0,
    };
  },
  mounted() {
    if (this.alert) {
      this.currAlert = { ...this.alert };
      this.selectedIcon = this.iconsOptions.findIndex(
        (icon) => icon.icon === this.currAlert.icon
      );
    }
  },
  methods: {
    saveAlert() {
      if(!this.currAlert.type) return
      this.$emit("saveAlert", this.currAlert);
    },
    selectIcon(key) {
      this.selectedIcon = key;
      this.currAlert.icon = this.iconsOptions[key].icon;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped lang="scss">
.backdrop-popup {
  background-color: #000000;
  opacity: 0.6;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 11;
}

.modal-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 12;
  border-radius: 40px;
  background: #393945;
  padding: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 330px;
}
.type {
  width: 85%;
}
label {
  font-size: $mediumTextSize;
}
textarea {
  height: 100px;
  color: white;
  font-size: $mediumTextSize;
}

select {
  background-color: rgb(57 57 69);
  color: white;
  border-radius: 10px;
}

</style>