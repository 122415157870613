<template>
  <div class="orders">
    <BaseMessage v-if="!isDesktop" class="orders-msg Heebo" messageIcon="order">
      <span
        >יש לך <b>{{ openOrders }}</b> הזמנות חדשות</span
      >
    </BaseMessage>
    <DatesSearch v-if="!isDesktop" />
    <section
      style="margin-top: 10px"
      v-if="ordersStatus.length > 0 && !isDesktop"
    >
      <ul class="status-filter">
        <li
          v-for="(status, index) in ordersStatus"
          :key="status"
          @click="changeStatusOrder(status, index)"
          :class="[status === $route.params.status ? 'active' : '']"
          ref="statusRef"
        >
          {{ status }}
        </li>
      </ul>
    </section>
    <span class="order-sum-label">
      סה״כ סכום הזמנות :
      <span class="sum-value-text">₪{{ formatPrice(ordersSum) }}</span></span
    >
    <section
      v-if="filteredOrders.length > 0"
      :style="{
        color: 'white',
        height: 'calc(100vh - 315px)',
      }"
      class="orders-container"
      :class="{ scroll: scrollOrders }"
    >
      <OrderItem
        v-for="order in filteredOrders"
        :key="order.ord"
        :order="order"
      />
    </section>
    <NotFound v-else imageIcon="noteIcon.png"
      >לא נמצאו הזמנות עם ססטוס "{{ $route.params.status }}" אצל הלקוח</NotFound
    >
  </div>
</template>

<script>
import BaseMessage from "../helpers/BaseMessage.vue";
import OrderItem from "../helpers/OrderItem.vue";
import NotFound from "../helpers/NotFound.vue";
import { mapGetters } from "vuex";
import DatesSearch from "../helpers/DatesSearch.vue";

export default {
  components: {
    BaseMessage,
    OrderItem,
    NotFound,
    DatesSearch,
  },
  data() {
    return {
      statusRef: [],
    };
  },
  methods: {
    formatPrice(price) {
      if (Number.isInteger(price)) {
        return price.toString();
      } else {
        return price.toFixed(2);
      }
    },
    changeStatusOrder(status, index) {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params,
          status: status,
        },
        query: {
          ...this.$route.query,
        },
      });
      if (!this.isDesktop) {
        setTimeout(() => {
          const element = this.$refs.statusRef[index];
          if (element) {
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "center",
            });
          } else {
            console.error("Element not found");
          }
        }, 0);
      }
    },
    filterOrder(order) {
      let isComesToTheParty = true;
      const orderStatus = this.$route.params.status;
      if (orderStatus && orderStatus !== "הכל") {
        if (order.Status.statusname !== orderStatus) {
          isComesToTheParty = false;
        }
      }

      const dateOne = new Date(this.$route.query.date);
      const dateTwo = new Date(this.$route.query.dateTwo);
      const orderDate = new Date(order.duedate);

      orderDate.setHours(0, 0, 0, 0);

      if (
        !isNaN(dateOne.getTime()) &&
        isNaN(dateTwo.getTime()) &&
        !this.isRangeDatesOptionClicked
      ) {
        dateOne.setHours(0, 0, 0, 0);
        if (orderDate.getTime() !== dateOne.getTime())
          isComesToTheParty = false;
      } else {
        dateOne.setHours(0, 0, 0, 0);
        dateTwo.setHours(0, 0, 0, 0);
        if (orderDate.getTime() < dateOne.getTime()) isComesToTheParty = false;
        if (orderDate.getTime() > dateTwo.getTime()) isComesToTheParty = false;
      }
      return isComesToTheParty;
    },
  },
  computed: {
    filteredOrders() {
      return this.Orders.filter((order) => this.filterOrder(order));
    },
    getTotalPrice() {
      const totalPrice =
        this.$store.getters["Orders/getOrderTotalPrice"](this.order.ord) +
        (this.order.debtBalance ?? 0);
      if (totalPrice <= 0) return 0;
      return totalPrice;
    },
    ordersSum() {
      return this.filteredOrders.reduce((acc, order) => {
        let totalPrice =
          this.$store.getters["Orders/getOrderTotalPrice"](order.ord) +
          (order.debtBalance ?? 0);
        if (totalPrice <= 0) totalPrice = 0;
        return acc + totalPrice;
      }, 0);
    },
    ...mapGetters({
      isDesktop: "isDesktop",
      Orders: "Orders/getOrders",
      openOrders: "Orders/getOpenOrders",
      isRangeDatesOptionClicked: "Orders/getIsRangeDatesOptionClicked",
      isSafari: "isSafari",
    }),
    ordersStatus() {
      const orderStatus = new Set(
        this.Orders.filter((order) => order.Status.showInPortal).map(
          (order) => order.Status.statusname
        )
      );

      return ["הכל", ...orderStatus];
    },
    scrollOrders() {
      // if (this.isDesktop && this.filteredOrders.length >= 10) {
      //   return true;
      // }
      if (!this.isDesktop && this.filteredOrders.length > 1) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.order-sum-label {
  color: rgb(0, 0, 0);
  font-size: $mediumTextSize;
  background-color: $primaryTextColor;
  border-radius: 20px;
  padding: 5px;
  text-align: center;
  .sum-value-text {
    font-weight: 800;
  }
}
.orders-container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding-bottom: 100px;
  margin-top: 15px;
}

@media (min-width: 1024px) {
  .orders-container {
    height: fit-content !important;
  }
  .order-sum-label {
    max-width: 33%;
  }
}

.scroll {
  overflow-y: scroll;
}

@media (min-width: 1024px) {
  .orders-container {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px !important;
  }
}

.orders-actions {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.orders {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $mainContentPadding;
}

// .status-scroll {
//   margin: 0px -18px;
//   overflow-x: scroll;
//   -ms-overflow-style: none; /* for Internet Explorer, Edge */
//   scrollbar-width: none; /* for Firefox */
// }

// .status-scroll::-webkit-scrollbar-thumb,
// .status-scroll::-webkit-scrollbar-button,
// .status-scroll::-webkit-scrollbar-track,
// .status-scroll::-webkit-scrollbar {
//   display: none;
// }

.status-filter {
  display: flex;
  gap: 7px;
  color: #c4c4c4;
  list-style: none;
  font-size: $mediumTextSize;
  padding: 0;
  margin-top: 0px;

  li {
    width: fit-content;
    background: $secondaryColor;
    border-radius: 50px;
    padding: 2px 8px;
    cursor: pointer;
  }

  li.active {
    border: 1px $primaryTextColor solid;
    color: $primaryTextColor;
  }
}
.orders-msg {
  width: fit-content;
  padding: 2px 10px;
  margin-bottom: 20px;

  span {
    font-size: $mediumTextSize;
  }
}

.filters-area {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.123);
  border-radius: 15px;
  padding: 15px;
  display: flex;
  align-items: center;
  grid-column-gap: 20px;
  margin-bottom: 30px;

  input {
    border: 1px solid lightgray;

    border-radius: 10px;
    padding: 3px;
    margin: 0 !important;
    font-size: $smallTextSize !important;
  }
}
</style>
