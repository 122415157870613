import { createApp } from 'vue'
import 'materialize-css/dist/css/materialize.min.css'


import App from './App.vue'
import router from './router.js'
import store from './store/index'
// import { createI18n } from 'vue-i18n'
import { createI18n } from 'vue-i18n/index'
import hebrew from './translations/hebrew'
import english from './translations/english'
import './registerServiceWorker'

if (process.env.NODE_ENV === 'production') console.log = function () {};


const i18n = createI18n({
    locale: 'he', // set locale
    fallbackLocale: 'he', // set fallback locale
    messages: {
        he: hebrew,
        en: english
    },
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(i18n)


router.isReady().then(function () {
    app.mount('#app')
})