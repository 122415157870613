<template>
  <div class="product-item-overlay">
    <span v-if="partWeight" class="product-weight">
      <span class="partrweight">{{ partWeight.value }}</span>
      {{ partWeight.unit }} / קרטון</span
    >
    <BaseCard class="product-continer">
      <img
        crossorigin="anonymous"
        @error="handleImageError"
        class="product-image"
        :src="getProductImage"
        alt="product image"
      />
      <div class="text-continer gap-05">
        <!-- <span class="description">
          {{ getProductDescription }}
        </span> -->
        <div class="product-name">
          <h1>
            {{ product.partname }}
          </h1>
          <div class="side-actions">
            <span
              v-if="!isCheckoutPage"
              @click="toggleFavoriteProduct"
              class="favorite-icon"
              >{{ favoriteIcon }}</span
            >
            <img
              v-if="isCheckoutPage"
              class="trash-icon"
              alt="trash-icon"
              src="/images/trash-icon.png"
              @click="removeProductFromCart"
            />
          </div>
        </div>
        <hr />
        <div class="product-actions">
          <div
            v-if="!showInputAmount"
            :class="{ 'add-amount-btn-wrapper': isDiscountCodeExist }"
          >
            <button
              class="update-amount-actions"
              :class="{ 'disable-btn': isAddAmountButtonDisabled }"
              @click="clickToAddAmount"
            >
              <img
                src="/images/plus-icon.png"
                class="plus-icon"
                alt="plus-icon"
              />
              הוספה
            </button>
          </div>
          <div
            v-else
            :class="{ 'add-amount-container-wrapper': isDiscountCodeExist }"
          >
            <div class="add-amount-container">
              <div class="input-amount" :class="getDisableActionsAmountStyle">
                <span
                  @click="updateAmountQuantity('inc')"
                  class="plus felix007"
                  :class="{ disable: isChangeQuantityDisable }"
                  aria-label="Increase by one"
                  >+</span
                >
                <input
                  name="amount"
                  :style="amountInputStyle"
                  class="product-amount felix007"
                  @blur="updateAmountQuantity"
                  v-model="productAmount"
                  type="number"
                  inputmode="numeric"
                  @click="clearInputAmount"
                  maxlength="4"
                  @input="validateInput"
                  dir="rtl"
                />
                <span
                  @click="updateAmountQuantity('dec')"
                  class="minus"
                  :class="{ disable: isChangeQuantityDisable }"
                  aria-label="Decrease by one"
                >
                  -
                </span>
              </div>
              <span>קרטונים</span>
            </div>
          </div>
          <div class="prices-section">
            <div v-if="isDiscountCodeExist" class="price-info">
              <span class="Gisha price-title-small">מחירון</span>
              <div class="prices-values">
                <span class="felix007 price-value-small">{{
                  product.price
                }}</span>
                <span class="felix007 price-unit">₪</span>
              </div>
            </div>
            <div v-if="isDiscountCodeExist" class="price-info">
              <span class="Gisha price-title-small">הנחה/%</span>
              <div class="prices-values">
                <span class="felix007 price-value-small discount-value">{{
                  product.CustPriceCode.precent
                }}</span>
              </div>
            </div>
            <div class="price-info">
              <span v-if="isDiscountCodeExist" class="Gisha price-title"
                >נטו</span
              >
              <div class="prices-values">
                <span class="felix007 price-value" style="font-weight: bold">{{
                  productPrice
                }}</span>
                <span class="felix007 price-unit">₪</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import BaseCard from "./BaseCard.vue";
// import { baseUrl } from "../../config.js";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { BaseCard },
  name: "ProductItem",
  props: {
    product: {
      type: Object,
      requierd: true,
    },
    index: {
      type: Number,
    },
    ord: {
      type: Number,
    },
    line: {
      type: Number,
    },
    editable: {
      type: Boolean,
    },
    quant: {
      type: Number,
    },
    ordi: {
      type: Number,
    },
    partPrice: {
      type: Number,
    },
  },
  data() {
    return {
      isAddAmountButtonClicked: false,
      isAddAmountButtonDisabled: false,
      lastProductAmount: null,
      productAmount: null,
      isFavoriteProduct: this.product.isFavoriteProduct,
      isChangeQuantityDisable: false,
      isAddingAmountDisable: false,
      isRemovingAmountDisable: false,
      starStatus: false,
    };
  },
  computed: {
    ...mapGetters({
      getFixedPrice: "Orders/getFixedPrice",
    }),
    isDiscountCodeExist() {
      return this.product.CustPriceCode?.precent;
    },
    partWeight() {
      if (this.product.patrweight)
        return { value: this.product.patrweight, unit: 'ק"ג' };
      if (this.product.qunatityinbox)
        return { value: this.product.qunatityinbox, unit: "יח" };
      return null;
    },
    getDisableActionsAmountStyle() {
      if (this.isChangeQuantityDisable) return "disable disable-btn";
      if (this.isAddingAmountDisable) return "disable-right";
      if (this.isRemovingAmountDisable) return "disable-left";
      return "";
    },
    amountInputStyle() {
      return String(this.productAmount).length === 4 ? "width: 56px" : "";
    },
    getProductImage() {
      if (this.product.image && this.product.image.includes(".")) {
        const src = this.product.image.split(".");
        const fileName = parseInt(src[0]);
        if (!isNaN(fileName)) {
          return `${'https://orders.balkan1936.co.il:3031/'}images/` + fileName + "." + src[1];
        }
      }
      return "";
    },
    getProductDescription() {
      const productDescription = this.product.shorttext ?? "";
      if (productDescription.length > 33) {
        return productDescription.substring(0, 33) + "...";
      } else {
        return productDescription;
      }
    },
    isCheckoutPage() {
      return this.$route.name === "cart";
    },
    isTheProductHasCustomer() {
      return this.product.CustParts.length > 0;
    },
    favoriteIcon() {
      if (this.starStatus) return "★";
      return "☆";
    },
    productPrice() {
      if (this.partPrice) return this.partPrice;
      let newProductPrice = this.product.price;
      if (
        this.isTheProductHasCustomer &&
        this.product.CustParts[0].specialprice
      ) {
        newProductPrice = this.product.CustParts[0].specialprice;
      } else if (this.product.CustPriceCode) {
        const discountAmount =
          (this.product.price * this.product.CustPriceCode.precent) / 100;
        newProductPrice = this.product.price - discountAmount;
      }

      return this.getFixedPrice(newProductPrice);
    },
    showInputAmount() {
      return this.isAddAmountButtonClicked || this.productAmount >= 1;
    },
  },
  mounted() {
    this.starStatus =
      this.isTheProductHasCustomer && this.product.CustParts[0].Star;
    if (this.quant) {
      this.productAmount = this.quant;
      if (this.quant >= 1) this.isAddAmountButtonClicked = true;
    } else this.productAmount = 0;
    if (this.$route.params.ord) {
      this.isChangeQuantityDisable = true;
    }
  },
  watch: {
    productAmount(value) {
      if (value > 0 || this.productAmount === "")
        this.isRemovingAmountDisable = false;
      else this.isRemovingAmountDisable = true;
      if (value < 9999) this.isAddingAmountDisable = false;
      else this.isAddingAmountDisable = true;
    },
  },
  methods: {
    ...mapActions({
      toggleFavorite: "Parts/toggleFavorite",
    }),
    async toggleFavoriteProduct() {
      if (this.$route.name !== "category") return;
      let toogleFavoritePayLoad = null;
      // if the product doesn't have CustParts array, add it
      if (this.product.CustParts.length === 0) {
        toogleFavoritePayLoad = {
          mode: true,
          part: this.product.part,
          action: "insert",
        };
        this.starStatus = true;
      } else {
        toogleFavoritePayLoad = {
          part: this.product.part,
          mode: !this.product.CustParts[0].Star,
          action: "update",
        };
        this.starStatus = !this.product.CustParts[0].Star;
      }
      const response = await this.toggleFavorite(toogleFavoritePayLoad);
      if (!response) {
        // if the request has failed, go back to the previous star status state
        this.starStatus = !this.starStatus;
      }
    },
    clearInputAmount() {
      this.lastProductAmount = this.productAmount;
      this.productAmount = "";
    },
    handleImageError(evt) {
      evt.target.src = "/images/product-image.png";
    },
    validateInput(e) {
      // Allow only numbers
      const numbers = e.target.value.replace(/[^0-9]/g, "");

      // Limit to 4 digits
      const fourDigits = numbers.slice(0, 4);

      const parseNumber = parseInt(fourDigits);

      if (isNaN(parseNumber)) {
        this.productAmount = 0;
      } else {
        this.productAmount = parseNumber;
      }
    },
    async clickToAddAmount() {
      this.isAddAmountButtonDisabled = true;
      if (this.productAmount === 0) {
        await this.updateAmountQuantity("inc");
      }
      if (this.productAmount > 0) {
        this.isAddAmountButtonClicked = true;
        this.isAddAmountButtonDisabled = false;
      }
    },
    async removeProductFromCart() {
      await this.$store.dispatch("Orders/removeOrderLine", {
        ord: this.ord,
        ordi: this.ordi,
      });
    },
    toggleAllUpdateAmountActions(onOrOff) {
      this.isChangeQuantityDisable = onOrOff;
      this.isAddingAmountDisable = onOrOff;
      this.isRemovingAmountDisable = onOrOff;
    },
    async updateAmountQuantity(operation) {
      if (this.productAmount === "") {
        this.productAmount = this.lastProductAmount;
        return;
      }
      // if (!this.editable && this.$route.params.ord) return;
      if (this.isRemovingAmountDisable && operation === "dec") return;
      if (this.isAddingAmountDisable && operation === "inc") return;
      if (this.$route.params.ord) return;
      let newProductAmount = this.productAmount;
      try {
        if (String(newProductAmount).length <= 4) {
          if (operation === "inc" && newProductAmount != 9999)
            ++newProductAmount;
          if (operation === "dec" && newProductAmount > 0) --newProductAmount;

          //remove the product from the cart
          if (newProductAmount === 0) {
            await this.removeProductFromCart();
            this.productAmount = 0;
            if (this.productAmount === "") this.productAmount = 0;
            return;
          }

          const isNewProductAdded =
            this.productAmount === 0 && operation === "inc";

          if (isNewProductAdded) {
            this.$store.commit("setLoading", true);
            this.toggleAllUpdateAmountActions(true);
          }

          //upate the product amount in the cart
          const isUpdated = await this.$store.dispatch(
            "Orders/updateOrderLine",
            {
              ord: this.ord,
              ordi: this.ordi ?? 0,
              part: this.product.part,
              quant: newProductAmount,
              partPrice: parseFloat(this.productPrice),
            }
          );
          if (isNewProductAdded) {
            this.$store.commit("setLoading", false);
            this.toggleAllUpdateAmountActions(false);
            if (!this.product?.CustParts[0]?.Star) {
              await this.toggleFavoriteProduct();
            }
          }
          if (isUpdated) this.productAmount = newProductAmount;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-name {
  display: flex;
  justify-content: space-between;
}
.side-actions {
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: stretch;
}
.update-amount-actions {
  display: flex;
  gap: 6px;
}
@media (min-width: 1024px) {
  .card-container {
    min-width: 350px !important;
    background: $cardBackgroundColor;
  }
  button {
    &:hover {
      cursor: pointer;
    }
  }
}
.product-item-overlay {
  position: relative;
}

@media (max-width: 1200px) {
  .product-item-overlay {
    margin-top: 10px;
  }
}

.trash-icon {
  width: $extraSmallTextSize;
  align-self: center;
  cursor: pointer;
  margin-top: 14px;
}
.favorite-icon {
  color: $primaryTextColor;
  font-size: $largestTextSize;
  cursor: pointer;
}
.product-weight {
  position: absolute;
  left: 15px;
  top: 10px;
  border-radius: 50px;
  background: $primaryColor;
  font-size: 12px;
  padding: 0 5px;
  color: white;
  z-index: 1;
  .partrweight {
    font-size: $mediumTextSize;
  }

  @media (max-width: 1200px) {
    top: -13px;
  }
}

.product-continer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  text-align: start;
  width: 100%;
  position: relative;
  overflow: hidden;
  .text-continer {
    width: 100%;
    height: inherit;
    padding: 10px;
    display: flex;
    flex-direction: column;
    margin-right: -20px;
    h1 {
      color: white;
      font-size: $mediumTextSize;
      margin: 11px 0px;
    }
    span {
      color: #c4c4c4;
    }
    .button-text {
      font-size: $smallTextSize;
    }
    button {
      border: none;
      background-color: transparent;
      color: white;
      font-size: 1.2rem;
      min-height: 51px;
    }
    hr {
      border-bottom: 1px solid #ffffff;
      opacity: 0.1;
      width: 100%;
      margin: 0;
    }
    .description {
      width: 75%;
    }
  }

  .product-image {
    width: 120px;
    height: inherit;
    object-fit: cover;
    position: relative;
    border: 1px solid $primaryTextColor;
    border-radius: 66px 0px 0px 76px;
    left: 22px;
    min-height: 130px;
  }

  @media (max-width: 1200px) {
    .product-image {
      min-height: 0px;
    }
  }

  .product-actions {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    div {
      display: flex;
      align-items: center;
    }

    @media (max-width: 1200px) {
      .add-amount-btn-wrapper {
        align-items: end;
        /*margin-right: -24px;*/
      }

      .add-amount-container-wrapper {
        align-items: end;
        margin-right: -14px;
        margin-bottom: -9px;
      }
    }

    span {
      color: $primaryTextColor;
      font-size: $largTextSize;

      span {
        font-size: $extraSmallTextSize;
      }
    }
    .plus-icon {
      width: 20px;
      height: 20px;
    }

    .add-amount-container {
      display: block;
      width: 100px;
      text-align: center;

      input[name="amount"] {
        width: 40px;
        text-align: center;
        color: white;
        border: none;
        background-color: $secondaryColor;
        font-size: $largTextSize !important;
        border-radius: 50%;
        margin: 0;
      }

      @media (min-width: 1200px) {
        input[name="amount"] {
          background-color: black;
        }
      }

      .plus,
      .minus {
        color: rgb(0, 0, 0);
        font-size: $largTextSize;
        font-weight: bold;
        cursor: pointer;
      }

      .minus {
        margin-left: 5px;
      }

      .input-amount {
        display: flex;
        justify-content: space-evenly;
        background: $primaryTextColor;
        border-radius: 50px;
        height: $largestTextSize;
      }

      button {
        padding: 0;
        margin: 0;
      }

      input:hover,
      input:focus {
        border: none;
        box-shadow: none;
        border-bottom: none !important;
      }

      span {
        font-size: 13px;
        color: white;
        font-weight: normal;
        font-family: "Gisha", sans-serif;
        letter-spacing: 1px;
      }

      .disable {
        color: rgb(109 109 109);
        cursor: not-allowed;
      }

      .input-amount.disable {
        background: #838383;
        user-select: none;
      }

      .input-amount.disable-right {
        background: rgb(255, 230, 173);
        background: linear-gradient(
          90deg,
          rgba(255, 230, 173, 1) 45%,
          rgba(131, 131, 131, 1) 45%
        );
      }

      .input-amount.disable-left {
        background: rgb(131, 131, 131);
        background: linear-gradient(
          90deg,
          rgba(131, 131, 131, 1) 45%,
          rgba(255, 230, 173, 1) 45%
        );
      }
    }

    @media (max-width: 360px) {
      .add-amount-container {
        width: 90px;
      }
    }
  }

  .prices-section {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 2px;

    .price-info {
      display: flex;
      gap: 10px;
      align-items: baseline;
      width: 100%;
      justify-content: space-between;
      margin-top: -10px;
      .price-title {
        font-size: $mediumTextSize;
        color: #c4c4c4;
      }

      .price-title-small {
        font-size: $extraSmallTextSize;
        color: #c4c4c4;
      }

      .prices-values {
        display: flex;
        align-items: baseline;
        width: 100%;
        justify-content: center;
        .price-value {
          font-size: 22px;
          color: $primaryTextColor;
        }

        .discount-value {
          margin-left: 13px;
        }

        .price-value-small {
          font-size: 20px;
          color: $primaryTextColor;
        }

        .price-unit {
          font-size: $extraSmallTextSize;
          color: $primaryTextColor;
        }
      }
    }
  }

  .disable-btn {
    pointer-events: none !important;
  }
}
</style>
