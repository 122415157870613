<template>
  <div class="not-found-container">
    <img v-if="!noIcon" :src="`/images/${imageIcon}`" />
    <h1 class="felix007">
      <slot></slot>
    </h1>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  props: {
    imageIcon: {
      type: String,
      default: "undraw_exclamation-point.png",
    },
    noIcon: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style scoped lang="scss">
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 10px;

  img {
    max-width: 40px;
    max-height: 80px;
  }
  
  h1 {
    color: $primaryTextColor;
    font-size: $largTextSize;
    text-align: center;
    margin: 0;
  }
}
</style>
