import Api from "./Api";
export default {
  getCustDistLines() {
    return Api().get("getCustDistLines");
  },
  getDistLines() {
    return Api().get("getDistLines");
  },
  updateDistLine(obj) {
    return Api().post("updateDistLine", obj);
  },
  deleteDistLine(lineKey) {
    return Api().post("deleteDistLine", lineKey);
  },
};
