<template>
  <section class="categories-section">
    <div class="flex section-actions">
      <BaseButton @click="updateCategories">עדכן קטגוריות</BaseButton>
    </div>
    <div class="categoreis gap-20">
      <div
        tag="a"
        v-for="(category) in getCategories"
        :key="category.familycode"
        class="category"
        :class="{ disabled: !category.isShownInModal }"
      >
        {{ category.familyname }}
        <form
          class="category-actions"
          :class="{ disabled: !category.isShownInModal }"
        >
          <div class="flex gap-10">
            <label :for="`priority-category-${category.familycode}`"
              >סדר תצוגה:
            </label>
            <input
              :value="category.sortOrderNumber"
              :id="`priority-category-${category.familycode}`"
              type="text"
              inputmode="numeric"
              @input="updateSortOrderCategory(category.familycode, $event)"
              required
            />
          </div>
          <div class="flex gap-10">
            <label :for="`expand-category-${category.familycode}`"
              >הרחב את גודל הכפתור?
            </label>
            <input
              :checked="category.isButtonExpand"
              :id="`expand-category-${category.familycode}`"
              type="checkbox"
              @click="updateExpandCategory(category.familycode)"
            />
          </div>
          <div class="flex gap-10">
            <label :for="`show-category-${category.familycode}`"
              >הצג קטגוריה?
            </label>
            <input
              :checked="category.isShownInModal"
              :id="`show-category-${category.familycode}`"
              type="checkbox"
              @click="updateShowCategory(category.familycode)"
            />
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "../helpers/BaseButton.vue";

export default {
  components: { BaseButton },
  data() {
    return {
      categories: [],
    };
  },
  mounted() {
    this.loadCategories();
  },
  methods: {
    findFamilyIndex(familyCode) {
      return this.categories.findIndex(
        (category) => category.familycode === familyCode
      );
    },
    updateShowCategory(familyCode) {
      const index = this.findFamilyIndex(familyCode);
      this.categories[index].isShownInModal =
        !this.categories[index].isShownInModal;
    },
    updateSortOrderCategory(familyCode, event) {
      const index = this.findFamilyIndex(familyCode);
      if (!isNaN(parseInt(event.target.value)))
        this.categories[index].sortOrderNumber = parseInt(event.target.value);
    },
    updateExpandCategory(familyCode) {
      const index = this.findFamilyIndex(familyCode);
      this.categories[index].isButtonExpand =
        !this.categories[index].isButtonExpand;
    },
    loadCategories() {
      this.categories = this.getCategories;
      console.log(this.categories);
    },
    async updateCategories() {
      try {
        await this.$store.dispatch("Parts/updateFamilies", this.categories);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      getCategories: "Parts/getFamilies",
    }),
  },
};
</script>

<style scoped lang="scss">
.section-actions {
  justify-content: space-between;
  margin-bottom: 20px;
}

.category-actions label {
  color: white;
  font-size: $largTextSize;
}

.category-actions.disabled {
  label {
    color: rgb(217, 217, 217);
  }
}

.category-actions {
  display: flex;
  text-shadow: none;
  box-shadow: none;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;

  input[id*="priority-category"] {
    width: 30px;
    text-align: center;
    color: white;
  }

  input[id*="expand-category"],
  input[id*="show-category"] {
    width: 20px;
    opacity: 1;
    position: static;
    pointer-events: fill;
    border-bottom: none;
    box-shadow: none;
  }
}
.categories-section {
  padding: 10px;
}
.categoreis {
  display: grid !important;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px !important;
  color: white;
  overflow: scroll;
  overflow-x: hidden;
  height: calc(90vh - 90px);
  padding: 20px;
}

@media (min-width: 900px) {
  .categoreis {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 50px;
  }
}

.category.disabled {
  color: #e0e0e0;
}
.category {
  margin-bottom: 10px;
  font-family: "felix007", sans-serif;
  text-align: center;
  font-size: $largestTextSize !important;
  letter-spacing: 1px;
  padding: 10px;
  text-shadow: 0px 3px 4px black;
  box-shadow: 1px 1px 1px #ffe6ad;
  background-color: $secondaryTextColor;
  border-radius: 20px;
  color: #ffe6ad;
}
.distlines-select {
  height: calc(80vh - 180px);
  margin-top: 20px;
  overflow: scroll;
  overflow-x: hidden;
}
.new-distline {
  margin-top: 10px;
}
.text-continer {
  margin-bottom: 10px;
  color: white;
}
.content {
  width: 100%;
  font-size: $mediumTextSize;
}
.icon {
  width: 30px;
}
.add-alert {
  width: 90%;
}
.distlines {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  max-width: 700px;
}
.alert {
  width: 100%;
  padding: 10px;
  box-shadow: 1px 1px 1px #ffe6ad;
  border-radius: 20px;
  background: #393945;
}

.product-continer {
  margin-bottom: 10px;
}
button {
  margin-left: 10px;
}
.type {
  color: white;
}
</style>
