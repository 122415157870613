import Api from "./Api";

export default {
  createOrderInDB(order) {
    return Api().post("createOrderInDB", order);
  },
  getOrders(obj) {
    console.log('order-service');
  
    return Api().post("orders", obj);
  },

  updateOrder(obj) {
    return Api().post("updateOrder", obj);
  },
  updateOrderLine(obj) {
    return Api().post("orderline", obj);
  },
  removeOrderLine(obj) {
    return Api().post("removeorderline", obj);
  },
  sendOrder(order) {
    return Api().post("send-order", order);
  },
  printDelivery(docNum) {
    return Api().get("print-procedure", {
      params: { fieldName: docNum, procedureName: "NIRR_WWWSHOWDOC_D" },
      responseType: "blob",
    });
  },
  cloneOrderToCart(obj) {
    return Api().post("clone-order-to-cart", obj);
  },
  clearCart() {
    return Api().post("clear-cart");
  },
  generateLinkPaymment(obj) {
    return Api().post("generateLinkPaymment", obj);
  },
};
