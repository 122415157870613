<template>
  <div class="order-container Heebo">
    <BaseCard @click="gotoOrderPage" class="card-container">
      <div class="date_and_order_num_info">
        <div class="ord-number-info">
          <span class="ord-number-label grey-text">מס' הזמנה</span>
          <span class="ord-number-val">{{ order.ord }}</span>
        </div>
        <span class="ord-date grey-text">{{ getFormatSubmitDate }}</span>
      </div>
      <div v-if="!isOrderOnPendingStatus && order.senddate" class="supply_info">
        <span class="supply-date-label grey-text">מועד אספקה:</span>
        <span class="supplu-date-val">{{ getSupplyDate }}</span>
      </div>
      <div class="status_info">
        <span class="status-label grey-text">סטטוס:</span>
        <span :style="getOrderStatusColor" class="status-val"
          ><img
            v-if="order.Status.icon"
            :src="`/images/${order.Status.icon}`"
            alt="status-icon"
            class="status-icon"
          />
          {{ order.Status.statusname }}</span
        >
      </div>
      <div v-if="order.peymentmethode" class="status_info">
        <span class="status-label grey-text">שיטת התשלום:</span>
        <span class="status-val"> {{ getPaymentMethod }}</span>
      </div>
      <SperetorLine hrWidth="100%" />
      <div class="price_info">
        <span class="price-label grey-text">סה"כ לתשלום:</span>
        <span class="price-val felix007"
          ><span style="font-size: 14px">₪</span
          >{{ getFixedPrice(getTotalPrice) }}</span
        >
      </div>
    </BaseCard>
  </div>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import SperetorLine from "./SperetorLine.vue";
import { formatDate } from "../../Dates.js";
import { routerNames } from "@/router";
import { mapGetters } from "vuex";

export default {
  name: "OrderItem",
  components: { BaseCard, SperetorLine },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    gotoOrderPage() {
      this.$router.push({
        name: routerNames.order,
        params: {
          ord: this.order.ord,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      getFixedPrice: "Orders/getFixedPrice",
    }),
    getTotalPrice() {
      const totalPrice =
        this.$store.getters["Orders/getOrderTotalPrice"](this.order.ord) +
        (this.order.debtBalance ?? 0);
      if (totalPrice <= 0) return 0;
      return totalPrice;
    },
    getPaymentMethod() {
      switch (this.order.peymentmethode) {
        case "credit":
          return "שולם באשראי";
        case "cash":
          return "תשלום במזומן/צ'ק";
        case "obligo":
          return "שולם באובליגו";
      }
      return "";
    },
    getFormatSubmitDate() {
      return formatDate(this.order.duedate, {
        delimiter: ".",
      });
    },
    getSupplyDate() {
      return formatDate(this.order.senddate, {
        delimiter: "/",
      });
    },
    isOrderOnPendingStatus() {
      return this.order.ordStatus === "ממתין לתשלום";
    },
    getOrderStatusColor() {
      switch (this.order.status) {
        case 1:
        case 7:
        case 4: // חלקית/אושרה להפצה או סופקה
          return "color: #71C0A3";
        case 3:
        case 6: // ממתינה לביטול/מבוטלת
          return "color: #FFFFFF";
        case 5:
        case 2: // נקלטה/ממתינה לקליטה
          return "color: #FFE6AD";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  cursor: pointer;
  padding: 14px;
  padding-bottom: 5px;
}

@media (min-width: 1200px) {
  .card-container {
    min-height: 200px;
    background: $cardBackgroundColor;
  }
}

.price_info,
.status_info,
.supply_info,
.ord-number-info,
.date_and_order_num_info {
  display: flex;
  justify-content: space-between;
}

.date_and_order_num_info {
  margin-bottom: 10px;
}

.price_info {
  align-items: center;
}

.order-container {
  font-size: $smallTextSize;
}

.ord-number-info {
  display: flex;
  flex-direction: column;
}

.ord-number-label,
.ord-date {
  font-size: $extraSmallTextSize;
}

.ord-number-label {
  font-size: $extraSmallTextSize;
  font-weight: bold;
}

.ord-number-val {
  font-size: $mediumTextSize;
  margin-top: -5px;
}

.price-val {
  font-size: $largTextSize;
}

.order-container hr {
  margin: 7px 0;
}

.status-icon {
  max-width: 11px;
  max-height: 11px;
}
</style>
