<template>
  <footer id="footer-container" :class="stickyFooterStyle">
    <div v-if="isLoading" class="row progress">
      <div class="indeterminate"></div>
    </div>
    <div
      v-if="isCatrgoryPage && !isDesktop"
      id="footer-bottom-category-container"
    >
      <CartSummary />
      <BaseButton
        @click="navigateToCartPage"
        bgColor="#2B2B35"
        class="submit-order-btn"
        :class="disableButtonStyle"
        >{{ $t("Header.orderSummary") }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11.586"
          height="11.586"
          viewBox="0 0 11.586 11.586"
        >
          <path
            id="Path_2265"
            data-name="Path 2265"
            d="M9.793,4l1.021,1.021L6.773,9.069h8.813v1.448H6.773l4.041,4.048L9.793,15.586,4,9.793Z"
            transform="translate(-4 -4)"
            fill="#ffe6ad"
          />
        </svg>
      </BaseButton>
    </div>
    <div id="footer-bottom-part">
      <div class="container">
        <div id="footer-bottom-part-container">
          <div
            v-if="!isCatrgoryPage || isDesktop"
            id="footer-bottom-part-text-container"
          >
            <p>
              {{ $t("Footer.companyName") }}
            </p>
          </div>
          <div
            v-if="!isCatrgoryPage || isDesktop"
            id="footer-bottom-part-logos-container"
          >
            <div class="flex gap-05">
              <p class="app-version">
                V-{{ this.$store.getters["getAppVersion"] }}
              </p>
              <a href="https://dolimo.co.il" id="lws-credits">
                <img
                  width="100"
                  height="auto"
                  src="../../../public/images/dolimoLogo.png"
                  class="dolimo-logo"
                  alt="dolimo"
                  style="max-width: 100%; height: auto; margin: auto"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import BaseButton from "../helpers/BaseButton.vue";
import { mapGetters } from "vuex";
import { routerNames } from "@/router";
import CartSummary from "../helpers/CartSummary.vue";

export default {
  components: {
    BaseButton,
    CartSummary,
  },
  methods: {
    navigateToCartPage() {
      this.$router.push({ name: routerNames.cart });
    },
  },
  computed: {
    stickyFooterStyle() {
      if (this.isDesktop) return "sticky-footer";
      if (this.isCatrgoryPage) return "sticky-footer";
      if (this.isOrdersPage) return "sticky-footer";
      else return "";
    },
    isCatrgoryPage() {
      return this.$route.name === "category";
    },
    isOrdersPage() {
      return this.$route.name === "orders";
    },
    disableButtonStyle() {
      if (this.$store.getters["Orders/getCartOrderLinesNumber"] === 0) {
        return "disabled";
      }
      return "";
    },
    ...mapGetters({
      isLoading: "isLoading",
      isDesktop: "isDesktop",
    }),
  },
};
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

footer {
  // position: fixed;
  // bottom: 0;
  width: 100%;
}

.sticky-footer {
  z-index: 5;
  position: fixed;
  bottom: 0;
}

#footer-bottom-part-container {
  display: flex;
  justify-items: center;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 10px;

  .app-version {
    font-size: 10px;
  }

  p {
    margin: 5px 0;
    color: #ffe6ad;
    font-size: $smallTextSize;
  }
}
#footer-bottom-part,
#footer-bottom-category-container {
  background-color: $footerBackgroundColor;
  margin: 0;
}

#footer-bottom-category-container {
  display: flex;
  border-radius: 30px 30px 0px 0px;
  margin: 0;
  height: 65px;
  justify-content: space-around;
  align-items: center;

  .submit-order-btn {
    padding: 7px 10px;
    font-family: "Gisha";
  }

  hr {
    align-self: center;
  }
}
.dolimo-logo {
  width: 52px;
}
</style>
