import AlertService from "../../services/AlertService";
export default {

    namespaced: true, // all the state, mutations, actions, getters in this module is unique to this store module
    state() {
        return {
            alerts: [],
            alertsPopups: [],
            lastAlert: null,
            popupBycust: [],
            promotions: [],
        }
    },
    mutations: {
        setAlerts(state, alerts) {
            state.alerts = alerts
        },
        setAlertsPopups(state, alertsPopups) {
            state.alertsPopups = alertsPopups
        },
        setLastAlert(state, lastAlert) {
            state.lastAlert = lastAlert
        },
        setPopupBycust(state, popupBycust) {
            state.popupBycust = popupBycust
        },
        setPromotions(state, promotions) {
            state.promotions = promotions
        },
        addPromotion(state, savedPromotion) {
            state.promotions.push(savedPromotion);
        },
        updatePromotion(state, promotion) {
            const idx = state.promotions.findIndex((currPromotion) => currPromotion.promo === promotion.promo);
            state.promotions.splice(idx, 1, promotion);
        },
        removePopUp(state, alert) {
            const idx = state.popupBycust.findIndex((popup) => popup.alert === alert);
            state.popupBycust.splice(idx, 1);
        },
        removePromotion(state, promotionId) {
            const idx = state.promotions.findIndex((currPromotion) => currPromotion.promo === promotionId);
            state.promotions.splice(idx, 1);
        },
        addAlert(state, savedAlert) {
            state.alertsPopups.push(savedAlert);
        },
        updateAlert(state, Alert) {
            const idx = state.alertsPopups.findIndex((curralert) => curralert.alert === Alert.alert);
            state.alertsPopups.splice(idx, 1, Alert);
        },
        removeAlert(state, alertId) {
            const idx = state.alertsPopups.findIndex((currAlert) => currAlert.alert === alertId);
            state.alertsPopups.splice(idx, 1);
        },
    },
    actions: {
        async savePromotion(context, promotion) {
            context.dispatch("startLoading", null, { root: true });
            var savedPromotion = await AlertService.savePromotion(promotion);
            if (savedPromotion?.data?.promo) {
                context.commit('addPromotion', savedPromotion.data);
                context.dispatch("stopLoading", { status: "success", message: "נוסף הפירסום", }, { root: true });
            } else {
                context.commit('updatePromotion', promotion);
                context.dispatch("stopLoading", { status: "success", message: "עודכן הפירסום", }, { root: true });
            }
            return savedPromotion;
        },
        async saveAlert(context, alert) {
            context.dispatch("startLoading", null, { root: true });
            var savedAlert = await AlertService.saveAlert(alert);
            console.log(savedAlert);
            if (savedAlert?.data?.alert) {
                context.commit('addAlert', savedAlert.data);
                context.dispatch("stopLoading", { status: "success", message: "נוסף הודעה", }, { root: true });
            } else {
                context.commit('updateAlert', alert);
                context.dispatch("stopLoading", { status: "success", message: "עודכן הודעה", }, { root: true });
            }
            return savedAlert;
        },
        async removePromotion(context, promo) {
            await AlertService.removePromotion(promo)
            context.commit('removePromotion', promo);
        },

        async removeAlert(context, alert) {
            await AlertService.removeAlert(alert);
            context.commit('removeAlert', alert);
        },
        async alerts(context) {
            const alerts = await AlertService.alerts();
            context.commit("setAlerts", alerts.data);
        },
        async alertsPopups(context) {
            const alertsPopups = await AlertService.alertsPopups();
            context.commit("setAlertsPopups", alertsPopups.data);
        },
        async lastAlert(context) {
            const lastAlert = await AlertService.lastAlert();
            context.commit("setLastAlert", lastAlert.data);
        },
        async popupBycust(context, obj) {
            const popupBycust = await AlertService.popupBycust(obj);
            context.commit("setPopupBycust", popupBycust.data);
        },
        async approvePopUp(context, obj) {
            await AlertService.approvePopUp(obj);
            context.commit("removePopUp", obj.alert);
        },
        async promotions(context) {
            var response = await AlertService.promotions();
            context.commit("setPromotions", response.data);
        }
    },
    getters: {
        getAlerts(state) {
            return state.alerts
        },
        lastAlert(state) {
            return state.lastAlert
        },
        popupBycust(state) {
            return state.popupBycust
        },
        getPromotions(state) {
            return state.promotions
        },
        getAlertsPopups(state) {
            return state.alertsPopups
        }
    },
}