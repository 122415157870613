<template>
  <div id="pageStretcher">
    <!-- <transition name="fade"> -->
    <div
      class="Heebo install-promt"
      v-if="deferredPrompt && $route.name === 'home'"
    >
      הורידו את האפליקציה שלנו, היא לא תתפוס מקום בטלפון שלכם והיא גם עובדת במצב
      לא מקוון!
      <div class="flex actions">
        <button class="install" @click="install">הורד</button>
        <button class="dismiss" @click="dismiss">לא עכשיו</button>
      </div>
    </div>
    <!-- </transition> -->
    <div class="page">
      <TheHeader v-if="$route.name !== 'passkey'"></TheHeader>

      <div class="container" style="flex: 1">
        <div
          :class="$route.name === 'loginSms' ? 'login-sms' : ''"
          class="row main-content-app"
        >
          <div
            :class="
              $route.name === 'loginPage'
                ? 'nav-login'
                : $route.name === 'loginSms'
                ? 'nav-login'
                : ''
            "
            class="main"
          >
            <router-view v-slot="{ Component }">
              <span
                v-if="
                  getIsDesktop &&
                  $route.meta.title !== 'הזמנה חדשה' &&
                  $route.meta.title
                "
                class="pageTitle felix007"
                >{{ $route.meta.title }}</span
              >
              <!-- <transition name="fade" mode="out-in"> -->
              <component :is="Component" />
              <!-- </transition> -->
            </router-view>
          </div>
          <AlertPopup
            v-if="popups.length"
            @approve="approvePopUp"
            :popups="popups"
            :currPopup="currentPopup"
          />
          <div class="side-bar">
            <main
              v-if="(showNavContainer && getIsDesktop) || getIsLaptop"
              id="mainapp"
            >
              <router-view name="sideBar"></router-view>
            </main>
          </div>
        </div>
      </div>
      <TheFooter v-if="$route.name !== 'passkey'"></TheFooter>
    </div>
    <!-- <router-view name="resetPassPage"></router-view> -->
  </div>
</template>

<script>
import M from "materialize-css";
import TheFooter from "./components/layout/TheFooter.vue";
import TheHeader from "./components/layout/TheHeader.vue";
import AlertPopup from "../src/components/helpers/AlertPopup.vue";

import { mapGetters } from "vuex";
import { mapActions } from "vuex";

export default {
  name: "App",

  mounted() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data === "reload") {
          window.location.reload(true);
        }
      });
    }
    document.getElementById("dolimo_loader").style.display = "none";
    this.setLang();
    M.AutoInit();
    console.log(this.popups);
    // this.logOffAfterTime();

    // uncomment code to bring back the header remove by scroll

    // var prevScrollpos = window.pageYOffset;
    // var isTablet = this.$store.getters.isTablet;
    // var isMobile = this.$store.getters.isMobile;
    // window.onscroll = function () {
    //   var currentScrollPos = window.pageYOffset;
    //   if (currentScrollPos <= 0) {
    //     console.log("take thge header down");
    //     document.querySelector("header").classList.remove("header-up");
    //     document.querySelector(".side-bar").classList.remove("header-up");

    //     if (!isMobile) {
    //       document.querySelector(".side-bar").classList.remove("header-up");
    //     }

    //     if (isMobile) {
    //       document.querySelector(".option-selected-conteiner").style.top =
    //         "1px";
    //       //   .classList.remove("header-up");
    //     }
    //     if (isTablet) {
    //       document.querySelector(".option-selected-conteiner").style.top =
    //         "137px";
    //     }
    //   } else {
    //     if (prevScrollpos > currentScrollPos) {
    //       console.log("take thge header down");
    //       document.querySelector("header").classList.remove("header-up");
    //       if (!isMobile) {
    //         document.querySelector(".side-bar").classList.remove("header-up");
    //       }

    //       if (isMobile) {
    //         document
    //           .querySelector(".option-selected-conteiner")
    //           .classList.remove("header-up");
    //       }
    //     } else {
    //       document.querySelector("header").classList.add("header-up");
    //       document.querySelector(".side-bar").classList.add("header-up");
    //       if (!isMobile) {
    //         document.querySelector(".side-bar").classList.add("header-up");
    //       }

    //       if (isMobile) {
    //         document.querySelector(".option-selected-conteiner").style.top =
    //           "92.59px";
    //         document
    //           .querySelector(".option-selected-conteiner")
    //           .classList.add("header-up");
    //       }
    //       if (isTablet) {
    //         document.querySelector(".option-selected-conteiner").style.top =
    //           "23.59px";
    //       }

    //       console.log("take thge header up");
    //     }
    //   }

    //   prevScrollpos = currentScrollPos;
    // };
  },
  computed: {
    ...mapGetters({
      isUserLogin: "AuthModule/isAuthenticated",
      getUserLang: "AuthModule/getUserLang",
      popups: "Alert/popupBycust",
      userCust: "AuthModule/getUserCust",
      getIsDesktop: "isDesktop",
      getIsLaptop: "isLaptop",
    }),
    isMainContentShowing() {
      if (this.$store.state.isMobile || this.$store.state.isTablet) {
        if (this.$store.state.openMenu) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      // let addToHomeCookie = false;
      // if (document.cookie) {
      //   addToHomeCookie = document?.cookie
      //     .split("; ")
      //     .find((cookie) => cookie.startsWith("add-to-home-screen="))
      //     .split("=")[1];
      // }
      // if (!addToHomeCookie) {
      //   this.deferredPrompt = e;
      // }
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
    // console.log(isTablet);
    this.setScreenSizes();
    addEventListener("resize", () => {
      this.setScreenSizes();
      // this.logOffAfterTime();
    });
    // window.addEventListener("languagechange", function () {
    //   alert("languagechange event detected!");
    // });
  },
  watch: {
    "$store.state.AuthModule.token": function () {
      this.setLang();
    },
  },
  methods: {
    async dismiss() {
      document.cookie =
        "add-to-home-screen=true; expires=" +
        new Date(Date.now() + 1296000000).toUTCString();
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    showNavContainer() {
      if (
        this.$route.name === "LoginPage" ||
        this.$route.name === "loginSmsPage"
      )
        return false;
      else return true;
    },

    approvePopUp(alert) {
      this.$store.dispatch("Alert/approvePopUp", {
        alert,
        cust: this.userCust,
      });
    },
    logOffAfterTime() {
      var isTablet = this.$store.getters.isTablet;
      var isMobile = this.$store.getters.isMobile;

      if (isTablet || isMobile) {
        if (this.timerToAutoLogout) {
          clearTimeout(this.timerToAutoLogout);
        }
        this.$el.addEventListener("touchmove", () => {
          if (this.isUserLogin) {
            clearTimeout(this.timerToAutoLogoutMobile);
            this.timerToAutoLogoutMobile = setTimeout(() => {
              this.logout();
              this.$router.go(0);
            }, this.minute * 10);
          }
        });
      }

      if (!isTablet && !isMobile) {
        if (this.timerToAutoLogoutMobile) {
          clearTimeout(this.timerToAutoLogoutMobile);
        }
        this.$el.addEventListener("mousemove", () => {
          if (this.isUserLogin) {
            clearTimeout(this.timerToAutoLogout);
            this.timerToAutoLogout = setTimeout(() => {
              this.logout();
              this.$router.go(0);
            }, this.minute * 10);
          }
        });
      }
    },
    setLang() {
      if (this.isUserLogin) {
        // itzhak 01.11.23 add for now to show the login page need later real get data for login from backend
        //alert(this.getUserLang);
        // this.$i18n.locale = this.getUserLang;
        // document.dir = this.$i18n.messages[this.$i18n.locale].direction;
        // document.documentElement.lang = this.$i18n.locale;
      }
    },
    setScreenSizes() {
      this.$store.commit("setIsDesktop", window.innerWidth >= 1200);
      this.$store.commit(
        "setIsLaptop",
        window.innerWidth >= 1024 && window.innerWidth <= 1199
      );
      this.$store.commit(
        "setIsTablet",
        window.innerWidth >= 602 && window.innerWidth <= 1023
      );
      this.$store.commit("setIsMobile", window.innerWidth <= 601);
    },
    ...mapActions({
      logout: "AuthModule/logout",
    }),
  },
  data() {
    return {
      textfloat: "right",
      currentPopup: 0,
      isMobile: false,
      isTablet: false,
      isLaptop: false,
      isDesktop: false,
      timerToAutoLogoutDesktop: null,
      timerToAutoLogoutMobile: null,
      minute: 60000,
      deferredPrompt: null,
    };
  },
  components: {
    AlertPopup,
    TheFooter,
    TheHeader,
  },
};
</script>

<style lang="scss">
@import "./assets/styles/materializeResets.scss";
@import "./assets/styles/globalClass.scss";

@font-face {
  font-family: "felix007";
  src: url("./assets/styles/fonts/Felix007.woff2") format("woff2");
}

@font-face {
  font-family: "Gisha";
  src: url("./assets/styles/fonts/Gisha.woff2") format("woff2");
}

@font-face {
  font-family: "Hebbo";
  src: url("./assets/styles/fonts/Heebo.woff2") format("woff2");
}

.pageTitle {
  padding: 0px $mainContentPadding;
  color: $primaryTextColor;
  font-size: $largestTextSize;
  line-height: 0.8;
  margin-top: 10px;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.install-promt {
  padding: 15px;
  color: white;
  text-align: center;

  .actions {
    margin-top: 5px;
    gap: 10px;
    font-family: "Hebbo" !important;

    .dismiss,
    .install {
      border: none;
      padding: 5px 10px;
      color: white;
      font-size: 16px;
    }

    .dismiss {
      background-color: #5c5c5c;
    }

    .install {
      background-color: #6d1106;
    }

    button:hover {
      border: 1px $primaryTextColor solid;
      color: $primaryTextColor;
    }
  }
}

//desctop setting
@media (min-width: 1024px) {
  // .container {
  //   max-width: 100%;
  // }
  .nav-login {
    width: 100%;
    left: unset;
    grid-column: unset !important;
  }
  body:has(.page-thank) .main-content-app {
    display: block !important;
  }

  body:has(.payment-page) .main-content-app {
    display: block !important;
  }
  .main-content-app {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 0;
    &::after {
      display: none;
    }
  }
  .main {
    grid-column: 2 / 6;
  }

  @media (min-width: 1024px) {
    .main {
      margin-top: 20px;
    }

    body:has(.category-title) .main,
    body:has(.login-page-form) .main {
      margin-top: 0px;
    }
  }
  .side-bar {
    grid-column: 1 / 2;
  }
  .login-sms {
    display: flex;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

* {
  touch-action: manipulation;
}
// [type="checkbox"].filled-in + span:not(.lever):before,
// [type="checkbox"].filled-in + span:not(.lever):after {
//   left: unset !important;
// }
body.stop-scrolling {
  overflow: hidden !important;
}
header.header-up,
.header-up {
  transform: translateY(-113px);
  transition: 0.2s;
}
.main-content-app,
.option-selected-conteiner {
  transition: 0.2s;
}

// .side-bar {
//   //   position: -webkit-sticky; /* Safari */
//   position: sticky;
//   top: 0;
// }

// #mainapp {
//   max-height: calc(100vh - 112px);
//   overflow-y: auto;
//   padding: 0px 3px 0px 20px;
// }

/* width */
// ::-webkit-scrollbar {
//   width: 8px;
// }
// ::-webkit-scrollbar-button {
//   height: 5%;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: $primaryTextColor;
//   border-radius: 10px;
//   min-height: 40px !important;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #b30000;
// }

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #585858;
//   box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.75) inset;
// }
.nav-login {
  top: 0px !important;
}

.toast {
  width: fit-content;
  color: white !important;
  margin: auto;
}
.side-bar:lang(en),
.main:lang(en) {
  left: 0 !important;
}
.lang-container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
  .caret:lang(en) {
    left: unset !important;
    right: 0 !important;
  }
  .select-wrapper input.select-dropdown:focus {
    border: unset !important;
    margin: 0 !important;
  }
  .select-wrapper {
    width: 50px !important;
  }
  input {
    border: unset !important;
    margin: 0 !important;
  }
  svg {
    right: unset !important;
    left: 0;
  }
  ul {
    padding: 0;
    text-align: center;
    span {
      color: #1c1c1c !important;
    }
  }
}
@media (max-width: 601px) {
  .side-bar.col {
    padding: 0;
  }
  header.header-up,
  .main-content-app.header-up,
  .option-selected-conteiner.header-up {
    transform: translateY(-93px);
    transition: 0.2s;
  }
  .main-content-app,
  .option-selected-conteiner {
    transition: 0.2s;
  }

  // .main {
  //   z-index: -2;
  // }
  // .footer {
  //   z-index: -3;
  // }
}

// .row .col {
//   float: var(--textfloat)
// }
/* Chrome, Safari, Edge, Opera hide arrow from number inputs*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox hide arrow from number inputs*/
input[type="number"] {
  -moz-appearance: textfield;
}
.page {
  // overflow-x: auto;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#pageStretcher {
  display: grid;
  align-content: space-between;
  height: 100vh;
  background: black;
}
.toast-container {
  bottom: 0 !important;
}

.toast {
  color: unset;

  &.success {
    background-color: $primaryColor;
  }

  &.error {
    background-color: rgb(240, 51, 51);
  }
}

@media (min-width: 900px) {
  .side-bar {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 85px;
  }
}

header {
  // position: -webkit-sticky;
  // position: sticky;
  top: 0;
  z-index: 99999 !important;
  transition: 0.2s;
}

@media only screen and (max-width: 601px) {
  //mobile
  main {
    margin-top: 30px;
  }
  //  .side-bar {
  //   position: -webkit-sticky !important;
  //   position: sticky !important;
  //   top: 112px;
  // }
}

[type="checkbox"].dolimo-style.filled-in:not(:checked)
  + span:not(.lever):after {
  border-radius: 7px;
  border: unset;
  box-shadow: 0 0 3px inset;
  right: 0;
}
[type="checkbox"].dolimo-style.filled-in:checked + span:not(.lever):after {
  border-radius: 7px;
  border: unset;
  background-color: $primaryColor;
  top: 3px;
  right: 0;
}

[type="checkbox"] + span:not(.lever) {
  padding: unset;
  padding-right: 35px;
}
[type="checkbox"] + span:not(.lever):lang(en) {
  padding-right: 0px;
  padding-left: 35px;
}
[type="checkbox"].filled-in:checked + span:not(.lever):before {
  left: unset;
  right: 10px;
}
[type="checkbox"].filled-in:checked + span:not(.lever):lang(en)::before {
  top: 2px;
  left: 0px;
}

.btn.dolimo-style {
  background-color: unset;
  background: $primaryBackgroundButtonColor;
  font-size: $mediumTextSize;
  color: white !important;
}

p {
  font-size: $mediumTextSize;
  color: $primaryTextColor;
}
html {
  width: 100%;
  height: 100%;
}
html,
pre {
  font-family: "Gisha";
  box-sizing: border-box;
}
body {
  margin: 0;
  overflow-x: hidden;
  background: black;
}
input.dolimo-style {
  border: 1px solid lightgrey !important;
  border-radius: 10px !important;

  font-size: $smallTextSize !important;
  margin: 0 !important;

  padding-right: 10px !important;
}
input.dolimo-style:lang(en) {
  padding-right: 0px;
  padding-left: 10px !important;
}
textarea {
  padding: 10px !important;
}
.menu-collection {
  padding: 0 !important;
  border: unset !important;
  a {
    color: #747474 !important;
    font-size: $mediumTextSize;
    border: unset !important;
    // border-radius: 8px;
    &.router-link-active {
      color: #73b235 !important;
      background-color: $primaryColorlight !important;
      // border-right: 5px solid $primaryColor !important;
    }
  }
}
input {
  max-width: 100% !important;
  box-sizing: border-box !important;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.3s ease;
}

.slide-left-enter {
  transform: translateX(100%);
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

.multiselect__placeholder {
  color: white;
}

.multiselect__tags {
  background: $secondaryColor !important;
  border-radius: 10px;
}
.multiselect__tag {
  background: gray !important;
}
.multiselect__option--highlight {
  background: $primaryColor;
  &::after {
    display: none;
  }
}
.multiselect__content-wrapper {
  width: fit-content;
}

.multiselect__input {
  color: white !important;
}
</style>
