<template>
  <div class="categories-filter-container">
    <BaseButton
      @click="moveToPage"
      :name="category.familyname"
      bgColor="#393945"
      v-for="category in getCategories"
      :key="category.familycode"
      :style="{
        gridColumn: getButtonWidth({
          name: category.familyname,
          expand: category.isButtonExpand,
        }),
      }"
    >
      <div v-html="getCategoryTitleStyle(category.familyname)"></div>
    </BaseButton>
  </div>
</template>

<script>
// import { routerNames } from "@/router";
import BaseButton from "../helpers/BaseButton.vue";
export default {
  components: { BaseButton },
  name: "Categories",
  emits: ["exit-modal"],
  computed: {
    getCategories() {
      return this.$store.getters["Parts/getExistingPartsFamily"].filter(
        (category) => category.isShownInModal
      );
    },
  },
  methods: {
    getCategoryTitleStyle(categoName) {
      const categoryName = categoName.split(" ");
      if (categoryName.length > 1) {
        return `
        <span class="white-text">${categoryName[0]}</span>
        ${categoryName.slice(1).join(" ")}
              `;
      }
      return `
      ${categoryName[0]}
      `;
    },
    getButtonWidth(category) {
      const categoryName = category.name.split(" ");
      if (categoryName.length >= 3 || category.expand) {
        return "1 / 3";
      }
    },
    moveToPage(event) {
      let name = event.currentTarget.getAttribute("name");
      this.$router.push(`/category/${name}`);
      this.$emit("exit-modal");
    },
  },
};
</script>

<style scoped lang="scss">
.categories-filter-container {
  // display: flex;
  // flex-wrap: wrap;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 20px;
  justify-content: center;
  padding: 15px;

  button {
    font-family: "felix007", sans-serif;
    letter-spacing: 1px;
    padding: 3px 30px;
    text-shadow: 0px 3px 4px black;
    border-radius: 20px;
    color: $primaryTextColor;
    font-size: $largestTextSize;
    width: unset;
    height: 70px;
    line-height: 25px;
  }

  a {
    all: unset;
  }
}

@media (min-width: 1024px) {
  .categories-filter-container {
    padding: 0px;

    button {
      font-size: $largTextSize;
    }
  }
}
</style>
