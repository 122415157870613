<template><hr :class="isRotatedVertical" :style="hrStyles" /></template>

<script>
export default {
  props: {
    hrWidth: {
      type: String,
      default: "300px",
    },
    rotateVertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hrStyles() {
      return {
        width: this.hrWidth,
      };
    },
    isRotatedVertical() {
      if (this.rotateVertical) return ["rotate"];
      else return [];
    },
  },
};
</script>

<style scoped>
hr {
  border: none;
  border-bottom: 2px dashed rgba(255, 255, 255, 0.511);
  margin: 0;
}

hr.rotate {
  transform: rotate(-90deg);
}
</style>
