<template>
  <li class="base-invoice">
    <div class="invoice-title">
      <i v-if="isInvoiceExpanded" class="material-icons expand-icon"
        >expand_less</i
      >
      <i v-else class="material-icons expand-icon">expand_more</i>
      <span
        @click="expandInvoice"
        :class="{ selected: isInvoiceExpanded }"
        class="invoice-type"
        >{{ invoice.doctype }}</span
      >
    </div>
    <transition name="fade">
      <div v-if="isInvoiceExpanded" class="invoice-info">
        <div class="info">
          <span class="key">מס' {{ invoice.doctype }}:</span>
          <span class="value">{{ invoice.docno }}</span>
        </div>
        <div class="info">
          <span class="key">תאריך:</span>
          <span class="value">{{ getDate }}</span>
        </div>
        <button
          @click="$emit('download-invoice', invoice.docId)"
          class="download-invoice-btn"
        >
          הורדת התעודה
        </button>
      </div>
    </transition>
  </li>
</template>

<script>
export default {
  name: "BaseInvoice",
  emits: ["download-invoice"],
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isInvoiceExpanded: true,
    };
  },
  computed: {
    getDate() {
      return this.invoice.curdate;
    },
  },
  methods: {
    expandInvoice() {
      this.isInvoiceExpanded = !this.isInvoiceExpanded;
    },
  },
};
</script>

<style scoped lang="scss">
.invoice-title {
  display: flex;
}
.base-invoice {
  font-size: $mediumTextSize;
  color: #c4c4c4;
  margin: 5px 0px;
  border-radius: 20px;
  background: #3f3f4d;
  padding: 10px 20px;
}
.fade-enter-active {
  transition: opacity 0.3s;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-active {
  display: none !important;
}
.expand-icon {
  opacity: 0.5;
}
.invoice-info {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .key {
    font-size: $smallTextSize;
    color: #c4c4c4;
  }

  .value {
    font-size: $mediumTextSize;
    color: $primaryTextColor;
  }

  .download-invoice-btn:hover {
    background: $secondaryColor;
    color: $primaryTextColor;
  }

  .download-invoice-btn {
    background: rgb(224, 226, 227);
    background: radial-gradient(
      circle,
      rgba(224, 226, 227, 0.10976890756302526) 0%,
      rgba(63, 63, 77, 1) 100%
    );
    border: 3px $secondaryColor solid;
    border-radius: 50px;
    color: $primaryTextColor;
    font-size: $smallTextSize;
    letter-spacing: 0.04px;
    padding: 10px;
    cursor: pointer;
  }

  .info {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 1200px) {
  .invoice-info {
    flex-direction: column;

    .info {
      flex-direction: row;
      justify-content: space-between;
      gap: 5px;
      width: 100%;
    }

    .download-invoice-btn {
      margin-top: 10px;
    }
  }
}
.selected {
  color: $primaryTextColor;
  text-decoration: underline;
}

.invoice-type {
  cursor: pointer;
}
</style>
