import axios from "axios";
import store from "../store";
import { baseUrl } from "../config";

export default () => {
  return axios.create({
    baseURL: baseUrl,
    // baseURL: `http://192.168.68.62:3031/`,
    headers: {
      Authorization: `Bearer ${store.state.AuthModule.token}`,
    },
  });
};
