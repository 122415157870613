<template>
  <teleport to="#modal_backdrop">
    <div class="backdrop-popup"></div>
    <div class="modal-popup">
      <img class="icon" :src="getIconSrc" alt="cart-icon" />
      <h1 class="felix007 center">{{ titleOne }}</h1>
      <span class="Heebo">{{ titleTwo }}</span>
      <span class="delivery-date felix007">{{ newDate }}</span>
      <BaseButton @click="confirmPopup" class="confirm-btn"
        >אישור סופי</BaseButton
      >
      <button @click="rejectPopup" class="reject-btn felix007">
        ביטול פעולה
      </button>
    </div>
  </teleport>
</template>

<script>
import BaseButton from "./BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    titleOne: {
      type: String,
    },
    titleTwo: {
      type: String,
    },
    newDate: {
      type: String,
    },
    icon: {
      type: String,
    },
  },
  emits: ["exit-modal"],
  computed: {
    getIconSrc() {
      if (this.icon) return `/images/${this.icon}`;
      else return "/images/cart-icon-modal.png";
    },
  },
  methods: {
    confirmPopup() {
      this.$emit("exit-modal", { isConfirmed: true });
    },
    rejectPopup() {
      this.$emit("exit-modal", { isConfirmed: false });
    },
  },
};
</script>

<style scoped lang="scss">
.categories-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  padding: 15px;

  button {
    font-size: $largTextSize;
    letter-spacing: 1px;
    padding: 20px 45px;
    text-shadow: 0px 3px 4px black;
    border-radius: 20px;
    color: $primaryTextColor;
    height: 70px;
  }

  .bottom-buttons {
    padding: 30px 37px;
  }

  .middle-buttons {
    width: 97%;
  }

  a {
    all: unset;
  }
}

.line-seperator-one {
  background-image: url("/images/category-filter-seperator.png");
  width: 370px;
  height: 46px;
  display: flex;
  justify-content: center;
}

.backdrop-popup {
  background-color: #000000;
  opacity: 0.6;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 11;
}

.modal-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 12;
  border-radius: 40px;
  background: #393945;
  padding: 30px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 330px;

  h1 {
    margin: 0;
    margin-top: 30px;
    padding: 0;
    color: white;
    font-size: $largTextSize;
    letter-spacing: 0.04px;
  }

  span {
    color: #c4c4c4;
    font-size: $mediumTextSize;
  }

  .delivery-date {
    font-size: 35px;
    color: $primaryTextColor;
  }

  .confirm-btn,
  .reject-btn {
    font-family: "Gisha";
    width: 100%;
    margin: 5px 0;
  }

  .reject-btn:hover {
    background: white;
    color: #393945;
    cursor: pointer;
  }

  .confirm-btn {
    background: #460b09;
  }

  .reject-btn {
    background: transparent;
    border: 1px white solid;
    border-radius: 50px;
    color: white;
    font-size: $mediumTextSize;
    letter-spacing: 0.04px;
    padding: 12px 14px 10px 14px;
  }

  img {
    animation: bounceIn 1s;
    width: 230px;
  }
  img[src*="undraw_exclamation-point"]{
    width: 40px;
  }

  @keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
      -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    0% {
      opacity: 0;
      -webkit-transform: scale3d(0.3, 0.3, 0.3);
      transform: scale3d(0.3, 0.3, 0.3);
    }

    20% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1);
      transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9);
      transform: scale3d(0.9, 0.9, 0.9);
    }

    60% {
      opacity: 1;
      -webkit-transform: scale3d(1.03, 1.03, 1.03);
      transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
      -webkit-transform: scale3d(0.97, 0.97, 0.97);
      transform: scale3d(0.97, 0.97, 0.97);
    }

    to {
      opacity: 1;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }
}
</style>
