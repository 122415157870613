function formatDate(date, payload) {
  const { delimiter, reverse, ltrFormat } = payload;
  if (ltrFormat) {
    const parts = date.split("/");
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    return `${month}${delimiter}${day}${delimiter}${year}`;
  }
  const dateObj = new Date(date);
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  if (reverse) return `${year}${delimiter}${month}${delimiter}${day}`;
  return `${day}${delimiter}${month}${delimiter}${year}`;
}

// Function to calculate next occurrence of each true day
function getNextDays(days) {
  const today = new Date();
  // Array to store output
  const nextDays = [];

  // Check each day
  Object.keys(days).forEach((key) => {
    let suffix = "הקרוב";

    const todayKeyDay = (today.getDay() + 1) % 7;
    // If day is true
    if (key.startsWith("day") && days[key] === true) {
      // Get the weekday number (0=Sunday, 1=Monday etc.)
      const dayOfWeek = parseInt(key.replace("day", ""));

      // Calculate next date
      const next = new Date(today);
      const diff = dayOfWeek - todayKeyDay;
      if (diff > 0) {
        const nextDay = next.getDate() + diff;
        next.setDate(nextDay);
      } else {
        const nextWeek = next.getDate() + (7 - todayKeyDay);
        next.setDate(nextWeek + dayOfWeek);
        suffix = "הבא";
      }

      // Add to output
      nextDays.push({
        title: `${next.getDate()}/${next.getMonth() + 1}/${next.getFullYear()}`,
        text: getDayText(next, suffix),
      });
    }
  });

  nextDays.sort((a, b) => {
    const dateA = new Date(
      formatDate(a.title, { ltrFormat: true, delimiter: "/" })
    );
    const dateB = new Date(
      formatDate(b.title, { ltrFormat: true, delimiter: "/" })
    );

    return dateA - dateB;
  });

  return nextDays.slice(0, 3);

  // return nextDays
  //   .slice()
  //   .sort((a, b) => b.title.localeCompare(a.title))
  //   .slice(0, 3);
}

// Helper function to return text
function getDayText(date, suffix) {
  const day = date.toLocaleString("he-IL", { weekday: "long" });
  return `${day} ${suffix}`;
}

export { formatDate, getNextDays };
