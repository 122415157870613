<template>
  <section class="delivery-details Heebo">
    <div v-if="deliveryDetails.date !== '1/1/1970'" class="details-line">
      <span>מועד אספקה :</span>
      <span class="white-text">{{ deliveryDetails.date }}</span>
    </div>
    <div class="details-line">
      <span> נשלח ל :</span>
      <span class="white-text">{{ deliveryDetails.providedTo }}</span>
    </div>
    <div class="details-line">
      <span> מוצרים :</span>
      <span class="white-text">{{ deliveryDetails.products }}</span>
    </div>
    <div class="details-line">
      <span> קרטונים :</span>
      <span class="white-text">{{ deliveryDetails.cartons }}</span>
    </div>
    <div class="divide"></div>
    <div class="details-line" v-if="deliveryDetails.debtBalance">
      <span>
        יתרת {{ deliveryDetails.debtBalance > 0 ? "חוב" : "זכות" }} :</span
      >
      <span class="total-debt felix007"
        ><span>₪</span
        >{{
          getFixedPrice(
            deliveryDetails.debtBalance > 0
              ? deliveryDetails.debtBalance
              : deliveryDetails.debtBalance * -1
          )
        }}</span
      >
    </div>
    <div class="details-line">
      <span> סה"כ הזמנה :</span>
      <span class="total-payment felix007"
        ><span>₪</span>{{ getTotalOrderPriceBeforeTax }}</span
      >
    </div>
    <div class="details-line">
      <span> מע"מ {{ deliveryDetails.tax }}% :</span>
      <span class="total-payment felix007"
        ><span>₪</span>{{ getTaxOrderPrice }}</span
      >
    </div>
    <div class="details-line">
      <span>
        סה"כ לתשלום
        <span v-if="deliveryDetails.debtBalance">(כולל יתרות)</span> :</span
      >
      <span class="total-payment felix007"
        ><span>₪</span>{{ getTotalPayment }}</span
      >
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "DeliveryDetails",
  props: {
    deliveryDetails: {
      type: Object,
      requierd: true,
    },
  },
  computed: {
    ...mapGetters({
      getFixedPrice: "Orders/getFixedPrice",
    }),
    getTotalPayment() {
      const totalPayment =
        this.deliveryDetails.totalPayment +
        (this.deliveryDetails.debtBalance ?? 0);
      if (totalPayment <= 0) return 0;
      return this.getFixedPrice(totalPayment);
    },
    getTotalOrderPriceBeforeTax() {
      const totalPaymentBeforeTax =
        this.deliveryDetails.totalPayment /
        (1 + this.deliveryDetails.tax * 0.01);
      return this.getFixedPrice(totalPaymentBeforeTax);
    },
    getTaxOrderPrice() {
      const taxOrderPrice =
        this.deliveryDetails.totalPayment -
        parseFloat(this.getTotalOrderPriceBeforeTax);
      return this.getFixedPrice(taxOrderPrice);
    },
  },
};
</script>

<style lang="scss" scoped>
.delivery-details {
  min-width: 310px;
  background: $secondaryColor;
  color: #c4c4c4;
  padding: 20px;
  border-radius: 20px;

  .details-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  span {
    font-size: $smallTextSize;
  }
  .divide {
    position: relative;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    height: 1px;
    border: 1px dashed #15151f;
    &:before {
      content: "";
      width: 40px;
      height: 40px;
      right: -45px;
      bottom: -20px;
      display: inline-block;
      position: absolute;
      background: black;
      border-radius: 50%;
    }
    &:after {
      content: "";
      width: 40px;
      height: 40px;
      left: -45px;
      bottom: -20px;
      display: inline-block;
      position: absolute;
      background: black;
      border-radius: 50%;
    }
  }
  .total-payment,
  .total-debt {
    font-size: $largestTextSize;
    color: $primaryTextColor;
  }

  .total-debt {
    color: #c4c4c4;
  }
}
</style>
