
import CustomerService from "@/services/CustomerService";

export default {

    namespaced: true,
    state() {
        return {
            customers: [],
        }
    },
    mutations: {
        setCustomers(state, customers) {
            state.customers = customers;
        },
    },
    actions: {
        async getCustomers(context) {
            var customers = await CustomerService.getCustomers()
            customers.data.unshift({cust:0,custname:'לכולם'})
            context.commit("setCustomers",customers.data)
        }
    },
    getters: {
        getCustomers(state) {
            return state.customers;
          },
    }
}