export default {
    direction: 'ltr',

    LoginPage: {
        titleOne: 'Welcome to the pantry of the',
        titleTwo: 'Balkan bakery',
        phoneNumberPlaceholder: 'Enter a mobile number',
        loginButton: 'Send me a login code',
        forgetPassButton: 'Forgot password?'
    },
    LoginSms: {
        title: 'Login code to the system',
        sendSmsInfoOne: 'We have sent you a verification code to the number',
        sendSmsInfoTwo: 'Please enter the code below',
        loginButton: 'Login',
        resendLinkMessage: 'I did not received the code',
        resendInfoMessageOne: 'Please wait for',
        resendInfoMessageTwo: 'seconds before sending a new code',
    },
    Header: {
        morningGreeting: "Good morning",
        afterNoonGreeting: "Good afternoon",
        eveningGreeting: "Good evening",
        nightGreeting: "Good nigth",
        neworder: 'New Order',
        catitems: 'Items in Cart',
        myorders: 'My Orders',
        openOrdersOne: 'Have',
        openOrdersTwo: 'open orders',
        hello: 'hello',
        logout: 'logout',
        logourl: "/images/logoen.png",
        adminSetting: 'Admin Setting',
    },

    Footer: {
        companyName: 'Balkan bakery'
    },

    ProductWidget: {
        nirotekSKU: 'Nirotek SKU',
        custSKU: 'Customer SKU',
        category: 'Category',
        length: 'length',
        width: 'Width',
        totalWidth: 'Total width',
        quantity: 'Quantity',
        numOfCycles: 'Number of cycles',
        detailOfWidthDimensions: 'Detail of width dimensions',
        unit: 'Unit',
    },

    Products: {
        clearAll: 'Clear all',
        overFiftyProducts: 'Only initial 50 results will be displayed, filters can be used for additional results',
        moreProductsAvailable: 'Additional products can be found when using filters at the top of the page.',
        noProductsWereFound: 'No products were found',
        filtersHeaders: {
            param1: "Paper type",
            param2: "Paper weight",
            param3: "Paper thickness",
            param4: "Color",
            param5: "",
            param6: "",
            param7: "Glue",
            param8: "Type of coating",
            param9: "",
            param10: "Back type",
            param11: "Back weight",
            param12: "Back Color",
            param13: "Release force",
            param14: "",
            param15: "",
            param16: "",
            param17: "",
            param18: "",
            param19: "",
            param20: "",
            type: "Type",
            paramInbox: "Is packed"
        },
    },

    ProductModal: {
        alreadyInCart: 'Product already in cart',
        notCustPart: 'Please note that this product does not exist in your product group.',
        orderLine: ' Order line',
        cancel: 'Cancel',
        note: 'Product note',
        save: 'Add to cart',
        inbox: 'To pack ?',
        quantity: 'Quantity of sheets',
        gilyonLength: 'Sheet length..',
        gilyonWidth: 'Sheet width..',

        galilLength: 'Sheet length in meter..',
        galilCutWidth: 'Cutting width in cm..',
        total: 'Cumulative overall width',
        cm: 'Cm',
        validWidths: 'possible widths of cylinders:',
        numOfCycles: 'Number of Cycles',
        invalidTotalWidth: 'The sum of the slice widths does not match the possible widths and therefore a reduction of ',
        unbox: 'Not packed',
        boxed: 'Packed',
        sheets: 'Sheets',
        galilee: 'Galilee',
        notvalidWidth: 'Sum Not valid - max sum',
        numberCuts: 'Width number',
        numberCycles: 'Cycles number',
        phatAlert: 'Must be coordinated with sales',
        inboxAlert: 'The final quantity of sheets will be confirmed by sales'
    },

    SingleOrder: {
        NewOrder: 'New order',
        OrderNumber: 'Order number',
        PurchaseOrderNumber: 'Purchase order number',
        contact: 'Contact',
        address: 'Address',
        orderDetails: 'Order details',
        Remarks: 'Remarks',
        SendOrder: 'Send Order',
        Update: 'Update',
        sent: 'Sent',
        inpPreparation: 'preparation',
        registered: 'Registered',
        maxLetters: 'The Max Letters is 120',
        ordersEmpty: 'Empty order',
        savedBeforeSendOrder : 'the order didnt save before Send',
        viewDeliveryDoc: 'View certificate',
        contactTooltip: 'You can change a contact person only after placing the order'

    },

    Categories: {
        langFieldName: 'familyedes',
        myProducts: 'My products',
        allProducts: 'All products',
        title: 'Product Categories',
        viewOrder: 'View order »',
        favorites: 'favorites',
        sticky: 'Sticky',
    },

    BaseSearch: { search: 'search...' },

    sideBar: {
        orderStatus: 'Order Status',
        showAll: 'Show all',
    },

    orders: {
        myOrders: 'My orders',
        addressForDelivery: 'Address for delivery',
        searchBySKU: 'Search by SKU',
        address: 'Address',
        status: 'Status',
        orderDetails: 'Order details',
        Remarks: 'Remarks',
        SendOrder: 'Send order',
        productionDate: 'Production date',
        orderNumber: 'Order number',
        deliveryCertificate: 'Delivery certificate',
        DeliveryCertificatenNotProvided: 'Delivery certificate not yet provided',
        reference: 'reference',
        activeorders: 'Open orders',
        orderSummary: 'Order summary',
    },
    home: {
        welcome: "Welcome to Nirotak's ordering site",
        start: 'You can start',
        newOrder: 'New order',
        viewOrders: 'Orders and invoices'
    },

    orderSuccModal: {
        title: 'Order has been successfully received',
        orderNumber: 'Order number',
        alertText: 'The TAPI department may make further adjustments, we will update you if there is a change.',
        watchOrder: 'View order',
        processingYourRequest: 'Processing your request',
        continueOrdering: 'Continue ordering',
    },
}