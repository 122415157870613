import Api from './Api'
export default {
    getAgentOfUser(obj) {
        return Api().get('getAgentOfUser', { params: obj });
    },
    getCustomerBalances() {
        return Api().get('getCustomerBalances');
    },
    getCustomers() {
        return Api().get('getCustomers');
    },
    getCustomerBalancesFromHasahsevet() {
        return Api().get('getCustomerBalancesFromHasahsevet');
    }
}