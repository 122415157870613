<template>
  <div class="product-continer">
    <img
      crossorigin="anonymous"
      @error="handleImageError"
      class="product-image"
      :src="getProductImage"
    />
    <div class="text-continer gap-05">
      <h1>
        {{ slide.tilte }}
      </h1>
      <span>
        {{ slide.contant }}
      </span>
      <button @click="clickedUrl">להזמנה</button>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../../config.js";

export default {
  props: {
    slide: {
      type: Object,
      requierd: true,
    },
  },
  computed: {
    getProductImage() {
      const image = this.slide.imgUrl;
      if (image && image.includes("http")) {
        return image;
      } else if (image) {
        return `${baseUrl}images/` + image;
      } else {
        return "";
      }
    },
  },
  methods: {
    handleImageError(evt) {
      evt.target.src = "/images/product-image.png";
    },
    clickedUrl() {
      const url = this.slide.url;
      if (url && url.includes("http")) {
        window.open(url);
      } else {
        this.$router.push(`${url}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-continer {
  text-align: start;
  right: 1px;
  height: 130px;
  box-shadow: 1px 1px 1px #ffe6ad;
  border-radius: 20px;
  background: $secondaryColor;
  position: relative;
  cursor: pointer;
  .text-continer {
    height: inherit;
    width: 90vw;
    padding: 10px;
    display: flex;
    flex-direction: column;
    h1 {
      color: $primaryTextColor;
      font-size: 22px;
      margin: unset;
    }
    span {
      width: 65%;
      color: #c4c4c4;
    }
    .button-text {
      font-size: 15px;
    }
    button {
      cursor: inherit;
      width: 100px;
      border-radius: 20px;
      height: 30px;
      background: #6d1106;
      color: white;
      border: 0px;
      box-shadow: 1px 1px 1px #ffe6ad;
    }
  }

  .product-image {
    width: 120px;
    height: inherit;
    position: absolute;
    border-radius: 20px 25% 25% 20px / 20px 50% 50% 20px;
    left: 0px;
    object-fit: cover;
  }
}

@media (min-width: 1200px) {
  .product-continer {
    background: $cardBackgroundColor;
  }
}
</style>
