<template>
  <teleport to="#modal_backdrop">
    <div @click.prevent="confirmPopup" class="backdrop-popup"></div>
    <div class="modal-popup">
      <button @click="this.$emit('exit-modal')" class="exit-modal">𐌗</button>
      <div class="icon-continer flex gap-10">
        <img class="iconWithGrayBg" src="/images/ball.png" />
        <h1>כל ההודעות</h1>
      </div>
      <div class="alart-continer">
        <div v-for="(alert, key) in alerts" :key="key">
          <div class="text-continer flex gap-05">
            <img class="icon" :src="getIconSrc(alert.icon)" />
            <span class="Heebo">
              {{ alert.content }}
            </span>
          </div>
          <div class="divider"></div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
    alerts: {
      type: Array,
      requierd: true,
    },
  },
  emits: ["exit-modal"],
  methods: {
    confirmPopup() {
      this.$emit("exit-modal");
    },
    getIconSrc(icon) {
      console.log(icon);
      if (icon) return `images/${icon}-icon.png`;
      else return "/images/party-icon.png";
    },
  },
};
</script>

<style scoped lang="scss">
.exit-modal {
  color: white;
  font-size: $largTextSize !important;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  margin-right: 20px;
  position: absolute;
  left: 30px;
}
.categories-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
  padding: 15px;
}

.line-seperator-one {
  background-image: url("/images/category-filter-seperator.png");
  width: 370px;
  height: 46px;
  display: flex;
  justify-content: center;
}

.backdrop-popup {
  background-color: #000000;
  opacity: 0.6;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 11;
}

.modal-popup {
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 12;
  border-radius: 40px;
  background: #393945;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 330px;
  padding: 20px;

  .icon-continer {
    width: 100%;
    justify-content: normal;
  }
  .alart-continer {
    max-height: 350px;
    overflow-y: auto;
    min-height: 300px;
  }
  .text-continer {
    padding: 0px 10px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .icon {
    width: 14px;
    height: 14px;
    margin-top: 5px;
  }

  h1 {
    margin: 0;
    padding: 0;
    color: $primaryTextColor;
    font-size: $largTextSize;
    letter-spacing: 0.04px;
  }

  span {
    color: #ffffff;
    font-weight: 300;
    font-size: $smallTextSize;
    // padding: 0px 20px;
  }
  .divider {
    background: #5e5e68;
    padding: 0px 10px;
    margin: 15px 10px;
  }
}
</style>
