<template>
  <div
    class="side-bar-container"
    :class="$store.state.openMenu ? 'down' : 'up'"
  >
    <slot> </slot>
  </div>
</template>


<script>
export default {
  // props: ["selectedOptionText"],
};
</script>

<style scoped lang="scss">
.side-bar-container {
  /* height: 350px;
  width: 290px; */
  // width: 95%;
  box-shadow: 0 10px 20px #00000024;
  z-index: 2;
  // padding: 20px 20px;
  border-radius: 0 0 15px 15px;
}
@media (max-width: 992px) {
  .side-bar-container {
    box-shadow: unset;
  }
}
</style>