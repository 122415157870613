<template>
  <div class="page-thank gap-20">
    <!-- <BaseButton>אישור סופי</BaseButton> -->
    <img
      class="Succeeded-icon"
      src="/images/Succeeded-icon.png"
      alt="cart-icon"
    />
    <h1 class="felix007">הזמנתכם נשלחה בהצלחה!</h1>
    <span class="Heebo"
      >אנו עובדים כעת לאשר את ההזמנה ולשלוח אותה במועד המבוקש.
      <br />
      ניתן לערוך את הזמנה בעוד 15 דקות ועד לאישור במערכת.
    </span>
    <DeliveryDetails :deliveryDetails="getDeliveryDetails" />
    <BaseButton @click="confirmPopup" class="confirm-btn"
      >מעבר לעמוד הבית</BaseButton
    >
    <h2 class="felix007">תודה שקניתם מאפה הבלקן.</h2>
  </div>
</template>

<script>
import DeliveryDetails from "../helpers/DeliveryDetails.vue";
import BaseButton from "../helpers/BaseButton.vue";
import OrderService from "@/services/OrderService";
import { formatDate } from "../../Dates";

export default {
  components: {
    DeliveryDetails,
    BaseButton,
  },
  async mounted() {
    if (this.$route.query.status === "approved") {
      const transactionUid = this.$route.query.transaction_uid;
      const cart = this.$store.getters["Orders/getCart"];
      const getCustomerDebtBalance =
        this.$store.getters["Orders/getCustomerDebtBalance"];
      const orderPayLoad = {
        ordname: cart.ordname,
        senddate: this.$route.query.more_info_1,
        notes: this.$route.query.more_info_2,
        tax: this.$route.query.more_info_3,
        orderLines: cart.OrderLines,
        peymentmethode: "credit",
        debtBalance: getCustomerDebtBalance,
        transactionUid: transactionUid,
      };
      const response = await this.$store.dispatch(
        "Orders/sendOrder",
        orderPayLoad
      );
      if (response) {
        if (response.status !== 200) {
          console.log("error on send order");
        } else {
          const orderNumber = response.data.ord;
          if (orderNumber) this.sendInvoice(orderNumber);
        }
      }
    }
  },
  methods: {
    async confirmPopup() {
      const ordersa = await OrderService.getOrders({});
      await this.$store.dispatch("Orders/updateOrders", ordersa.data.orders);
      await this.$store.dispatch("Orders/updateCart", ordersa.data.cart);
      this.$router.push("/");
    },
    async sendInvoice(ord) {
      // build the invocie payload for hasahsevet
      const invoicePayLoad = {
        payment: {
          amount: parseFloat(this.$route.query.amount).toFixed(2),
          issuer_name: this.$route.query.issuer_name,
          identification_number: this.$route.query.identification_number,
          four_digits: this.$route.query.four_digits,
          number_of_payments: this.$route.query.number_of_payments,
        },
        order: {
          ord: ord,
        },
      };

      try {
        await this.$store.dispatch(
          "Invocies/sendInvoicePayment",
          invoicePayLoad
        );
      } catch (error) {
        console.log("error on send invoice");
        console.log(error);
      }
    },
  },
  computed: {
    getTotalPriceWithDebtBalance() {
      return this.$store.getters["Orders/getTotalPriceWithDebtBalance"];
    },
    getDeliveryDetails() {
      // const cart = this.$store.getters["Orders/getCart"];
      const customerName = this.$store.getters["AuthModule/getCustomerName"];
      // if (this.$store.getters["Orders/getCartOrderLinesNumber"] === 0)
      // this.$router.replace("/");
      // const deliveryOptions =
      //   this.deliveryOptions[this.selectedDeliveryOptions];
      const lastOrderSended = this.$store.getters["Orders/getLastOrderSended"];
      return {
        date: formatDate(lastOrderSended.senddate, {
          delimiter: "/",
        }),
        providedTo: customerName,
        products: this.$store.getters["Orders/getCartOrderLinesNumber"],
        cartons: this.$store.getters["Orders/getCartTotal"],
        totalPayment: this.$store.getters["Orders/getCartTotalPriceWithTax"],
        debtBalance: lastOrderSended.debtBalance,
        tax: lastOrderSended.tax,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.page-thank {
  color: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .delivery-details {
    min-width: 330px !important;
  }
  h1 {
    font-size: $largestTextSize;
  }
  span {
    font-size: $mediumTextSize;
  }
  h2 {
    color: $primaryTextColor;
    font-size: $largTextSize;
  }
}
.confirm-btn {
  width: 100%;
}
.Succeeded-icon {
  width: 230px;
}
</style>
