<template>
  <section class="payment-page">
    <iframe class="payment-iframe" scrolling="no" :src="linkPayment"></iframe>
  </section>
</template>

<script>
export default {
  name: "PaymentPage",
  data() {
    return {
      linkPayment: this.$route.query.linkPayment,
    };
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 1200px) {
  .payment-page {
    padding: $mainContentPadding;
  }
}
.payment-iframe {
  width: 100%;
  min-height: 665px;
  border: none;
}
</style>
