import { createStore } from "vuex";
import AuthModule from "./modules/Auth";
import Orders from "./modules/Orders";
import Parts from "./modules/Parts";
import Alert from "./modules/Alert";
import Customers from "./modules/Customers";
import Setting from "./modules/Setting";
import DistLines from "./modules/DistLines";
import Invocies from "./modules/Invoices";
import M from "materialize-css";

const store = createStore({
  modules: {
    //namespaced
    AuthModule,
    Orders,
    Parts,
    Alert,
    Setting,
    Customers,
    DistLines,
    Invocies,
  },
  state() {
    //data
    return {
      loading: false,
      openMenu: true,
      isMobile: false,
      isTablet: false,
      isLaptop: false,
      isDesktop: false,
      previousRoute: null,
      mobileNavSelectedOptionText: "",
      version: "1.0.15",
      toastCount: 0,
    };
  },
  mutations: {
    setLoading(state, mode) {
      state.loading = mode;
    },
    setMobileNavText(state, selectedText) {
      console.log(selectedText);
      state.mobileNavSelectedOptionText = selectedText;
    },
    toggleMenu(state) {
      state.openMenu = !state.openMenu;
    },
    setMenuToOpen(state) {
      state.openMenu = true;
    },
    setMenuToClose(state) {
      state.openMenu = false;
    },
    setIsMobile(state, value) {
      state.isMobile = value;
    },
    setIsTablet(state, value) {
      state.isTablet = value;
    },
    setIsLaptop(state, value) {
      state.isLaptop = value;
    },
    setIsDesktop(state, value) {
      state.isDesktop = value;
    },
    incrementToastCount(state) {
      ++state.toastCount;
    },
    decrementToastCount(state) {
      --state.toastCount;
    },
    setPreviousRoute(state, value) {
      state.previousRoute = value;
    }
  }, //methods for update the state, only here! not in actions!!!
  actions: {
    startLoading(context) {
      context.commit("setLoading", true);
    },
    stopLoading(context, data) {
      let { toastCount } = context.state;
      context.commit("setLoading", false);
      if (data) {
        if (toastCount >= 1) {
          // If limit reached, do nothing
          return;
        }
        context.commit("incrementToastCount");
        M.toast({ html: data.message, classes: "rounded " + data.status });
        // Remove toast after duration
        setTimeout(() => {
          context.commit('decrementToastCount');
        }, 5000);
      }
    },
  },
  getters: {
    getMobileNavText(state) {
      return state.mobileNavSelectedOptionText;
    },
    isMobile(state) {
      return state.isMobile;
    },
    isTablet(state) {
      return state.isTablet;
    },
    isLaptop(state) {
      return state.isLaptop;
    },
    isDesktop(state) {
      return state.isDesktop;
    },
    isLoading(state) {
      return state.loading;
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    getAppVersion(state) {
      return state.version;
    },
    getPreviousRoute(state) {
      return state.previousRoute
    },
  },
});

export default store;
