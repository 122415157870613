<template>
  <header>
    <div
      v-if="!isLoginPage || ($route.meta.isShowHeaderDesktop && isDesktop)"
      class="container header-container"
    >
      <div class="container-right-side">
        <div class="buttons" v-if="isDesktop">
          <a
            v-if="isAuth && !isLoginPage && !isLoginSms"
            href=""
            @click="this.$store.dispatch('AuthModule/logout')"
            class="flex gap-05"
          >
            <span class="logOut-button"> התנתקות</span>
          </a>
          <a
            class="site-link"
            target="_blank"
            href="https://www.balkan-bakery.co.il/"
            >אתר הבלקן</a
          >
        </div>
        <div
          v-if="showNavContainer && isMobile"
          @click="navigateBack"
          class="navigation-container"
        >
          <span class="back-button-link">
            <img
              :class="backButtonStyle"
              src="/images/backButtonLogo.png"
              alt="back-button-icon"
            />
          </span>
          <h5
            class="navigation-container__pageTitle felix007"
            v-if="showNavContainer && isMobile"
          >
            {{ this.$route.meta.title }}
          </h5>
        </div>
        <div v-if="showUserInfo && !isMobile" class="user-info-container">
          <h5 class="user-info-container__greeting">
            {{ greeting }}, <br v-if="isMobile" />
            {{ userName }}
          </h5>
          <hr />
          <p class="user-info-container__customerName">{{ custName }}</p>
        </div>
      </div>
      <div class="continer-left-side">
        <img
          @click="
            () => {
              if (!isLoginSms && !isLoginPage) this.$router.push('/');
            }
          "
          id="balkan-header-logo"
          src="/images/headerLogo.png"
          alt="balkan-header-logo"
        />

        <div v-if="isDesktop && !isThankYouPage" class="desktop-actions">
          <NavCard
            :title="$t('Header.orderSummary')"
            :subTitle="$t('Header.catitems')"
            :amount="cartOrderLinesNumber"
            mode="grey"
            iconName="add_shopping_cart"
            refi="cart"
          ></NavCard>
          <!-- <NavCard
            v-if="!getUser?.isRedirectedFromAgent"
            :title="$t('Header.myorders')"
            :subTitle="$t('Header.activeorders')"
            :amount="openOrders"
            mode="grey"
            iconName="dvr"
            refi="orders/הכל"
          ></NavCard> -->
          <NavCard
            :title="$t('Header.myorders')"
            :subTitle="$t('Header.activeorders')"
            :amount="openOrders"
            mode="grey"
            iconName="dvr"
            refi="orders/הכל"
          ></NavCard>
          <NavCard
            v-if="getUserRole?.customerapp"
            :title="$t('Header.adminSetting')"
            mode="grey"
            iconName="settings"
            refi="admin-panel"
            class="orders-nav-card"
          ></NavCard>
        </div>
      </div>
    </div>
    <div v-if="showUserInfo && isMobile" class="user-info-container-mobile">
      <p class="user-info-container__customerName">{{ custName }}</p>
      <h5 class="user-info-container__greeting center-text">
        {{ greeting }}, <br v-if="isMobile" />
        {{ userName }}
      </h5>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
// import BaseButton from "../helpers/BaseButton.vue";
import { routerNames } from "@/router";
import NavCard from "../helpers/NavCard.vue";
// import CartSummary from "../helpers/CartSummary.vue";

export default {
  components: { NavCard },
  name: "TheHeader",
  methods: {
    navigateToOrdersPage() {
      this.$router.push({
        name: routerNames.orders,
        params: { status: "הכל" },
      });
    },
    navigateBack() {
      if (this.isCheckOutPage && !this.isDesktop) {
        this.$router.push({
          name: routerNames.category,
          params: { category: "חיפוש" },
        });
        return;
      }
      if (this.getPreviousRoute) {
        try {
          this.$router.push({ name: this.getPreviousRoute });
        } catch (err) {
          this.$router.go(-1);
        }
      } else {
        this.$router.push("/");
      }
    },
    navigateToCart() {
      if (this.areThereOrdersInCart) {
        this.$router.push({ name: routerNames.cart });
      } else {
        this.$router.push({
          name: routerNames.category,
          params: { category: "חיפוש" },
        });
      }
    },
  },
  computed: {
    areThereOrdersInCart() {
      return this.$store.getters["Orders/getCartOrderLinesNumber"] > 0;
    },
    custName() {
      const custName = this.customerName;
      if (this.isMobile) {
        if (custName.length <= 20) {
          return custName;
        }
        return `${custName.substring(0, 20)}...`;
      }
      return custName;
    },
    greeting() {
      const d = new Date();

      if (d.getHours() < 12) {
        return this.$t("Header.morningGreeting");
      } else if (d.getHours() < 18) {
        return this.$t("Header.afterNoonGreeting");
      } else if (d.getHours() < 22) {
        return this.$t("Header.eveningGreeting");
      } else {
        return this.$t("Header.nightGreeting");
      }
    },
    backButtonStyle() {
      if (!this.isLoginSms) return ["back-button-icon"];
      else return [];
    },
    showNavContainer() {
      if (this.isNavigationPage || this.isLoginSms) return true;
      else return false;
    },
    showUserInfo() {
      if (this.isDesktop) {
        if (this.isLoginSms || this.isLoginPage) return false;
        else return true;
      } else {
        if (this.isHomePage) return true;
        else return false;
      }
    },
    isLoginPage() {
      return this.$route.name === "loginPage";
    },
    isLoginSms() {
      return this.$route.name === "loginSms";
    },
    isHomePage() {
      return this.$route.name === "home";
    },
    isCheckOutPage() {
      return this.$route.name === "cart";
    },
    isThankYouPage() {
      return this.$route.name === "thankYou";
    },
    isNavigationPage() {
      return this.$route.meta.title;
    },
    ...mapGetters({
      isAuth: "AuthModule/isAuthenticated",
      user: "AuthModule/getUser",
      getUser: "AuthModule/getUser",
      userName: "AuthModule/getUserName",
      customerName: "AuthModule/getCustomerName",
      openOrders: "Orders/getOpenOrders",
      cartOrderLinesNumber: "Orders/getCartOrderLinesNumber",
      getUserRole: "AuthModule/getUserRole",
      isLoading: "isLoading",
      isDesktop: "isDesktop",
      isLaptop: "isLaptop",
      isTablet: "isTablet",
      isMobile: "isMobile",
      getPreviousRoute: "getPreviousRoute",
    }),
  },
};
</script>

<style scoped lang="scss">
.desktop-actions {
  margin-top: 10px;
}
@media (min-width: 1024px) {
  .navigation-container__pageTitle {
    width: 100px !important;
  }
  .header-container {
    flex-direction: row-reverse;
  }
  .continer-left-side {
    flex-direction: row !important;
  }
  .container-right-side {
    display: flex;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .buttons {
    // color: white;
    display: flex;
    gap: 20px;
    .logOut-button {
      font-size: $mediumTextSize;
      text-decoration: underline;
      color: white;
    }
  }
  .continer-left-side__open-orders-btn {
    margin-top: 10px;
    border-radius: 15px;
    height: 90px;
    &:hover {
      cursor: pointer;
    }

    .cart-summary {
      margin-top: 4px;
    }

    .open-orders-text {
      font-size: $extraSmallTextSize;
      color: white;

      b {
        font-size: $smallTextSize;
      }
    }
  }
  .user-info-container {
    margin-top: 5px;
    flex-direction: column-reverse !important;

    hr {
      display: none;
    }
  }
  #balkan-header-logo {
    margin-left: 45px;
    cursor: pointer;
  }
}
.site-link {
  color: white;
  font-size: $mediumTextSize;
  &:hover {
    text-decoration: underline;
  }
}
.navigation-container.back-button-link {
  display: flex;
  align-items: center;
}

.navigation-container .back-button-icon {
  width: 25px;
}

.navigation-container .navigation-container__pageTitle {
  color: $primaryTextColor;
  font-size: 20px;
  line-height: 0.8;
  margin: 0;
  width: 80px;
}

.continer-left-side__open-orders-btn {
  font-size: 17px;
  padding: 10px 12px 10px 12px;
  width: fit-content;
}

.continer-left-side {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}

@media (min-width: 1024px) {
  .continer-left-side {
    align-items: center;
  }
}

.navigation-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  &:hover {
    cursor: pointer;
  }
}

.user-info-container__greeting {
  color: $primaryTextColor;
  font-size: 18px;
  font-weight: 800;
}

.user-info-container__customerName {
  color: $primaryTextColor;
  font-size: $mediumTextSize;
  margin: 0;
}

.user-info-container {
  display: flex;
}

.user-info-container-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 14px 15px;
  max-height: 50px;
}

.user-info-container hr {
  width: 40px;
  border: none;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.511);
  margin-right: 0;
}
header {
  z-index: 6 !important;
  .user-info-container {
    color: #ffe6ad;
  }

  .user-info-container h5 {
    font-size: $mediumTextSize;
    font-weight: bold;
  }

  @media (min-width: 1024px) {
    .user-info-container h5 {
      margin: 0;
    }
  }

  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(100, 11, 6, 1)
    ),
    url("/images/background-header.png");
  background-size: cover;
  background-position: center;
  border-radius: 0px 0px 20px 20px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px 10px 10px;
}

#balkan-header-logo {
  width: 250px;
  margin-top: 10px;
}
.plus-icon {
  width: 20px;
}
</style>
